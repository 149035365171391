import { Injectable } from '@angular/core'
import { Usuario } from 'src/app/models/usuario.model'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Rx'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    usuario: Usuario
    IsLoginActive: any
    token: string

    constructor(public http: HttpClient, public _router: Router) {
        this.cargarStorage()
    }

    estaLogueado() {
        return this.IsLoginActive == 1 ? true : false
    }

    cargarStorage() {
        if (sessionStorage.getItem('token')) {
            this.token = sessionStorage.getItem('token')
            this.usuario = JSON.parse(sessionStorage.getItem('usuario'))
        } else {
            this.token = ''
            this.usuario = null
        }

        if (sessionStorage.getItem('IsLoginActive')) {
            this.IsLoginActive = sessionStorage.getItem('IsLoginActive')
        } else {
            this.IsLoginActive = ''
        }
    }

    logout() {
        this.usuario = null
        this.token = ''
        this.IsLoginActive = 0
        sessionStorage.clear()
        this._router.navigate(['/login'])
        window.location.reload()
    }

    closeToken() {
        // let local_data: any = JSON.parse(sessionStorage.getItem('identidad'))
        // let data = {
        // 	usuario: local_data.id_login_usuario,
        // 	token: local_data.token_dispositivo
        // }
        // let url = environment.URL_SERVICIOS +'closeSession';
        // return this.http.post(url, data)
        // .pipe(map((resp: any) => {
        // }));
    }

    public getUsuario(loginForm): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/User/LoginConserje', loginForm)
            .timeout(20000)
            .map((response) => {
                // console.log(response, "DATAAAA")
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    errorHandler(error: any): any {
        throw new Error('Method not implemented.')
    }

    getLocalData() {
        var data = {
            token: sessionStorage.getItem('token'),
            id: sessionStorage.getItem('id'),
            dispositivo: sessionStorage.getItem('dispositivo')
        }
        return data
    }

    recuperarClave(usuario: string) {
        let data = {
            nombre_usuario: usuario
        }
        let url = environment.URL_SERVICIOS + 'postEmail'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    public Recuperarcontrasena(data): Observable<any> {
        return this.http
            .post(environment.URL_FRAME + 'users/validateAccount', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }
}
