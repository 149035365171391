import { Component, OnInit, Injectable, OnDestroy } from '@angular/core'
import { RealizarLlamadaPerdidaService } from './realizar-llamada-perdida.service'
import { Observable, Subscription } from 'rxjs'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { CitofoniaService } from 'src/app/services/citofonia/citofonia.service'
import { Notificacion } from 'src/app/models/notificacion.model'
import { GlobalServicesService } from 'src/app/services/global-services.service'

@Injectable({
    providedIn: 'root'
})
@Component({
    selector: 'app-realizar-llamada-perdida',
    templateUrl: './realizar-llamada-perdida.component.html',
    styles: []
})
export class RealizarLlamadaPerdidaComponent implements OnInit, OnDestroy {
    public selectUser$: Observable<any>
    public user_data: any = JSON.parse(sessionStorage.getItem('identidad'))
    public usuariosConectados$: Subscription
    public notificacion = new Notificacion(null, 'conserje', '', '')
    public salon: any

    constructor(
        public _realizarLlamadaPerdidaService: RealizarLlamadaPerdidaService,
        public _socketService: SocketServiceService,
        public _citofoniaService: CitofoniaService,
        public _globalService: GlobalServicesService
    ) {}

    ngOnInit() {
        this.salon = `Sala${this.user_data.id_login_usuario}Proyecto${sessionStorage.getItem('IdSelectProject')}`
        this.selectUser$ = this._socketService.ChangeselectMissingUser$()
        this.selectUser$.subscribe((res) => {
            this._socketService.usuariosConectados()
            this._realizarLlamadaPerdidaService
                .deleteMissingCall(res.id_login_usuario)
                .subscribe(() => this._citofoniaService.refreshTableService())
            this.usuariosConectados$ = this._socketService.UsuariosConectadosNotificacion$().subscribe((resp: any) => {
                let encontrado = resp.filter((user: any) => {
                    return user.id_usuario == res.id_login_usuario
                })
                //De no encontrarse se le emite una notificacion de que el conserje lo esta llamando
                if (encontrado.length == 0) {
                    let data = {
                        titulo: 'Citofonia',
                        mensaje: `Llamada entrante de ${this.user_data.nombre} ${this.user_data.apellido}`,
                        modulo: 'citofonia',
                        nombre_remitente: `${this.user_data.nombre} ${this.user_data.apellido}`,
                        id_sala: this.salon,
                        id_usuario: res.id_login_usuario,
                        id_usuario_remitente: this.user_data.id_login_usuario
                    }
                    // this._globalService.envioNotificacionBkCitofonia(data).subscribe()
                }
            })
            let data_bussy = {
                id_usuario: this.user_data.id_login_usuario,
                ocupado: true
            }
            this._socketService.estoyOcupado(data_bussy)
            this._citofoniaService.realizar = true
            let id_salon = `Sala${this.user_data.id_login_usuario}Proyecto${sessionStorage.getItem('IdSelectProject')}`
            this._citofoniaService.joinSession(id_salon)
            let data = {
                time: 5000,
                id_usuario: res.id_login_usuario,
                nombre_remitente: this.user_data.nombre + ' ' + this.user_data.apellido,
                id_sala: id_salon,
                numero_bien: 'conserjeria',
                id_usuario_remitente: this.user_data.id_login_usuario
            }
            this._socketService.realizarLlamada(data)
        })
    }

    openFullscreen() {
        if (this._citofoniaService.modal) {
            this._citofoniaService.modal = false
            this._citofoniaService.width = this.getWidth()
            this._citofoniaService.height = this.getHeight()
        } else {
            this._citofoniaService.modal = true
            this._citofoniaService.width = 650
            this._citofoniaService.height = 400
        }
    }

    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        )
    }

    getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        )
    }

    ngOnDestroy() {
        this.usuariosConectados$.unsubscribe()
    }
}
