import {  Component, NgModule, OnDestroy, OnInit } from '@angular/core'
import { HorarioDiarioService } from './horario-diario.service'
import { Horas } from '../../models/horas.model'
import { Subscription } from 'rxjs'
import { AreasComunesService } from 'src/app/pages/areas-comunes/areas-comunes.service'
import * as moment from 'moment'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { registerLocaleData } from '@angular/common'
import localeEs from '@angular/common/locales/es'

import { DemoComponent } from 'src/app/pages/day-view-scheduler/component'


registerLocaleData(localeEs)

export interface Horas {
    hora: string
    ocupado: boolean
    minutos: number
}

export interface DayData {
    day: string
    number_day: number
    available: boolean
    reserva: Reserva[]
}

export interface Reserva {
    dia_entrada_area?: string
    id_reserva?: number
    hora_inicio?: string
    hora_fin?: string
    estado?: number
    numero_bien?: string
    patente?: null
    id_login_usuario?: number
    nombre_usuario?: string
    tipo_bien: string
}

@NgModule({
    providers: [DemoComponent]
})
@Component({
    selector: 'app-horario-diario',
    templateUrl: './horario-diario.component.html',
    styleUrls: ['./horario-diario.component.css']
})
export class HorarioDiarioComponent implements OnInit, OnDestroy {
    public IsIntegracion: any = 0
    public hours: Array<Horas> = Horas
    public dayData: DayData = { day: '', number_day: 0, available: false, reserva: [{ tipo_bien: '' }] }
    public $day: Subscription
    private id_proyecto: string = sessionStorage.getItem('IdSelectProject')
    public fecha: string = moment(moment()).format('YYYY-MM-DD')
    public numero_dia: moment.MomentInput = moment().isoWeekday()
    public bienes: Array<any> = []
    public todayNumber: number
    public areas = []
    public lengthAreas = 0
    public respAux: any = ''

    constructor(
        public _horarioDiarioService: HorarioDiarioService,
        private _areasComunesService: AreasComunesService,
        private globalServicesService: GlobalServicesService
    ) {}

    ngOnInit() {
        
        this.$day = this._horarioDiarioService.$dayData.subscribe((resp: any) => {
            console.log(this.respAux)
            this.respAux = resp
            this._horarioDiarioService.EmitCronograma(this.respAux)
        
        })
    }

    public closeModal() {
        this._horarioDiarioService.hideModal()
        this._horarioDiarioService.NextReservas()
    }

    ngOnDestroy() {
        this.$day.unsubscribe()
    }

    async EliminarReserva(item) {
        console.log(item)
      /*   let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_reserva: item.id_reserva,
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje elimina la reserva correspondiente al depto ${item.numero_bien} registrada a las ${item.hora_inicio} hasta ${item.hora_fin} para ${item.dia_entrada_area}`
        }
        this._horarioDiarioService.EliminarReserva(data).subscribe((result) => {
            //    this.buildCronogram(this.respAux)
        }) */
    }

}
