<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>

<!-- OPCIPON 2  -->

<div class="margen-lateral">
    <div class="bloque-reserva">
        <div class="txt-1">
            <h1>Entrega de Llaves:</h1>
        </div>

        <div class="contenedor-selectores">
            <span class="bloque-1-area">
                <div class="contenedor-btn-disponibilidad">
                    <select
                        [(ngModel)]="selectedTorre"
                        (change)="Torre($event.target.value)"
                        class="estilo-boton gris"
                        type="button"
                        value="Todos"
                    >
                        <option value="0">TORRE</option>
                        <option value="{{ item.id_etapa }}" *ngFor="let item of torres">{{ item.nombre_etapa }}</option>
                    </select>
                </div>

                <div class="contenedor-btn-disponibilidad">
                    <select
                        [(ngModel)]="filterValueBien"
                        (change)="Tipo($event.target.value)"
                        class="estilo-boton gris"
                        type="button"
                        value="Todos"
                    >
                        <option value="0">TIPO DE BIEN</option>
                        <option value="BODEGA">Bodega</option>
                        <option value="ESTACIONAMIENTO">Estacionamiento</option>
                    </select>
                </div>

                <div class="contenedor-selector-datepicker">
                    <span class="titulo">Fecha</span>
                    <input
                        (click)="d.toggle()"
                        autocomplete="off"
                        (ngModelChange)="changeDate($event)"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="fechaSelected"
                        [ngModelOptions]="{ standalone: true }"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        class="estilo-input-1"
                        type="text"
                    />
                </div>
            </span>

            <span class="bloque-area-2 ml-2">
                <div (click)="LimpiarFiltro()" class="contenedor-btn-disponibilidad">
                    <input class="estilo-boton" type="button" value="Limpiar" />
                </div>
            </span>
        </div>
        <div class="borrado"></div>
    </div>
</div>

<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla-llaves">
            <div class="msj-2">
                <h2 class="titulo-5">Entrega de LLaves</h2>
                <!-- <div *ngIf="BienesEntregaFil.length == 0" class="subtitle centrado pb-5">
                    SIN AGENDAMIENTOS DISPONIBLES
                </div> -->
            </div>

            <div class="table-responsive table-borderless">
                <ng-container *ngFor="let item of BienesEntregaFil">
                    <div
                        [ngClass]="{
                            'verde-tono-2': item.entrega_llaves == 1,
                            'naranjo-tono-2': item.id_estado_checkin == 1,
                            'gris-tono-2': item.entrega_llaves == null && item.id_estado_checkin == null
                        }"
                        (click)="ModalEntrega(item)"
                        class="sector btn weight-1"
                    >
                        {{ item.nombre_tipo }} - {{ item.numero_bien }}
                        <br />
                        <ng-container>{{ item.nombre_etapa }}</ng-container>
                        <br />
                        <ng-container *ngIf="item.nombre_estado_checkin != null"
                            >{{ item.nombre_estado_checkin }}
                        </ng-container>
                        <ng-container *ngIf="item.nombre_estado_checkin == null">Sin Solicitud</ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="contenedor-modal-ingreso-visita">
    <div class="modal fade" id="modal-entrega-llaves">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">
                        Entrega de Llaves {{ EntregaActiva.nombre_tipo }} {{ EntregaActiva.numero_bien }}
                    </h4>
                    <button type="button" class="close pointer" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-md-12 estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">RUT</label>
                            <input
                                disabled
                                #input
                                [ngModel]="EntregaActiva.rut"
                                autocomplete="off"
                                class="form-control estilo-input-2 gris"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12 estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">Nombre</label>
                            <input
                                disabled
                                #nombre
                                [ngModel]="EntregaActiva.nombre"
                                autocomplete="off"
                                class="form-control estilo-input-2"
                                placeholder="Nombre"
                            />
                        </div>

                        <div class="form-group col-md-12 estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">Apellido</label>
                            <input
                                disabled
                                #apellido
                                [ngModel]="EntregaActiva.apellido"
                                autocomplete="off"
                                class="form-control estilo-input-2"
                                placeholder="Apellido"
                            />
                        </div>
                    </div>
                </div>

                <div class="centered-title-2">
                    <b>CHECK-IN</b><br />
                    <div class="subtitle">Historial de cambios</div>
                </div>
                <div class="table-responsive">
                    <div class="ml-5 mr-5">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th scope="col text-center">
                                        <div class="ico-estado-2 text-center">Estado check-in</div>
                                    </th>
                                    <th scope="col text-center">
                                        <div class="ico-estado-2 text-center">Fecha de check-in</div>
                                    </th>
                                    <th scope="col text-center">
                                        <div class="ico-estado-2 text-center">Fecha Modificación</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of EntregaActiva.bitacora">
                                    <td [ngClass]="{ inasistencia: item.id_estado_checkin == '4' }">
                                        {{ item.nombre_estado_checkin }}
                                    </td>
                                    <td [ngClass]="{ inasistencia: item.id_estado_checkin == '4' }">
                                        {{ item.fecha }}
                                    </td>
                                    <td [ngClass]="{ inasistencia: item.id_estado_checkin == '4' }">
                                        {{ item.created_at }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Modal footer -->
                <div *ngIf="EntregaActiva.entrega_llaves != 1" class="modal-footer">
                    <button type="button" class="btn btn-modal-enviar float-right pointer" (click)="EntregarLlaves()">
                        Entregar
                    </button>

                    <button
                        type="button"
                        class="btn btn-modal-cancelar pointer float-right"
                        data-dismiss="modal"
                        (click)="closeModal()"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
