import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { RecibirEncomiendaService } from './recibir-encomienda.service'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { EncomiendaService } from 'src/app/services/encomienda/encomienda.service'
import { forkJoin } from 'rxjs'
import { Encomienda } from 'src/app/models/encomienda.model'
import * as moment from 'moment'
import { ToastrService } from 'ngx-toastr'
import { Notificacion } from 'src/app/models/notificacion.model'
import swal from 'sweetalert2'
import { EncomiendaComponent } from 'src/app/pages/encomienda/encomienda.component'
import { AlertService } from '@full-fledged/alerts'

@Component({
    selector: 'app-recibir-encomienda',
    templateUrl: './recibir-encomienda.component.html',
    styles: [],
    host: {
        '(document:click)': 'onClick($event)'
    }
})
export class RecibirEncomiendaComponent implements OnInit {
    public users: Array<any>
    public types: Array<any>
    public usersFiltered: Array<any>
    public selectedType: Number = 0
    public selectedUser: any = 0
    public observacion: String = ''
    public procedencia: String
    public codigo: String
    public encomiendas: Array<Encomienda>
    public selectText: String = 'Seleccione una opcion'
    public notificacion = new Notificacion(null, 'conserje', '', '')
    public input: any = true
    public onfocus: Boolean = false
    @ViewChild('search', { static: false }) searchElement: ElementRef

    constructor(
        public _recibirEncomiendaService: RecibirEncomiendaService,
        public _globalService: GlobalServicesService,
        public _encomiendaService: EncomiendaService,
        private toastr: ToastrService,
        public globalServicesService: GlobalServicesService,
        public encomiendaComponent: EncomiendaComponent,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.getData()
    }

    getData() {
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        forkJoin(this._globalService.getResidentes(data), this._encomiendaService.getTypes()).subscribe((res: any) => {
            this.users = res[0]
            this.usersFiltered = res[0]
            this.types = res[1]
        })
    }

    sendData() {
        let user = this.users.filter((user: any) => {
            return (
                user.id_bien == this.selectedUser.id_bien && user.id_login_usuario == this.selectedUser.id_login_usuario
            )
        })
        let nombre_encomienda = this.types.filter((type: any) => {
            return type.id_tipo_encomienda == this.selectedType
        })
        if (this.selectedUser == 0) {
            this.alertService.danger('Debe seleccionar el usuario')
            return
        }
        if (this.selectedType == 0) {
            this.alertService.danger('Debe seleccionar el tipo')
            return
        }
        if (!this.procedencia) {
            this.alertService.danger('Debe ingresar la procedencia')
            return
        }
        let encomienda = new Encomienda(
            JSON.parse(sessionStorage.getItem('identidad')).id_login_usuario,
            this.selectedUser.id_login_usuario,
            this.selectedUser.id_bien,
            0,
            this.selectedType,
            this.procedencia,
            this.observacion,
            moment().format('YYYY-MM-DD HH:mm'),
            '',
            '',
            user[0].numero_bien,
            user[0].nombre + ' ' + user[0].apellido,
            nombre_encomienda[0].nombre_encomienda,
            '',
            this.selectedUser.correo,
            this.codigo
        )

        this._encomiendaService.sendCommission(encomienda).subscribe((res: any) => {
            let promise = new Promise((resolve, reject) => {
                if (res) {
                    resolve('ok')
                } else {
                    reject('No existen registros')
                }
            })
            promise
                .then(() => {
                    this._encomiendaService.encomiendas.push(encomienda)
                    this.closeModal()
                    this.encomiendaComponent.GetEncomiendas()
                    this.toastr.success(res.res, 'Ingresado!', {
                        positionClass: 'toast-bottom-left',
                        progressBar: true
                    })
                    this.notificarEncomienda(nombre_encomienda[0].nombre_encomienda)
                    let data = {
                        correo: encomienda.correo,
                        nombre: user[0].nombre + ' ' + user[0].apellido,
                        encomienda: nombre_encomienda[0].nombre_encomienda,
                        procedencia: this.procedencia
                    }
                    this._encomiendaService.sendEmailToClient(data).subscribe()
                    this.selectedType = 0
                    this.selectedUser = 0
                    this.procedencia = ''
                    this.codigo = ''
                    this.observacion = ''
                })
                .catch((err) => console.error(err))
        })
    }

    closeModal() {
        this._recibirEncomiendaService.hideModal()
        this.selectText = 'Seleccione una opcion'
    }

    notificarEncomienda(nombre_encomienda: String) {

        this.notificacion = new Notificacion(
            this.selectedUser.id_login_usuario,
            'conserje',
            'Se ha recibido una encomienda dirigida a su domicilio, favor retirarla a la brevedad en recepción. Atentamente, la administración. ---> Observaciones: ' +
            this.observacion,
            'Encomienda ' + '(' + nombre_encomienda + ')'
        )
        this.globalServicesService.enviarNotificacion(this.notificacion).subscribe(
            (response) => {
                this.alertService.success('Notificación enviada.')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            },
            (error) => {
                this.alertService.danger('Problemas al enviar notificación')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            }
        )
    }

    testingSelect() {
        if (this.input) {
            this.input = false
            setTimeout(() => {
                this.searchElement.nativeElement.focus()
                this.onfocus = true
            }, 10)
        } else {
            this.input = true
        }
    }

    onClick(event: any) {
        if (event.srcElement.name == 'btn') {
            this.onfocus = true
        }
        if (event.srcElement.name != 'input' && this.onfocus && event.srcElement.name != 'btn') {
            this.onfocus = false
            this.input = true
        }
    }

    setFilteredItems(event: any) {
        this.usersFiltered = this.users.filter((item: any) => {
            if (item.nombre != null && item.apellido != null && item.numero_bien != null) {
                return (
                    item.nombre.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
                    item.apellido.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
                    item.numero_bien.toLowerCase().indexOf(event.toLowerCase()) > -1
                )
            }
        })
    }

    getUserData(user: any) {
        // console.table(user, "USER")
        // return
        this.selectedUser = user
        this.selectText = user.nombre + ' ' + user.apellido
    }
}
