<div *ngIf="_citofoniaService.modal" class="fondo-negro animated fadeIn" [ngClass]="_recibirLlamadaService.oculto">
    <div class="contenedor-modal-bitacora-detalle">
        <div id="modal-llamada-citofonia">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header header-verde">
                        <h4 class="modal-title">Citófono</h4>
                        <button type="button" class="close pointer" (click)="_citofoniaService.leaveSession(true)">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <div *ngIf="!_citofoniaService.session && !_citofoniaService.loading">
                            <div class="titulo-modal-icono">Llamada entrante de:</div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="fondo-a">{{ _recibirLlamadaService.user }}</div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="fondo-b">{{ _recibirLlamadaService.dpto }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>

                        <div *ngIf="_citofoniaService.loading">
                            <div class="imagen-llamada">
                                <img class="margen-ico-puntos" src="assets/images/circle-modificado.gif" />
                            </div>
                            <div class="texto-llamada">Conectando...</div>
                        </div>

                        <div id="main-container" class="container">
                            <div *ngIf="_citofoniaService.session && !_citofoniaService.loading" id="session">
                                <div id="video-container" class="col-md-12">
                                    <div *ngFor="let sub of _citofoniaService.subscribers">
                                        <user-video
                                            [streamManager]="sub"
                                            (click)="_citofoniaService.updateMainStreamManager(sub)"
                                        ></user-video>
                                        <div class="full-screen-btn">
                                            <div class="btn-fulls" (click)="openFullscreen()"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="modal-footer alineacion-items">
                            <div class="contenedor-opciones">
                                <button
                                    type="button"
                                    class="btn btn-modal-cancelar pointer"
                                    (click)="_citofoniaService.leaveSession(true)"
                                >
                                    Colgar
                                </button>
                                <button
                                    *ngIf="!_citofoniaService.session"
                                    data-toggle="modal"
                                    data-target="#modal-confirmacion"
                                    type="button"
                                    class="btn btn-modal-enviar ico-telefono pointer"
                                    (click)="_citofoniaService.joinSession()"
                                >
                                    Contestar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contenedor-videollamada" *ngIf="!_citofoniaService.modal">
            <div *ngIf="_citofoniaService.session" id="session">
                <div id="video-container">
                    <!-- <div id="video-container" class="col-md-5"> -->
                    <div *ngFor="let sub of _citofoniaService.subscribers">
                        <!-- <div *ngFor="let sub of _citofoniaService.subscribers" class="stream-container col-md-6 col-xs-6"> -->
                        <user-video
                            #videoUser
                            [streamManager]="sub"
                            id="user-video"
                            (click)="_citofoniaService.updateMainStreamManager(sub)"
                        ></user-video>
                        <div class="full-screen-btn">
                            <div class="btn-fulls" (click)="openFullscreen()"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
