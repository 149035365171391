import { Component, OnInit } from '@angular/core'
import { forkJoin } from 'rxjs'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { MultasService } from 'src/app/services/multas/multas.service'
import { IngresarMultaService } from './ingresar-multa.service'
import * as moment from 'moment'
import { FileServiceService } from 'src/app/services/fileService/file-service.service'
import { ToastrService } from 'ngx-toastr'
import { AlertService } from '@full-fledged/alerts'

@Component({
    selector: 'app-ingresar-multa',
    templateUrl: './ingresar-multa.component.html',
    styles: []
})
export class IngresarMultaComponent implements OnInit {
    public fechaSelected: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public fechaMinDate: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public fechaMaxDate: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public multas: Array<any> = []
    public dptos: Array<any> = []
    public base64: string = ''
    public numeroBien: any
    public idMulta: any
    public observacion: any = ''
    public textAreaPlaceholder: string = 'Aquí va su Observación'
    public horas: Array<string> = []
    public minutos: Array<string> = []
    public hora: string = '00'
    public minuto: string = '00'
    public IsIntegracion: any = 0

    constructor(
        public _ingresarMultaService: IngresarMultaService,
        public _multasService: MultasService,
        public _globalService: GlobalServicesService,
        public _fileService: FileServiceService,
        private toastr: ToastrService,
        private alertServices: AlertService
    ) {}

    ngOnInit() {
        const data = { id_proyecto: sessionStorage.getItem('IdSelectProject') }
        this.IsIntegracion = sessionStorage.getItem('Integracion')
        forkJoin(this._multasService.GetMultas(), this._globalService.getBienesByProyecto(data)).subscribe(
            (resp: any) => {
                for (let i = 0; i < 24; i++) {
                    if (i < 10) {
                        this.horas.push(`0${i.toString()}`)
                    } else {
                        this.horas.push(`${i.toString()}`)
                    }
                }
                for (let i = 0; i <= 60; i++) {
                    if (i < 10) {
                        this.minutos.push(`0${i.toString()}`)
                    } else {
                        this.minutos.push(`${i.toString()}`)
                    }
                }

                this.multas = resp[0]
                if (this.IsIntegracion == 1) {
                    this.multas = this.multas.filter((element) => {
                        return element.externo_id != null
                    })
                }
                this.dptos = resp[1]
            }
        )
    }

    async handleFileSelect(evt: any) {
        const base64 = await this._fileService.base64Encoder(evt)
        this.base64 = base64
    }

    closeModal() {
        this._ingresarMultaService.hideModal()
    }

    crearMulta() {
        let schema = JSON.parse(sessionStorage.getItem('LoginUsuario'))
        schema = schema.filter((element) => {
            return element.id_proyecto == sessionStorage.getItem('IdSelectProject')
        })

        const data = {
            schema: schema[0].cliente,
            id_bien: this.numeroBien,
            id_multa: this.idMulta,
            observacion: this.observacion,
            fecha: moment(
                `${this.fechaSelected.year}-${this.fechaSelected.month}-${this.fechaSelected.day} ${this.format(
                    this.hora
                )}:${this.format(this.minuto)}:00`
            ).format('YYYY-MM-DD HH:mm:ss'),
            foto: this.base64
        }
        if (!this.numeroBien || !this.idMulta || !this.observacion || !this.fechaSelected) {
            this.alertServices.danger('Debe completar todos los campos del formulario')
            return
        }
        this._multasService.ingresarMulta(data).subscribe((resp: any) => {
            this.toastr.success(resp.message, 'Éxito', {
                timeOut: 3000,
                closeButton: true,
                progressBar: true,
                positionClass: 'toast-bottom-left'
            })
            this._ingresarMultaService.hideModal()
        })
    }

    private format(numero: string) {
        return numero.length === 1 ? `0${numero}` : numero
    }

    public clearPlaceHolder() {
        this.textAreaPlaceholder = ''
    }

    public onBlur() {
        this.textAreaPlaceholder = 'Aquí va su Observación'
    }
}
