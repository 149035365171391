<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>
<!-- header calendario -->

<!-- body calendario -->
<div class="margen-lateral">
    <div class="bloque-areas">
        <div class="row">
            <div class="contenedor-selectores col-12">
                <span class="bloque-1-area col-12 text-center">
                    <div class="contenedor-selector-area col-12">
                        <div class="cont-btn">
                            <a class="btn-volver" href="javascript:history.back()"><i class="fas fa-arrow-left"></i></a>
                        </div>
                        <span class="titulo">Área o zona</span>
                        <select (change)="filterAreas()" [(ngModel)]="areaSelected" class="estilo-select-1">
                            <option value="default">Seleccione Área</option>
                            <option value="ASCENSOR">Ascensores</option>
                            <option value="ESTACIONAMIENTO VISITA">Estacionamientos</option>
                            <option value="QUINCHO">Quinchos</option>
                            <option value="LAVADORA">Lavadoras</option>
                            <option value="PISCINA">Piscina</option>
                            <option value="SALÓN">Salones</option>
                            <option value="SECADORA">Secadoras</option>
                            <!--       <option value="SALÓN">Salón Gourmet</option>
                            <option value="SALÓN">Salón multiuso</option> -->
                        </select>
                    </div>
                </span>
            </div>

            <div class="col-10 text-center m-auto">
                <div class="titulo">
                    <h4 class="card-title">Calendario de reservas</h4>
                    <h6 class="card-subtitle">Interactua con el calendario para visualizar las reservas</h6>
                </div>
                <hr />
                <full-calendar deepChangeDetection="false" [options]="calendarOptions"></full-calendar>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
<app-horario-diario></app-horario-diario>
