<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla">
            <div class="centered-title">
                <b>Ingresa el nombre</b><br />
                <div class="subtitle">Ingresa los datos para ver resultados de búsqueda</div>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped"
                    [dtTrigger]="dtTrigger"
                    datatable
                    [dtOptions]="dtOptions"
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="ico-estado-2">Departamento</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-3">Nombre residente</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Sector</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of contagiados">
                            <td scope="row">{{ item.numero_bien }}</td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.nombre_sector }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
