<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>

<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla">
            <div class="centered-title">
                <b>Ingresa el nombre, apellido o número de departamento</b><br />
                <div class="subtitle">Ingresa los datos para ver resultados de búsqueda</div>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped"
                    [dtTrigger]="dtTrigger"
                    datatable
                    [dtOptions]="dtOptions"
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="ico-estado-2">Nº visita</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-2">Número del bien</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Nombre</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Rut</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Entrada</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Salida</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Patente</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Estacionamiento</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data">
                            <td scope="row">{{ item.id_visita }}</td>
                            <td>{{ item.numero_bien }}</td>
                            <td>{{ item.nombre }} {{ item.apellido }}</td>
                            <td>{{ item.rut }}</td>
                            <td>{{ item.fecha }} {{ item.hora }}</td>
                            <td>{{ item.fecha_salida }} {{ item.hora_salida }}</td>
                            <td>{{ item.patente }}</td>
                            <td>{{ item.nombre_estacionamiento }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
