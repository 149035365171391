import { HttpClient } from '@angular/common/http'
import { Injectable, EventEmitter } from '@angular/core'
import { map } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class HorarioDiarioService {
    public oculto: string = 'oculto'
    public $dayData: EventEmitter<any> = new EventEmitter<any>()
    public $DataCronograma: EventEmitter<any> = new EventEmitter<any>()
    public recibirReservas$: Subject<any> = new Subject<any>()
    constructor(public http: HttpClient) {}

    hideModal() {
        this.oculto = 'oculto'
    }

    showModal(selectedDay: any) {
        this.$dayData.emit(selectedDay)
        this.oculto = ''
    }

    EmitCronograma(data) {
        this.$DataCronograma.emit(data)
    }

    EliminarReserva(data: object) {
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/EliminarReserva'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    NextReservas() {
        this.recibirReservas$.next()
    }

    ObsReservas$(): Observable<any> {
        return this.recibirReservas$.asObservable()
    }
}
