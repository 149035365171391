import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { UsuarioService } from '../usuario/usuario.service'
import { SocketServiceService } from '../../services/socket-service.service'
import { DeviceDetectorService } from 'ngx-device-detector'
@Injectable()
export class LoginGuardGuard implements CanActivate {
    public deviceInfo: any

    constructor(
        public _usuarioService: UsuarioService,
        public _router: Router,
        private _socketService: SocketServiceService,
        private deviceService: DeviceDetectorService
    ) {}

    canActivate() {
        if (this._usuarioService.estaLogueado()) {
            this.getWebUserAgentInformation()
            this._socketService.iniciarSocket(this.deviceInfo)
            return true
        } else {
            this._router.navigate(['/login'])
            return false
        }
    }

    getWebUserAgentInformation() {
        this.deviceInfo = this.deviceService.getDeviceInfo()
        // const isMobile = this.deviceService.isMobile()
        // const isTablet = this.deviceService.isTablet()
        // const isDesktopDevice = this.deviceService.isDesktop()
        this.deviceInfo.isMobile = this.deviceService.isMobile()
        this.deviceInfo.isTablet = this.deviceService.isTablet()
        this.deviceInfo.isDesktopDevice = this.deviceService.isDesktop()
    }
}
