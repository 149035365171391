import { Component, OnInit } from '@angular/core'
import { forkJoin, Subscription } from 'rxjs'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { MultasService } from 'src/app/services/multas/multas.service'
import { EditarMultaService } from './editar-multa.service'
import * as moment from 'moment'
import { Multa } from '../../interfaces/interface'
import { ToastrService } from 'ngx-toastr'
import { FileServiceService } from 'src/app/services/fileService/file-service.service'
import { AlertService } from '@full-fledged/alerts'

@Component({
    selector: 'app-editar-multa',
    templateUrl: './editar-multa.component.html',
    styles: []
})
export class EditarMultaComponent implements OnInit {
    public fechaMaxDate: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public multas: Array<any> = []
    public dptos: Array<any> = []
    public horas: Array<string> = []
    public minutos: Array<string> = []
    public textAreaPlaceholder: string = 'Aquí va su Observación'
    public base64: string = ''
    public numeroBien: any
    public idMulta: any
    public observacion: any = ''
    public $getMulta: Subscription
    public hora: string
    public minuto: any
    public fechaSelected: any
    public id: any

    constructor(
        public _multasService: MultasService,
        public _editarMultaService: EditarMultaService,
        public _globalService: GlobalServicesService,
        private toastr: ToastrService,
        public _fileService: FileServiceService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        const data = { id_proyecto: sessionStorage.getItem('IdSelectProject') }
        forkJoin(this._multasService.GetMultas(), this._globalService.getBienesByProyecto(data)).subscribe(
            (resp: any) => {
                for (let i = 0; i < 24; i++) {
                    if (i < 10) {
                        this.horas.push(`0${i.toString()}`)
                    } else {
                        this.horas.push(`${i.toString()}`)
                    }
                }

                for (let i = 0; i <= 60; i++) {
                    if (i < 10) {
                        this.minutos.push(`0${i.toString()}`)
                    } else {
                        this.minutos.push(`${i.toString()}`)
                    }
                }

                this.multas = resp[0]
                this.dptos = resp[1]
            }
        )
        this.$getMulta = this._editarMultaService.$dataMulta.subscribe((resp: Multa) => {
            this.id = resp.id
            this.numeroBien = resp.bien_id
            this.idMulta = resp.multa_id
            this.observacion = resp.observacion
            this.hora = moment(resp.fecha).format('HH')
            this.minuto = parseInt(moment(resp.fecha).format('mm'))
            this.fechaSelected = {
                year: parseInt(moment(resp.fecha).format('YYYY')),
                month: parseInt(moment(resp.fecha).format('MM')),
                day: parseInt(moment(resp.fecha).format('DD'))
            }
        })
    }

    public clearPlaceHolder() {
        this.textAreaPlaceholder = ''
    }

    public onBlur() {
        this.textAreaPlaceholder = 'Aquí va su Observación'
    }

    closeModal() {
        this._editarMultaService.hideModal()
    }

    async handleFileSelect(evt: any) {
        const base64 = await this._fileService.base64Encoder(evt)
        this.base64 = base64
    }

    public editarMulta() {
        let schema = JSON.parse(sessionStorage.getItem('LoginUsuario'))
        schema = schema.filter((element) => {
            return element.id_proyecto == sessionStorage.getItem('IdSelectProject')
        })
        const data = {
            id: this.id,
            schema: schema[0].cliente,
            id_bien: this.numeroBien,
            id_multa: this.idMulta,
            observacion: this.observacion,
            fecha: moment(
                `${this.fechaSelected.year}-${this.fechaSelected.month}-${this.fechaSelected.day} ${this.format(
                    this.hora
                )}:${this.format(this.minuto)}:00`
            ).format('YYYY-MM-DD HH:mm:ss'),
            foto: this.base64
        }

        if (!this.numeroBien || !this.idMulta || !this.observacion || !this.fechaSelected) {
            this.alertService.danger('Debe completar todos los campos del formulario')
            return
        }
        this._multasService.editarMulta(data).subscribe(() => {
            this.toastr.success('Multa editada')
            this._editarMultaService.hideModal()
        })
    }

    private format(numero: string) {
        return numero.length === 1 ? `0${numero}` : numero
    }
}
