import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { GlobalServicesService } from '../global-services.service'
import { OpenVidu, Publisher, Session, StreamEvent, StreamManager, Subscriber } from 'openvidu-browser'
import { catchError } from 'rxjs/operators'
import { throwError as observableThrowError } from 'rxjs'
import { RecibirLlamadaService } from 'src/app/modal/recibir-llamada/recibir-llamada.service'
import { SocketServiceService } from '../socket-service.service'
import { RealizarLlamadaService } from 'src/app/modal/realizar-llamada/realizar-llamada.service'
import { RealizarLlamadaPerdidaService } from 'src/app/modal/realizar-llamada-perdida/realizar-llamada-perdida.service'
import { Notificacion } from 'src/app/models/notificacion.model'
import swal from 'sweetalert2'
import { environment } from 'src/environments/environment'
import { AlertService } from '@full-fledged/alerts'

@Injectable({
    providedIn: 'root'
})
export class CitofoniaService {
    public ocupado: boolean = false
    public _refresh = new Subject<void>()
    public refresh_table$: Subject<any> = new Subject<any>()
    public OPENVIDU_SERVER_URL = 'https://cl.datcapital.cl'
    public OPENVIDU_SERVER_SECRET = 'sakjbsvdzx'
    // OpenVidu objects
    public OV: OpenVidu
    public session: Session
    public publisher: StreamManager // Local
    public subscribers: StreamManager[] = [] // Remotes
    public user_data: any = JSON.parse(sessionStorage.getItem('identidad'))

    // Join form
    public mySessionId: string
    public myUserName: string
    public loading: boolean = false
    public loading_make_call: boolean = true
    // Main video of the page, will be 'publisher' or one of the 'subscribers',
    // updated by click event in UserVideoComponent children
    public mainStreamManager: StreamManager
    public timer: Array<any> = []
    public realizar: boolean = false
    public notificacion = new Notificacion(null, 'conserje', '', '')
    public width: any = 650
    public height: any = 400
    public modal: boolean = true

    constructor(
        public http: HttpClient,
        public _globalService: GlobalServicesService,
        public _recibirLlamadaService: RecibirLlamadaService,
        public _socketService: SocketServiceService,
        public _realizarLlamadaService: RealizarLlamadaService,
        public _realizarLlamadaPerdidaService: RealizarLlamadaPerdidaService,
        private alertService: AlertService
    ) {}

    get refresh() {
        return this._refresh
    }

    loadMissingCallPaginated() {
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ListCitofonia'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    CreateLlamadaCitofonia(data) {
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/CreateLlamadaCitofonia'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    joinSession(id_salon?: any) {
        // this.mySessionId = undefined
        if (id_salon) this.mySessionId = id_salon
        this.loading = true
        this.OV = new OpenVidu()
        this.session = this.OV.initSession()
        let i = setTimeout(() => {
            this._recibirLlamadaService.hideModal()
            this._realizarLlamadaService.hideModal()
            this._realizarLlamadaPerdidaService.hideModal()
            this.leaveSession()
            //ACA NOTI DE NO CONTESTA
            let user_id: any
            if (this._realizarLlamadaPerdidaService) {
                user_id = this._realizarLlamadaPerdidaService.id_usuario
            }
            if (this._realizarLlamadaService) {
                user_id = this._realizarLlamadaService.id_usuario
            }
            this.notificacion = new Notificacion(
                user_id,
                'conserje',
                'Tienes una llamada perdida desde conserjería',
                'Llamada perdida'
            )
            this._globalService.enviarNotificacion(this.notificacion).subscribe(
                (response) => {
                    this.alertService.success('Notificación enviada.')
                    this.notificacion = new Notificacion(null, 'conserje', '', '')
                },
                (error) => {
                    this.alertService.danger('Problemas al enviar notificación')
                    this.notificacion = new Notificacion(null, 'conserje', '', '')
                }
            )
            let data_colgar = {
                id_usuario: user_id
            }
            this._socketService.colgarLlamadaWeb(data_colgar)
        }, 40000)
        this.timer.push(i)
        this.session.on('streamCreated', (event: StreamEvent) => {
            let subscriber: Subscriber = this.session.subscribe(event.stream, undefined)
            this.subscribers.push(subscriber)
            this.loading = false
            this.loading_make_call = false
            this._socketService.stopAudioCitofonia()
            this.timer.forEach(clearInterval)
        })
        this.session.on('streamDestroyed', (event: StreamEvent) => {
            this.deleteSubscriber(event.stream.streamManager)
        })
        this.getToken().then((token) => {
            this.session
                .connect(token, { clientData: this.myUserName })
                .then(() => {
                    let publisher: Publisher = this.OV.initPublisher(undefined, {
                        audioSource: undefined, // The source of audio. If undefined default microphone
                        videoSource: undefined, // The source of video. If undefined default webcam
                        publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                        publishVideo: true, // Whether you want to start publishing with your video enabled or not
                        resolution: '640x480', // The resolution of your video
                        frameRate: 30, // The frame rate of your video
                        insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                        mirror: false // Whether to mirror your local video or not
                    })
                    this.session.publish(publisher)
                    this.mainStreamManager = publisher
                    this.publisher = publisher
                    // this.loading = false
                    // this.loading_make_call = false
                })
                .catch((error) => {
                    console.error('There was an error connecting to the session:', error.code, error.message)
                })
        })
    }

    private deleteSubscriber(streamManager: StreamManager): void {
        let index = this.subscribers.indexOf(streamManager, 0)
        if (index > -1) {
            this.subscribers.splice(index, 1)
        }
    }

    async getToken(): Promise<string> {
        if (!this.realizar) {
            this.mySessionId = this._recibirLlamadaService.id_sala
        }
        return this.createSession(this.mySessionId).then((sessionId) => {
            return this.createToken(sessionId)
        })
    }

    createSession(sessionId: any) {
        if (sessionId == null || sessionId === undefined) {
            sessionId = `Sala${this.user_data.id_login_usuario}Proyecto${sessionStorage.getItem('IdSelectProject')}`
        }
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({ customSessionId: sessionId })
            const options = {
                headers: new HttpHeaders({
                    Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                    'Content-Type': 'application/json'
                })
            }
            return this.http
                .post(this.OPENVIDU_SERVER_URL + '/api/sessions', body, options)
                .pipe(
                    catchError((error) => {
                        if (error.status === 409) {
                            resolve(sessionId)
                        } else {
                            console.warn(
                                'No connection to OpenVidu Server. This may be a certificate error at ' +
                                    this.OPENVIDU_SERVER_URL
                            )
                            if (
                                window.confirm(
                                    'No connection to OpenVidu Server. This may be a certificate error at "' +
                                        this.OPENVIDU_SERVER_URL +
                                        '"\n\nClick OK to navigate and accept it. If no certificate warning is shown, then check that your OpenVidu Server' +
                                        'is up and running at "' +
                                        this.OPENVIDU_SERVER_URL +
                                        '"'
                                )
                            ) {
                                location.assign(this.OPENVIDU_SERVER_URL + '/accept-certificate')
                            }
                        }
                        return observableThrowError(error)
                    })
                )
                .subscribe((response) => {
                    console.log(response)
                    resolve(response['id'])
                })
        })
    }

    createToken(sessionId: any): Promise<string> {
        return new Promise((resolve, reject) => {
            const body = JSON.stringify({ session: sessionId })
            const options = {
                headers: new HttpHeaders({
                    Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                    'Content-Type': 'application/json'
                })
            }
            return this.http
                .post(this.OPENVIDU_SERVER_URL + '/api/tokens', body, options)
                .pipe(
                    catchError((error) => {
                        reject(error)
                        return observableThrowError(error)
                    })
                )
                .subscribe((response) => {
                    console.log(response)
                    resolve(response['token'])
                })
        })
    }

    leaveSession(optional?: boolean) {
        if (this.session) {
            this.session.disconnect()
        }
        // Empty all properties...
        let data = {
            id_usuario: this.user_data.id_login_usuario,
            ocupado: false
        }
        this._socketService.stopAudioCitofonia()
        this._socketService.estoyOcupado(data)
        this.timer.forEach(clearInterval)
        this.subscribers = []
        delete this.publisher
        delete this.session
        delete this.OV
        delete this.mainStreamManager
        delete this.mySessionId
        delete this.myUserName
        this.generateParticipantInfo()
        this._recibirLlamadaService.hideModal()
        this._realizarLlamadaService.hideModal()
        this._realizarLlamadaPerdidaService.hideModal()
        this.loading = false
        this.loading_make_call = true
        this.realizar = false
        if (optional) {
            let data = {
                id_usuario: undefined
            }
            this._recibirLlamadaService.hideModal()
            this._realizarLlamadaService.hideModal()
            //ACA VAAAAAA LA COSA

            if (this._recibirLlamadaService.id_usuario) {
                data.id_usuario = this._recibirLlamadaService.id_usuario
            } else {
                data.id_usuario = this._realizarLlamadaService.id_usuario
            }
            if (!data.id_usuario) {
                data = {
                    id_usuario: this._realizarLlamadaPerdidaService.id_usuario
                }
                this._realizarLlamadaPerdidaService.hideModal()
            }
            this.notificacion = new Notificacion(
                data.id_usuario,
                'conserje',
                'El conserje ha finalizado la llamada',
                'Llamada finalizada'
            )
            this._globalService.enviarNotificacion(this.notificacion).subscribe(() => console.log('success'))
            this._socketService.colgarLlamadaWeb(data)
        }
    }

    public generateParticipantInfo() {
        this.mySessionId = `Sala${this.user_data.id_login_usuario}Proyecto${sessionStorage.getItem('IdSelectProject')}`
        this.myUserName = this.user_data.nombre + ' ' + this.user_data.apellido
    }

    updateMainStreamManager(streamManager: StreamManager) {
        this.mainStreamManager = streamManager
    }

    //------------------Observable-------------------------

    refreshTableService() {
        this.refresh_table$.next()
    }

    TableService$(): Observable<any> {
        return this.refresh_table$.asObservable()
    }
}
