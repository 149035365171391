import { Component, OnInit, ViewChild } from '@angular/core'
import { IngresarBitacoraService } from 'src/app/modal/ingresar-bitacora/ingresar-bitacora.service'
import { BitacoraService } from 'src/app/services/bitacora/bitacora.service'
import { Bitacora } from 'src/app/models/bitacora.model'
import { VerDetalleService } from 'src/app/modal/ver-detalle/ver-detalle.service'
import * as moment from 'moment'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'
import { NgxSpinnerService } from 'ngx-spinner'

moment.locale('es')
moment.updateLocale('es', {
    relativeTime: {
        past: function (input) {
            return input === 'just now' ? input : input + ' ago'
        },
        s: 'just now',
        future: '%s'
    }
})
@Component({
    selector: 'app-bitacora',
    templateUrl: './bitacora.component.html',
    styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit {
    public fechaSelectedInicio: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public fechaSelectedFin: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }

    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false
    dtTrigger: Subject<any> = new Subject<any>()
    public button_state_ok: boolean = false
    public filtered_section: boolean = false
    public button_state_close: boolean = false
    public desde: number = 1
    public totalRegistros: number = 0
    public page: number = 1
    public binnacles: Bitacora[] = []
    public registerPage: number
    public init_page: number
    public final_page: number
    public pagination_type: number = 1
    public binnacle: any
    public fechaInit: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public fechaFinal: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public modulo: any = 0
    public modulo_elejido: any = null
    title = 'angulardatatables'
    dtOptions: any = {}
    //DataTables.Settings = {};
    //buttons?: any[];

    constructor(
        private _ingresarBitacoraService: IngresarBitacoraService,
        private _bitacoraService: BitacoraService,
        public _verDetalleService: VerDetalleService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {}

    onDateSelected() {
        this.loadBinnacle(this.fechaSelectedInicio, this.fechaSelectedFin)
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe()
    }

    ngOnInit() {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
            order: [[0, 'desc']],
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel'
                } /* ,
				{
					extend: 'csvHtml5',
					text: '<i class="fas fa-file-csv"></i>',
					titleAttr: 'CSV'
				} */
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }

        /* this._bitacoraService.refresh
			.subscribe(() => {
				this.loadBinnacle(this.pagination_type);
			}); */
        this.onDateSelected()
        //this.loadBinnacle(this.pagination_type);
    }

    insertBinnacle() {
        this._ingresarBitacoraService.showModal()
    }

    loadBinnacle(inicio, fin) {
        this.spinner.show()
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            fecha_inicio: moment(`${inicio.year}-${inicio.month}-${inicio.day}`).format('YYYY-MM-DD'),
            fecha_fin: moment(`${fin.year}-${fin.month}-${fin.day}`).format('YYYY-MM-DD')
        }

        this._bitacoraService.getAllBinnaclePaginated(data).subscribe((result: any) => {
            
            this.binnacles = result
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
            this.spinner.hide()
        })
    }

    showDetail(binnacle_id: number) {
        
        this._bitacoraService.getBinnacle(binnacle_id).subscribe((resp: any) => {
            this.binnacle = resp
            this._verDetalleService.showModal()
        })
    }
}
