<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>

<div class="margen-lateral">
    <div class="bloque-reserva display">
        <div class="txt-1">
            <h1 class="propiedades">Reserva una zona del edificio:</h1>
        </div>
        <div routerLink="/calendario" class="contenedor-btn-disponibilidad margin-btn margin-r">
            <input class="estilo-boton pointer" type="button" value="Buscar Reservas" />
        </div>

        <!-- <div class="contenedor-selectores">
    </div> -->
        <!-- <div class="borrado"></div> -->
    </div>
</div>
<div class="margen-lateral">
    <div class="bloque-areas">
        <div class="titulo">
            <h2 class="estilo-titulo">Áreas Comunes</h2>
        </div>

        <div class="listado">
            <ul>
                <li><span class="verde"></span>Disponible</li>
                <li><span class="amarillo"></span>En uso</li>
                <li><span class="rojo"></span>Excedido</li>
                <li><span class="celeste"></span>Reservado</li>
                <li><span class="gris"></span>En mantención</li>
            </ul>
        </div>
        
        <div class="contenedor-btn-accion">
            <a data-toggle="modal" data-target="#modal-mantenimiento" href="#"><img
                    src="assets/images/img-btn.png" /></a>
        </div>

        <div class="contenedor-dos-bloques">
            <div class="bloque-izquierdo-listado">
                <div (click)="FilterAreas('ESTACIONAMIENTO VISITA')" class="div-1">
                    <div class="fade-in-1">
                        <a><span class="ico-1"><img src="assets/images/ico-1.png" alt="" title="" /></span><span
                                class="espaciado">Estacionamientos</span>
                            <span class="valor">{{ total_estacionamientos }}</span></a>
                    </div>
                </div>
                <div (click)="FilterAreas('QUINCHO')" class="div-1">
                    <div class="fade-in-2">
                        <a><span class="ico-1"><img src="assets/images/ico-2.png" alt="" title="" /></span><span
                                class="espaciado">Quinchos</span> <span class="valor">{{ total_quinchos }}</span></a>
                    </div>
                </div>
                <div (click)="FilterAreas('LAVADORA')" class="div-1">
                    <div class="fade-in-3">
                        <a><span class="ico-1"><img src="assets/images/ico-3.png" alt="" title="" /></span><span
                                class="espaciado">Lavadoras</span>
                            <span class="valor">{{ total_lavanderia }}</span></a>
                    </div>
                </div>
                <div (click)="FilterAreas('PISCINA')" class="div-1">
                    <div class="fade-in-4">
                        <a><span class="ico-1"><img src="assets/images/ico-4.png" alt="" title="" /></span><span
                                class="espaciado">Piscinas</span> <span class="valor">{{ total_piscina }}</span></a>
                    </div>
                </div>
                <div (click)="FilterAreas('SALÓN')" class="div-1">
                    <div class="fade-in-5">
                        <a><span class="ico-1"><img src="assets/images/ico-5.png" alt="" title="" /></span><span
                                class="espaciado">Salones</span> <span class="valor">{{ total_salones }}</span></a>
                    </div>
                </div>
                <div (click)="FilterAreas('SECADORA')" class="div-1">
                    <div class="fade-in-6">
                        <a><span class="ico-1"><img src="assets/images/ico-6.png" alt="" title="" /></span><span
                                class="espaciado">Secadoras</span>
                            <span class="valor">{{ total_secadoras }}</span></a>
                    </div>
                </div>

                <div (click)="FilterAreas('ASCENSOR')" class="div-1">
                    <div class="fade-in-8">
                        <a><span class="ico-1"><img src="assets/images/ico-8.png" alt="" title="" /></span><span
                                class="espaciado">Ascensores</span>
                            <span class="valor">{{ total_ascensores }}</span></a>
                    </div>
                </div>
            </div>
            <div class="bloque-derecho-info">
                <div>
                    <div>
                        <ng-container *ngFor="let item of areas_actuales; let i = index">
                            <div class="capsula">
                                <div class="bloque-1" [ngClass]="{
                                        'fondo-verde': item.estado == 4,
                                        'fondo-naranjo': item.estado == 5,
                                        'fondo-rojo': item.estado == 6,
                                        'fondo-celeste': item.estado == 7,
                                        'fondo-plomo': item.mantenimiento.length > 0
                                    }">
                                    <div class="ico"><img src="{{ item.icono }}" alt="" title="" /></div>
                                    <div class="titulo-capsula">{{ item.numero_bien }} <br> {{item.nombre_torre}}</div>
                                </div>
                                <!--bloque que se muestra cuando no esta en uso-->
                                <ng-container *ngIf="item.estado == 4 && item.mantenimiento.length == 0">
                                    <div class="bloque-principal">
                                        <div class="bajada-1">Disponible</div>
                                        <div class="bajada-2">Ingresa para reservar</div>
                                    </div>
                                    <div class="bloque-inferior">
                                        <input (click)="DetalleAreaComun(i, item)" data-toggle="modal"
                                            class="estilo-boton-2" type="button" value="Reservar" />
                                       <!--  <input (click)="UtilizarAreaComun(i, item)" data-toggle="modal"
                                            class="estilo-boton-azul ml-2" type="button" value="Utilizar" /> -->
                                    </div>
                                </ng-container>
                                <!--bloque que se muestra cuando no esta en uso-->
                                <!--bloque que se muestra cuando esta en uso-->
                                <ng-container *ngIf="item.estado == 5 && item.mantenimiento.length == 0">
                                    <div class="bloque-2">
                                        <div class="titulo-capsula color-naranjo">{{ item.RelojArea }}</div>
                                    </div>
                                    <div class="bloque-3">
                                        <div class="titulo-capsula">{{ item.nombre_usuario }}</div>
                                        <div class="titulo-capsula">Depto: {{ item.numero_bien_usuario }}</div>
                                        <div *ngIf="item.patente != null" class="titulo-capsula">
                                            Patente: {{ item.patente }}
                                        </div>
                                    </div>
                                    <div class="bloque-4">
                                        <div class="titulo-capsula">
                                            <span>Inicio: {{ item.hora_entrada }}</span>
                                            <span class="separador"><img src="assets/images/separador-2.jpg" alt=""
                                                    title="" /></span>
                                            <span class="color-naranjo">Fin: {{ item.hora_salida }}</span>
                                        </div>
                                    </div>
                                    <div class="bloque-inferior">
                                        <input (click)="FinalizarReserva(item)" data-toggle="modal"
                                            class="estilo-boton-2" type="button" value="Finalizar" />
                                    </div>
                                </ng-container>
                                <!--bloque que se muestra cuando esta en uso-->
                                <!--bloque que se muestra cuando esta excedido-->
                                <ng-container *ngIf="item.estado == 6 && item.mantenimiento.length == 0">
                                    <div class="bloque-2">
                                        <div class="titulo-capsula">{{ item.RelojArea }}</div>
                                    </div>
                                    <div class="bloque-3">
                                        <div class="titulo-capsula">Depto: {{ item.numero_bien }}</div>
                                        <div class="titulo-capsula">Usuario: {{ item.nombre_usuario }}</div>
                                    </div>
                                    <div class="bloque-4">
                                        <div class="titulo-capsula">
                                            <span>Inicio: {{ item.hora_entrada }}</span>
                                            <span class="separador"><img src="assets/images/separador-2.jpg" alt=""
                                                    title="" /></span>
                                            <span>Fin: {{ item.hora_salida }}</span>
                                        </div>
                                    </div>
                                    <div class="bloque-inferior">
                                        <input (click)="FinalizarReserva(item)" data-toggle="modal"
                                            class="estilo-boton-2" type="button" value="Finalizar" />
                                    </div>
                                </ng-container>
                                <!--bloque que se muestra cuando esta excedido-->
                                <!--bloque que se muestra cuando esta reservado-->
                                <ng-container *ngIf="item.estado == 7 && item.mantenimiento.length == 0">
                                    <div class="bloque-2">
                                        <div class="titulo-capsula">{{ item.RelojArea }}</div>
                                    </div>
                                    <div class="bloque-3">
                                        <div class="titulo-capsula">Depto: {{ item.numero_bien }}</div>
                                        <div class="titulo-capsula">Usuario: {{ item.nombre_usuario }}</div>
                                    </div>
                                    <div class="bloque-4">
                                        <div class="titulo-capsula">
                                            <span>Inicio: {{ item.hora_entrada }}</span>
                                            <span class="separador"><img src="assets/images/separador-2.jpg" alt=""
                                                    title="" /></span>
                                            <span>Fin: {{ item.hora_salida }}</span>
                                        </div>
                                    </div>
                                    <div class="bloque-inferior">
                                        <input (click)="EliminarReserva(item)" data-toggle="modal"
                                            class="estilo-boton-2 mr-2" type="button" value="Eliminar" />
                                        <input (click)="IniciarReserva(item)" data-toggle="modal" class="estilo-boton-2"
                                            type="button" value="Iniciar" />
                                    </div>
                                </ng-container>
                                <!--bloque que se muestra cuando esta reservado-->
                                <!--bloque que se muestra cuando esta en mantencion-->
                                <ng-container *ngIf="item.mantenimiento.length > 0">
                                    <div class="bloque-principal">
                                        <div class="bajada-1">En Manteción</div>
                                        <!--<div class="bajada-2"> Desde {{item.mantenciones[0].fecha_inicio}}</div>-->
                                        <!--<div class="bajada-3">Hasta {{item.mantenciones[0].fecha_fin}}</div>-->
                                    </div>
                                    <div (click)="DesactivarMantencion(item)" class="bloque-inferior">
                                        <input data-toggle="modal" class="estilo-boton-2" type="button"
                                            value="Activar" />
                                    </div>
                                </ng-container>
                                <!--bloque que se muestra cuando esta en mantencion-->
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="borrado"></div>
        </div>
    </div>
</div>

<!-- Modal Reserva-->

<div class="contenedor-modal-reserva">
    <div class="modal fade" id="modal-reserva-integracion">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Reserva {{ NombreAreaSelected }}</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="contenedor-selector-der mb-2">
                        <div class="titulo-tipo-2">Fecha</div>
                        <input (dateSelect)="GetAreaComunByBien(fechaSelected)" (click)="d.toggle()" autocomplete="off"
                            placeholder="yyyy-mm-dd" [maxDate]="fechaMaxDate" name="dp" [minDate]="fechaMinDate"
                            [(ngModel)]="fechaSelected" [ngModelOptions]="{ standalone: true }" ngbDatepicker
                            #d="ngbDatepicker" class="estilo-input-1" type="text" />
                    </div>
                    <div class="borrado"></div>
                    <div class="contenedor-selector-izq">
                        <div class="titulo-tipo-2">Número de Departamento</div>
                        <select [(ngModel)]="selectedValueBien" class="estilo-select-1 col-7"
                            (change)="GetUserBybienes($event.target.value)" [ngModelOptions]="{ standalone: true }">
                            <option value="0">Seleccione departamento</option>
                            <option value="{{ item | json }}" *ngFor="let item of this.ArrayBienes">
                                {{ item.numero_bien }}
                            </option>
                        </select>
                    </div>
                    <div class="contenedor-selector-der">
                        <div class="titulo-tipo-2">Seleccione residente</div>
                        <select [(ngModel)]="selectedValueUsuario" class="estilo-select-1"
                            [ngModelOptions]="{ standalone: true }">
                            <option value="0">Seleccione residente</option>
                            <option value="{{ item | json }}" *ngFor="let item of this.ArrayUsuarios">
                                {{ item.nombre }} {{ item.apellido }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="ObjReserva.tipo == 'ESTACIONAMIENTO VISITA'" class="contenedor-selector-izq mt-2">
                        <div class="titulo-tipo-2">Ingrese patente</div>
                        <input [(ngModel)]="inputPatente" placeholder="ingrese patente" class="clase-x" />
                    </div>
                    <div class="float-left mt-2">
                        <div class="titulo-tipo-2">Horario</div>
                        <select [(ngModel)]="selectedValueHorario" class="estilo-select-1"
                            [ngModelOptions]="{ standalone: true }">
                            <option value="0">Seleccione horario</option>
                            <option value="{{ item | json }}" *ngFor="let item of Horarios_Reservas">
                                {{ item.hora_inicio }} -
                                {{ item.hora_fin }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar" data-dismiss="modal">Cancelar</button>
                    <button (click)="informarCargos()" type="button" class="btn btn-modal-enviar">Reservar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal confirmacion -->

<div class="contenedor-modal-confirmacion">
    <div class="modal fade" id="modal-confirmacion">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Confirmación</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="text-central">¿Estás seguro?</div>
                    <br />
                    <div class="text">
                        <p>
                            Se aplicarán cargos por un valor de {{ valor_reserva }}, se enviará una notificación y
                            correo al residente.
                        </p>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar" data-dismiss="modal">Cancelar</button>
                    <button (click)="EnviarReserva()" type="button" class="btn btn-modal-enviar" data-dismiss="modal">
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal confirmacion mantencion-->

<div class="contenedor-modal-confirmacion">
    <div class="modal fade" id="modal-confirmacion-mantencion">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Confirmación</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="text-central">¿Estás seguro?</div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar" data-dismiss="modal">Cancelar</button>
                    <button (click)="InsertMantencion()" type="button" class="btn btn-modal-enviar"
                        data-dismiss="modal">
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Mantenimiento -->

<div class="contenedor-modal-mantenimiento">
    <div class="modal fade" id="modal-mantenimiento">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header gris-modal">
                    <h4 class="modal-title">Mantenimiento</h4>
                    <button type="button" class="close" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="contenedor-selector-izq">
                        <div class="titulo-tipo-2">Área común</div>
                        <select (change)="AreaMentecion()" [(ngModel)]="areaSelectedMantencion" class="estilo-select-1">
                            <option value="0">Seleccione Área</option>
                            <option value="ASCENSOR">Ascensores</option>
                            <option value="ESTACIONAMIENTO VISITA">Estacionamientos</option>
                            <!-- <option value="Salon Gourmet">Salon Gourmet </option> -->
                            <option value="QUINCHO">Quinchos</option>
                            <option value="LAVADORA">Lavadoras</option>
                            <option value="PISCINA">Piscina</option>
                            <option value="SALÓN DE EVENTOS">Sala de eventos</option>
                            <option value="SECADORA">Secadoras</option>
                        </select>
                    </div>
                    <div class="contenedor-selector-der">
                        <div class="titulo-tipo-2">Zona</div>
                        <select [(ngModel)]="zonaSelectedMantencion" class="estilo-select-1">
                            <option value="0">Seleccion zona</option>
                            <option value="{{ item | json }}" *ngFor="let item of zonas">{{ item.numero_bien }}</option>
                        </select>
                    </div>
                    <!--inicio mantencion-->
                    <div class="contenedor-selector-izq">
                        <div class="titulo-tipo-2">Fecha Desde</div>
                        <input name="fechaInicioMantencion" autocomplete="off" (click)="d1.toggle()"
                            placeholder="yyyy-mm-dd" name="dp1" [minDate]="fechaMinDate"
                            [(ngModel)]="fechaInicioMantencion" [ngModelOptions]="{ standalone: true }" ngbDatepicker
                            #d1="ngbDatepicker" placeholder="Seleccione un día" class="input-modal-calendario"
                            type="text" />
                    </div>
                    <!-- <div class="contenedor-selector-der">
            <div class="titulo-tipo-2">Hora</div>
            <select [(ngModel)]="horaInicioMantencion" class="estilo-select-1">
              <option value="{{item}}" *ngFor="let item of Horas">{{item}}</option>
            </select>
            <input [(ngModel)]="horaInicioMantencion" placeholder="Hora Término" class="input-modal-reloj"
                            type="text">
          </div> -->
                    <!--inicio mantencion-->
                    <!--fin mantencion-->
                    <div class="contenedor-selector-izq">
                        <div class="titulo-tipo-2">Fecha Hasta</div>
                        <input name="fechaFinMantencion" autocomplete="off" (click)="d2.toggle()"
                            placeholder="yyyy-mm-dd" name="dp2" [minDate]="fechaMinDate"
                            [(ngModel)]="fechaFinMantencion" [ngModelOptions]="{ standalone: true }" ngbDatepicker
                            #d2="ngbDatepicker" placeholder="Seleccione un día" class="input-modal-calendario"
                            type="text" />
                    </div>
                    <!--  <div class="contenedor-selector-der">
            <div class="titulo-tipo-2">Hora</div>
            <select [(ngModel)]="horaFinMantencion" class="estilo-select-1">
              <option value="{{item}}" *ngFor="let item of Horas">{{item}}</option>
            </select>
            <input [(ngModel)]="horaFinMantencion" placeholder="Hora Término" class="input-modal-reloj"
                            type="text">
          </div> -->
                    <!--fin mantencion-->
                    <div class="contenedor-textarea">
                        <div class="titulo-tipo-2">Observación</div>
                        <textarea [(ngModel)]="observacion" class="textarea-modal" rows="7"></textarea>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar" data-dismiss="modal">Cancelar</button>
                    <button data-toggle="modal" (click)="AplicarMantencion()" type="button"
                        class="btn btn-modal-enviar">
                        Aplicar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>