import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { APP_ROUTES } from './app.routes'
//Rutas
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './login/login.component'
//Modulos
import { PagesModule } from './pages/pages.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ServiceModule } from './services/service.module'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DeviceDetectorModule } from 'ngx-device-detector'
import { HttpClientModule } from '@angular/common/http'

import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { AlertModule } from '@full-fledged/alerts';


@NgModule({
    declarations: [AppComponent, LoginComponent],
    //estos modulos necesitan los componentes de este modulo
    imports: [
        BrowserModule,
        AppRoutingModule,
        APP_ROUTES,
        PagesModule,
        FormsModule,
        ReactiveFormsModule,
        ServiceModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AlertModule.forRoot({ maxMessages: 5, timeout: 5000}),
        DeviceDetectorModule.forRoot(),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
