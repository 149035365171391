export class Encomienda {
    constructor(
        public id_conserje: Number,
        public id_usuario: Number,
        public id_bien: Number,
        public estado: Number,
        public id_tipo_encomienda: Number,
        public procedencia: String,
        public observacion?: String,
        public fecha_recepcion?: String,
        public fecha_entrega?: String,
        public id_proyecto?: String,
        public numero_bien?: String,
        public nombre_residente?: String,
        public nombre_encomienda?: String,
        public id_encomienda?: String,
        public correo?: String,
        public codigo?: String,
        public ids?: Array<number>,
        public nombre_receptor?: string,
        public rut_receptor?: string
    ) {}
}
