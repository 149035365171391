import { Component, OnInit, Input } from '@angular/core'
import { VerDetalleService } from './ver-detalle.service'
import { BitacoraService } from 'src/app/services/bitacora/bitacora.service'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-ver-detalle',
    templateUrl: './ver-detalle.component.html',
    styles: []
})
export class VerDetalleComponent implements OnInit {
    @Input('binnacle') binnacle: any
    public oculto: string = 'oculto'
    public auto_manual: boolean
    public nombre_accion: string
    public state: boolean
    public title: string
    public selectedValue: any
    public observer: Array<any>
    public conserje: any
    public comentary: string
    public binnacle_id: number
    public modulo: string
    public nombre: string
    public dpto: string
    public fecha: string
    public hora: string
    public id_accion: any

    constructor(
        public _verDetalleService: VerDetalleService,
        public _binnacleService: BitacoraService,
        private toastr: ToastrService
    ) {
        
    }

    ngOnInit() {
        this._verDetalleService.refresh.subscribe(() => {
            
            this.binnacle = undefined
            this.title = undefined
            this.binnacle_id = undefined
            this.selectedValue = undefined
            this.comentary = undefined
        })
    }

    ngDoCheck() {
        if (this.binnacle && this.binnacle_id === undefined) {
            this.conserje = JSON.parse(sessionStorage.getItem('identidad'))
            //bitacora manual
            if (this.binnacle[0][0].auto_manual) {
                this.auto_manual = true
                this.observer = this.binnacle[0]
                this.title = this.binnacle[0][0].texto
                this.selectedValue = this.binnacle[0][0].id_estado
                this.binnacle_id = this.binnacle[0][0].id_bitacora
                this.modulo = this.binnacle[0][0].texto

                if (this.binnacle[0][0].id_estado == 10) {
                    //10 cerrada, 11 abierta
                    this.state = false
                } else {
                    this.state = true
                    this.id_accion = this.binnacle[0][0].id_accion
                }
            } else {
                //bitacora automatica
                var fecha = this.binnacle[0][0].fecha.split(' ')
                let date = fecha[0]
                let hour = fecha[1]
                this.fecha = date
                this.hora = hour
                this.auto_manual = false
                this.nombre_accion = this.binnacle[0][0].nombre_accion
                this.nombre = this.binnacle[0][0].nombre
                this.dpto = this.binnacle[0][0].numero_bien
            }
        }
    }

    editBinnacle() {
        
        this._binnacleService
            .editBinnacle(this.title, this.selectedValue, this.comentary, this.binnacle_id, this.id_accion)
            .subscribe((resp) => {
                this.toastr.success(resp.message, 'Listo', {
                    positionClass: 'toast-bottom-left',
                    progressBar: true
                })
                this._binnacleService.refreshBinnacleCount()
                this._verDetalleService.hideModal()
                this.comentary = undefined
            })
    }

    closeModal() {
        this._verDetalleService.hideModal()
        this.binnacle = undefined
        this.title = undefined
        this.binnacle_id = undefined
        this.selectedValue = undefined
        this.comentary = undefined
    }
}
