import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class RealizarLlamadaPerdidaService {
    public hide: string = 'oculto'
    public dpto: string
    public residente: string
    public resident_obj: any
    public id_usuario: any

    constructor(public http: HttpClient) {}

    hideModal() {
        this.hide = 'oculto'
    }

    showModal(resident: any) {
        this.hide = ''
        this.residente = resident.nombre
        this.dpto = resident.departamento
        this.resident_obj = resident
        this.id_usuario = resident.id_login_usuario
    }

    deleteMissingCall(id_user: any) {
        let data = {
            id_usuario: id_user
        }
        let url = environment.URL_SERVICIOS + 'DeleteMissingCall'
        return this.http.post(url, data)
    }

    sendOfflineNotfication(id_conserje: string, usuario_llamado: string) {
        let data = {
            titulo: 'Conserje llamando',
            mensaje: 'El conserje esta intentando comunicarse con usted',
            modulo: 'citofonia',
            id_usuario_llamado: usuario_llamado,
            id_usuario: id_conserje
        }
        let url = environment.URL_SERVICIOS + 'EnvioNotificacionBkCitofonia'
        return this.http.post(url, data)
    }
}
