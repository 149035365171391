<div class="fondo-negro animated fadeIn" [ngClass]="_ingresarBitacoraService.oculto">
    <div class="contenedor-modal-bitacora-simple">
        <div id="modal-bitacora">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Creación de Bitácora</h4>
                        <button type="button" class="close pointer" (click)="closeModal()">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="separador">
                            <div class="titulo-modal">Tipo de Bitácora</div>
                            <select class="estilo-select-1 fondo-gris" [(ngModel)]="title">
                                <option value="0">Seleccione una opción</option>
                                <option value="Entrega">Entrega</option>
                                <option value="Ingreso">Ingreso</option>
                                <option value="Información">Información</option>
                                <option value="Paquete">Paquete</option>
                                <option value="Ronda">Ronda</option>
                                <option value="Ruidos Molestos">Ruidos Molestos</option>
                                <option value="Residente">Residente</option>
                                <option value="Recepción">Recepción</option>
                                <option value="Mudanza">Mudanza</option>
                                <option value="1">Otra</option>
                            </select>
                            <ng-container *ngIf="title == 1">
                                <div class="titulo-modal">Titulo</div>
                                <input
                                    class="select-modal-1"
                                    type="select"
                                    placeholder="ingrese titulo"
                                    [(ngModel)]="title_text"
                                />
                            </ng-container>
                            <div [hidden]="true" class="titulo-modal">Estado</div>
                            <select [hidden]="true" class="estilo-select-1 fondo-gris" [(ngModel)]="selectedValue">
                                <option value="10">Cerrada</option>
                                <!--  <option value="11">Pendiente</option> -->
                            </select>
                            <div class="titulo-modal">Torre</div>
                            <select class="estilo-select-1 fondo-gris" [(ngModel)]="selectedTorre">
                                <option value="0">Seleccione una opción</option>
                                <option value="{{ item.id_etapa }}" *ngFor="let item of torres">
                                    {{ item.nombre_etapa }}
                                </option>
                            </select>
                            <div [hidden]="true" class="titulo-modal">Módulo</div>
                            <select [hidden]="true" class="estilo-select-1 fondo-gris" [(ngModel)]="modulo">
                                <option value="0">Seleccione opción</option>
                                <option value="1">Residentes</option>
                                <option value="2">Áreas Comunes</option>
                                <option value="5">Mis visitas</option>
                                <option value="7">Gastos comunes</option>
                                <option value="10">Sugerencias</option>
                                <option value="14">Multa</option>
                                <option value="15">Otro</option>
                            </select>
                            <div [hidden]="true" class="titulo-modal"></div>
                            <div [hidden]="true" class="pretty p-icon p-round p-jelly">
                                <input type="checkbox" [(ngModel)]="fecha" />
                                <div class="state p-primary">
                                    <i class="icon mdi mdi-check"></i>
                                    <label class="subtitle-modal">Fecha y hora de término</label>
                                </div>
                            </div>
                            <ng-container *ngIf="fecha">
                                <div class="titulo-modal"></div>
                                <div class="contenedor-selector-datepicker">
                                    <span class="titulo">Fecha</span>
                                    <input
                                        [minDate]="fecha_termino"
                                        [(ngModel)]="fecha_termino"
                                        (click)="dTo.toggle()"
                                        ngbDatepicker
                                        #dTo="ngbDatepicker"
                                        autocomplete="off"
                                        placeholder="yyyy-mm-dd"
                                        name="dp"
                                        class="estilo-input-1"
                                        type="text"
                                    />
                                </div>
                                <div class="contenedor-generico">
                                    <div class="contenedor-selector-hora">
                                        <span class="titulo"></span>
                                        <select class="estilo-select-1" [(ngModel)]="selected_hour">
                                            <option value="{{ item }}" *ngFor="let item of horas">{{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="contenedor-selector-minutos">
                                        <span class="titulo">:</span>
                                        <select class="estilo-select-1" [(ngModel)]="selected_minute">
                                            <option value="{{ item }}" *ngFor="let item of minutos">{{ item }}</option>
                                        </select>
                                        <span class="ico-reloj">
                                            <img alt="" src="assets/images/reloj.png" title="" />
                                        </span>
                                    </div>
                                </div>
                                <br />
                            </ng-container>
                        </div>
                        <div class="limpiar-mobile"></div>
                        <div class="titulo-modal">Nuevo Comentario</div>
                        <textarea
                            maxlength="3000"
                            class="textarea fondo-blanco"
                            rows="5"
                            cols="10"
                            [(ngModel)]="comentary"
                        ></textarea>
                        <span style="color: red" *ngIf="comentary?.length > 0">
                            {{ comentary?.length }} / 3000 MAX
                        </span>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-modal-enviar pointer" (click)="createBinnacle()">
                            ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
