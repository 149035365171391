<div class="fondo-final" id="main-wrapper">
    <app-header></app-header>
    <!-- <app-sidebar></app-sidebar> -->
    <!-- <div class="page-wrapper">      -->
    <div class="container-fluid">
        <!-- <app-breadcrums></app-breadcrums> -->
        <router-outlet></router-outlet>
        <!-- <div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-body">
							This is some text within a card block.
						</div>
					</div>
				</div>
			</div> -->
        <!-- </div> -->
    </div>
</div>

<!--Modal Upload-->
