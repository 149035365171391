<div class="fondo-negro-2 animated fadeIn" [ngClass]="_verDetalleService.hidde">
    <div class="contenedor-modal-bitacora-detalle modal-multa">
        <div id="modal-bitacora-resumen">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Ver detalle de Multa</h4>
                        <button (click)="closeModal()" class="close pointer" type="button">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>

                    <div>
                        <div class="contenedor-modal-bitacora-simple modal-body">
                            <img
                                class="img-fluid"
                                *ngIf="url && url != 'null'"
                                [src]="url"
                                alt=""
                                style="width: 700px"
                            />
                            <p *ngIf="url == 'null'">Sin imágen disponible.</p>
                        </div>

                        <div class="modal-footer">
                            <button (click)="closeModal()" class="btn btn-modal-cancelar pointer" type="button">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
