import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class CovidService {
    constructor(public http: HttpClient) {}

    getUserCovidPaginated(page: Number) {
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/UsuariosCovid'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }
}
