import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { StreamManager } from 'openvidu-browser'
import { CitofoniaService } from 'src/app/services/citofonia/citofonia.service'

@Component({
    selector: 'ov-video',
    template: ` <style>
            .controles-video {
                position: absolute;
                bottom: 0px;
                right: 0px;
            }
        </style>
        <video
            controls
            class="test-5"
            #videoElement
            style="object-fit: cover; width: 100%!important;"
            [style.width.px]="_citofoniaService.width"
            [style.height.px]="_citofoniaService.height"
        ></video>`
})
export class OpenViduVideoComponent implements AfterViewInit {
    constructor(public _citofoniaService: CitofoniaService) {}

    @ViewChild('videoElement', { static: false }) elementRef: ElementRef

    _streamManager: StreamManager

    ngAfterViewInit() {
        this._streamManager.addVideoElement(this.elementRef.nativeElement)
    }

    @Input()
    set streamManager(streamManager: StreamManager) {
        this._streamManager = streamManager
        if (!!this.elementRef) {
            this._streamManager.addVideoElement(this.elementRef.nativeElement)
        }
    }
}
