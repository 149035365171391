import { Component, OnInit, ViewChild } from '@angular/core'
import { EntregaLlavesService } from 'src/app/services/entrega-llaves/entrega-llaves.service'
import { DataTableDirective } from 'angular-datatables'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'
import * as moment from 'moment'
import { BitacoraService } from 'src/app/services/bitacora/bitacora.service'
declare var $: any
declare var swal: any

@Component({
    selector: 'app-entrega-llaves',
    templateUrl: './entrega-llaves.component.html',
    styleUrls: ['./entrega-llaves.component.css']
})
export class EntregaLlavesComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false })
    public dtElement: DataTableDirective
    public isDtInitialized: boolean = false
    public dtTrigger: Subject<any> = new Subject<any>()
    public title = 'angulardatatables'
    public selectedTorre = 0
    public dtOptions: any = {}
    public Bienes: [] = []
    public BienesEntrega: [] = []
    public torres: Array<any> = []
    public EntregaActiva: any = ''
    public BienesEntregaFil: any
    public fechaSelected: any = {
        year: '', // parseInt(moment().format('YYYY')),
        month: '', //parseInt(moment().format('MM')),
        day: '' // parseInt(moment().format('DD'))
    }
    public filterValueBien = 0

    constructor(
        public entregaLlavesService: EntregaLlavesService,
        private spinner: NgxSpinnerService,
        private _binnacleService: BitacoraService
    ) {}

    ngOnInit() {
        this.GetTorres()
        this.ListBienes()
    }

    ListBienes() {
        this.spinner.show()
        let data = { id_proyecto: sessionStorage.getItem('IdSelectProject'), all: false }
        this.entregaLlavesService.ListBienesCheckIn(data).subscribe(
            (response) => {
                response.forEach((element) => {
                    element.fecha_checkin = moment(element.fecha_checkin).format('YYYY-MM-DD')
                })
                this.BienesEntregaFil = response
                this.BienesEntrega = response
                this.spinner.hide()
            },
            (error) => {}
        )
    }

    ModalEntrega(item) {
        
        this.EntregaActiva = item
        if (item.id_checkin != null) {
            $('#modal-entrega-llaves').modal('show')
        }
    }

    closeModal() {
        $('#modal-entrega-llaves').modal('hide')
    }

    EntregarLlaves() {
        this.NotificarLlaves(
            `Estimado usuario se ha efectuado la entrega de llaves para el (la) ${this.EntregaActiva.nombre_completo}, si realizara un check-in a un piso subterraneo, favor entrar al modulo de check-in previamente.`,
            this.EntregaActiva.id_login_usuario
        )
        let data = { id_proyecto: sessionStorage.getItem('IdSelectProject'), id_checkin: this.EntregaActiva.id_checkin }
        this.entregaLlavesService.EntregarLlaves(data).subscribe(
            (response) => {
                swal('Éxito!', 'Entrega realizada con exito')
                this.closeModal()
                this.ListBienes()
                
            },
            (error) => {}
        )
    }

    Torre(value) {
        this.selectedTorre = value
        this.filterValueBien = 0
        this.fechaSelected = ''
        this.BienesEntregaFil = this.BienesEntrega.filter((element: any) => {
            return element.id_etapa == this.selectedTorre
        })
    }

    Tipo(value) {
        this.filterValueBien = value
        this.BienesEntregaFil = this.BienesEntrega.filter((element: any) => {
            return element.nombre_tipo == this.filterValueBien
        })
        this.fechaSelected = ''
        
    }

    changeDate(date) {
        this.fechaSelected = date
        let fechaSelected = moment(
            `${this.fechaSelected.year}-${this.fechaSelected.month}-${this.fechaSelected.day}`
        ).format('YYYY-MM-DD')
        this.BienesEntregaFil = this.BienesEntrega.filter((element: any) => {
            return element.fecha_checkin == fechaSelected
        })
    }

    LimpiarFiltro() {
        this.BienesEntregaFil = this.BienesEntrega
        this.selectedTorre = 0
        this.filterValueBien = 0
        this.fechaSelected = ''
    }

    NotificarLlaves(mensaje, id_usuario) {
        let data = {
            mensaje: mensaje,
            fecha: moment().format('YYYY-MM-DD HH:mm'),
            id_usuario: id_usuario
        }

        this.entregaLlavesService.NotificarLlaves(data).subscribe(
            (response) => {},
            (error) => {}
        )
    }

    GetTorres() {
        this._binnacleService.GetTorres().subscribe((resp: any) => {
            this.torres = resp
        })
    }
}
