import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Visita } from 'src/app/models/visita.model'
import { GlobalServicesService } from '../global-services.service'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class VisitasService {
    constructor(public http: HttpClient, public _globalService: GlobalServicesService) {}

    saveVisit(visita: Visita) {
        visita.id_proyecto = sessionStorage.getItem('IdSelectProject')
        let url = environment.URL_SERVICIOS + 'saveVisitas'
        return this.http.post(url, visita).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    validarVisitaQR( data: object ) {
        const url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ValidarVisitaQR';
        return this.http.post( url, data )
        .pipe(map((resp: any) => {
          return resp;
        }))
      }

    getCommonParks(id_proyecto: string) {
        let data = {
            id_proyecto: id_proyecto
        }
        let url = environment.URL_SERVICIOS + 'getCommonParking'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    insertBinnacleNotify(data: object) {
        let url = environment.URL_SERVICIOS + 'insertBinnacleNotify'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    getBienesByUser(data: object) {
        let url = environment.URL_SERVICIOS + 'getBienesByUser'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    getVisitantes(data: object) {
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/GetVisitas'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }
}
