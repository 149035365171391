import { Component, OnInit, OnDestroy } from '@angular/core'
import { RecibirLlamadaService } from './recibir-llamada.service'
import { Subscription } from 'rxjs'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { CitofoniaService } from 'src/app/services/citofonia/citofonia.service'

@Component({
    selector: 'app-recibir-llamada',
    templateUrl: './recibir-llamada.component.html',
    styles: []
})
export class RecibirLlamadaComponent implements OnInit, OnDestroy {
    public oculto: string = 'oculto'
    public colgar_llamada_desde_movil$: Subscription

    constructor(
        public _recibirLlamadaService: RecibirLlamadaService,
        public _socketService: SocketServiceService,
        public _citofoniaService: CitofoniaService
    ) {}

    ngOnInit() {
        this.colgar_llamada_desde_movil$ = this._socketService
            .ColgarLlamadaDesdeMovil$()
            .subscribe(() => this._citofoniaService.leaveSession())
    }

    openFullscreen() {
        
        if (this._citofoniaService.modal) {
            this._citofoniaService.modal = false
            this._citofoniaService.width = this.getWidth()
            this._citofoniaService.height = this.getHeight()
        } else {
            this._citofoniaService.modal = true
            this._citofoniaService.width = 650
            this._citofoniaService.height = 400
        }
    }

    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        )
    }

    getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        )
    }

    ngOnDestroy() {
        this.colgar_llamada_desde_movil$.unsubscribe()
    }
}
