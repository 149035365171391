<div class="fondo-negro-2 animated fadeIn" [ngClass]="_entregarEncomiendaService.oculto">
    <div class="contenedor-modal-bitacora-detalle">
        <div id="modal-bitacora-resumen">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Entregar Encomienda</h4>
                        <button type="button" class="close pointer" (click)="closeModal()">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="table-responsive margen-lateraL-2">
                            <table class="table table-bordered tabla-encomienda">
                                <thead>
                                    <tr>
                                        <th scope="col">Encomienda</th>
                                        <th scope="col">Procedencia</th>
                                        <th scope="col">Observación</th>
                                        <th scope="col">Fecha Recepción</th>
                                        <th *ngIf="!_encomiendaService.encomienda.estado" scope="col">Entregado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let encomienda of _entregarEncomiendaService.encomiendasByUser">
                                        <td>{{ encomienda.nombre_encomienda }}</td>
                                        <td>{{ encomienda.procedencia }}</td>
                                        <td>
                                            <div placement="left" ngbTooltip="{{ encomienda.observacion }}">
                                                {{ encomienda.observacion }}
                                            </div>
                                        </td>
                                        <td>{{ encomienda.fecha_recepcion }}</td>
                                        <td *ngIf="!_encomiendaService.encomienda.estado">
                                            <div class="pretty p-icon p-round p-tada">
                                                <input
                                                    type="checkbox"
                                                    (click)="fillCommisssion(encomienda.id_encomienda)"
                                                />
                                                <div class="state p-success">
                                                    <i class="icon mdi mdi-check"></i>
                                                    <label class="subtitle-modal"></label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div *ngIf="!_encomiendaService.encomienda.estado" class="form-row">
                                <div class="form-group col-md-6 estilo-contenido-redondeado">
                                    <label class="label-txt margen-superior">Nombre</label>
                                    <input
                                        [(ngModel)]="nombre_receptor"
                                        autocomplete="off"
                                        class="form-control estilo-input-2 ng-pristine ng-valid ng-touched"
                                        placeholder="Nombre"
                                    />
                                </div>
                                <div class="form-group col-md-6 margen-izquierdo estilo-contenido-redondeado">
                                    <label class="label-txt margen-superior">Rut</label>
                                    <input
                                        [(ngModel)]="rut_receptor"
                                        autocomplete="off"
                                        class="form-control estilo-input-2 ng-untouched ng-pristine ng-valid"
                                        placeholder="Rut"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-modal-cancelar no-margin pointer" (click)="closeModal()">
                            Cerrar
                        </button>
                        <button
                            *ngIf="!_encomiendaService.encomienda.estado"
                            type="button"
                            class="btn btn-modal-enviar pointer"
                            (click)="giveCommission()"
                        >
                            Entregar encomiendas
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
