<div class="fondo-negro animated fadeIn" [ngClass]="_verDetalleService.oculto">
    <div class="contenedor-modal-bitacora-detalle">
        <div id="modal-bitacora-resumen">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Bitacora</h4>
                        <button type="button" class="close pointer" (click)="closeModal()">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div *ngIf="auto_manual">
                        <div *ngIf="state" class="contenedor-modal-bitacora-simple modal-body">
                            <div class="separador">
                                <div class="titulo-modal">Titulo</div>
                                <input
                                    class="select-modal-1"
                                    type="select"
                                    placeholder="Ruido Molesto"
                                    [(ngModel)]="title"
                                    disabled
                                />
                                <div class="titulo-modal">Estado</div>
                                <select class="estilo-select-1 fondo-gris" [(ngModel)]="selectedValue">
                                    <option value="10">Cerrada</option>
                                    <option value="11">Pendiente</option>
                                </select>
                                <div class="titulo-modal">Módulo</div>
                                <select class="estilo-select-1 fondo-gris" [(ngModel)]="modulo" disabled>
                                    <option selected>{{ modulo }}</option>
                                    <option [value]="id_accion"></option>
                                </select>
                            </div>
                            <div class="scroll-modal">
                                <div class="titulo-modal">Nuevo Comentario</div>
                                <textarea
                                    class="textarea fondo-blanco"
                                    rows="5"
                                    cols="10"
                                    [(ngModel)]="comentary"
                                ></textarea>
                                <div class="titulo-modal">Comentarios</div>
                                <div *ngFor="let item of observer">
                                    <div class="enmutecido-negra">{{ conserje.nombre }}</div>
                                    <div class="enmutecido">{{ item.fecha }}</div>
                                    <textarea class="textarea fondo-blanco" rows="5" cols="10" disabled>{{
                                        item.descripcion
                                    }}</textarea>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="state" class="modal-footer">
                            <button type="button" class="btn btn-modal-cancelar pointer" (click)="closeModal()">
                                Cancelar
                            </button>
                            <button type="button" class="btn btn-modal-enviar pointer" (click)="editBinnacle()">
                                Modificar
                            </button>
                        </div>
                        <div *ngIf="!state" class="contenedor-modal-bitacora-simple modal-body">
                            <div class="separador">
                                <div class="titulo-modal">Titulo</div>
                                <input
                                    class="select-modal-1"
                                    type="select"
                                    placeholder="Ruido Molesto"
                                    [(ngModel)]="title"
                                    disabled
                                />
                                <div class="titulo-modal">Estado</div>
                                <select class="estilo-select-1 fondo-gris" [(ngModel)]="selectedValue" disabled>
                                    <option value="10">Cerrada</option>
                                    <option value="11">Abierta</option>
                                </select>
                            </div>
                            <div class="titulo-modal">Comentarios</div>
                            <div *ngFor="let item of observer">
                                <div class="enmutecido-negra">{{ conserje.nombre }}</div>
                                <div class="enmutecido">{{ item.fecha }}</div>
                                <textarea class="textarea fondo-blanco" rows="5" cols="10" disabled>{{
                                    item.descripcion
                                }}</textarea>
                            </div>
                        </div>
                        <div *ngIf="!state" class="modal-footer">
                            <button type="button" class="btn btn-modal-enviar pointer" (click)="closeModal()">
                                ok
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!auto_manual">
                        <div class="modal-body">
                            <!-- <div class="titulo-modal"> {{ nombre_accion }} </div> -->
                            <div class="parrafo separacion">
                                <div>
                                    Fecha de creación: <span class="negrita color-negro"> {{ fecha }}</span>
                                </div>
                                <div>
                                    Hora <span class="negrita color-negro"> {{ hora }}</span>
                                </div>
                                <div>
                                    Depto: <span class="negrita color-negro"> {{ dpto }}</span>
                                </div>
                                <div>
                                    Residente: <span class="negrita color-negro"> {{ nombre }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-modal-enviar pointer" (click)="closeModal()">
                                ok
                            </button>
                        </div>
                    </div>
                    <!-- <div class="modal-body">
                        <div class="titulo-modal"> Reserva de Quincho </div>
                        <div class="parrafo separacion">
                            <div>Fecha de creación: <span class="negrita color-negro"> Jueves 29 de Agosto</span></div>
                            <div>Hora <span class="negrita"> 22:00</span></div>
                            <div>Fecha de creación: <span class="negrita color-negro"> Jueves 29 de Agosto</span></div>
                            <div>Depto: <span class="negrita"> 904</span></div>
                            <div>Residente: <span class="negrita color-negro"> Sebastian Osorio</span></div>
                        </div>
                        <div class="titulo-modal"> Datos de la reserva: </div>
                        <div class="parrafo">
                            <div>Fecha: <span class="negrita color-negro"> Martes 5 de Septiembre</span></div>
                            <div>Hora <span class="negrita"> 22:00</span></div>
                            <div>Lugar: <span class="negrita color-negro"> Quincho 2</span></div>
                        </div>
                    </div> -->
                    <!-- Modal footer -->
                </div>
            </div>
        </div>
    </div>
</div>
