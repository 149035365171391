import { Injectable } from '@angular/core'
import Swal from 'sweetalert2'

import { io } from 'socket.io-client';
import { Subject, Observable } from 'rxjs'
import { RecibirLlamadaService } from '../modal/recibir-llamada/recibir-llamada.service'
import { ToastrService } from 'ngx-toastr'
import { RealizarLlamadaService } from '../modal/realizar-llamada/realizar-llamada.service'
import { environment } from 'src/environments/environment'
import { Notificacion } from '../models/notificacion.model'
import { GlobalServicesService } from './global-services.service'
import * as moment from 'moment'
import { AlertService } from '@full-fledged/alerts';

@Injectable({
    providedIn: 'root'
})
export class SocketServiceService {
    public socket: any
    audio = new Audio()
    proyecto: any
    identidadUsuario: any
    //WEAS NUEVAS
    recibirLlamada$: Subject<any> = new Subject<any>()
    colgarLlamadaDesdeMovil$: Subject<any> = new Subject<any>()
    updateData$: Subject<any> = new Subject<any>()
    //WEAS VIEJAS
    llamada$: Subject<any> = new Subject<any>()
    enviarPeer$: Subject<any> = new Subject<any>()
    colgarllamada$: Subject<any> = new Subject<any>()
    selectUser$: Subject<any> = new Subject<any>()
    selectMissingUser$: Subject<any> = new Subject<any>()
    connectedUser$: Subject<any> = new Subject<any>()
    connectedUserNotification$: Subject<any> = new Subject<any>()
    llamadaExitosa$: Subject<any> = new Subject<any>()
    peerresidente$: Subject<any> = new Subject<any>()
    llamadaPerdida$: Subject<any> = new Subject<any>()
    public desconnection$: Subject<any> = new Subject<any>()
    usuario_entrante: any
    public notificacion = new Notificacion(null, 'conserje', '', '')

    constructor(
        private _recibirLlamadaService: RecibirLlamadaService,
        private toastr: ToastrService,
        public globalServicesService: GlobalServicesService,
        public _realizarLlamadaService: RealizarLlamadaService,
        private alertService: AlertService
    ) {}

    iniciarSocket(deviceInfo: Object) {
        this.identidadUsuario = JSON.parse(sessionStorage.getItem('identidad'))
        let LoginUsuario = JSON.parse(sessionStorage.getItem('LoginUsuario'))
        let _identidad = {
            id_login_usuario: this.identidadUsuario.id_login_usuario,
            id_proyecto: JSON.parse(sessionStorage.getItem('IdSelectProject')),
            id_perfil: '3', //LoginUsuario[0].id_perfil
            deviceInfo: deviceInfo
        }

        const self = this

        // Se crea conexion y se agregan los datos de identidad
        this.socket = io(environment.URL_SOCKET, { forceNew: true })

        // console.log(this.socket.clients())
        // console.log(this.socket)
        this.socket.on('connect', function () {
            self.crearIdentidad(_identidad)
            // self.socket.emit('saludo', 'hola')
        })

        this.socket.on('panico', function (data) {
            if (data.id_proyecto == sessionStorage.getItem('IdSelectProject')) {
                setTimeout(() => {
                    self.playAudio(true)
                }, 1000)
                self.alertaModal(data)
            } else {
                console.log('no')
            }
        })

        this.socket.on('bitacora-pendiente', function (data) {
            //self._globalService.bitacorasPendientes(this.token, sessionStorage.getItem('proyecto').replace(/['"]+/g, ''));
        })

        this.socket.on('alarma', function (data) {
            alert(data.msn)
        })

        this.socket.on('repuesta-panico', function (data) {
            alert(data.msn)
        })

        this.socket.on('nuevo-mensaje', function (data) {
            setTimeout(() => {
                self.playAudio(true)
            }, 1000)
            self.alertaModal(data)
            // alert(data);
        })

        this.socket.on('incremento', function (data) {
            //self._globalService.agregarCliente(moment().format('mm:ss'));
        })

        this.socket.on('citofonia', function (data) {
            self.llamada$.next(data)
        })

        //desconexión forzada
        this.socket.on('desconexion-forzada', (data: Object) => {
            self.desconnection$.next(data)
        })

        //recibir llamada
        this.socket.on('consultarpeerconserje', function (data) {
            self.enviarPeer$.next(data)
        })

        //realizar llamada
        this.socket.on('responderpeerresidente', function (data) {
            self.peerresidente$.next(data)
        })

        this.socket.on('colgarcitofoniadesdemovil', function (data) {
            self.colgarllamada$.next(data)
        })

        this.socket.on('llamadaperdida', (data: any) => {
            // console.log(data)
            // this.toastr.info('Llamada perdida de ' + data.nombre_usuario, 'Llamada perdida', {
            //   positionClass: 'toast-bottom-left',
            //   progressBar: true,
            //   closeButton: true
            // });
        })

        this.socket.on('avisodemensajellamada', (data: any) => {
            this.toastr.info('Llamada perdida de ' + data.nombre_usuario, 'Llamada perdida', {
                positionClass: 'toast-bottom-left',
                progressBar: true,
                closeButton: true
            })
            this._recibirLlamadaService.hideModal()
            self.llamadaPerdida$.next(data)
        })

        this.socket.on('UsuariosConectados', (data: any) => {
            self.connectedUser$.next(data)
        })

        this.socket.on('UsuariosConectadosNotificacion', (data: any) => {
            self.connectedUserNotification$.next(data)
        })

        this.socket.on('llamadaexitosa', (data: any) => {
            self.llamadaExitosa$.next(data)
        })
        //NUEVOCITO
        this.socket.on('RecibirLlamada', (data: any) => {
            self.recibirLlamada$.next(data)
        })

        this.socket.on('ColgarLlamadaDesdeMovil', (data: any) => {
            this.toastr.error(`${data.nombre_usuario} ha cortado la llamada!`, 'Llamada finalizada', {
                positionClass: 'toast-bottom-left',
                progressBar: true
            })
            self.colgarLlamadaDesdeMovil$.next(data)
        })
        //FIN NUEVO CITO
        this.socket.on('updatedataconserje', (data: any) => {
            if (data.id_proyecto == sessionStorage.getItem('IdSelectProject')) {
                console.log(data)
                self.updateData$.next(data)
            }
        })
    }

    playAudioCitofonia() {
        /* this.stopAudioCitofonia();
    this.audio.src = 'assets/sonidos/sonido.mp3';
    this.audio.load();
    this.audio.loop = true;
    this.audio.play(); */
    }

    stopAudioCitofonia() {
        this.audio.pause()
    }

    //NUEVO
    RecibirLlamada$(): Observable<any> {
        return this.recibirLlamada$.asObservable()
    }

    ColgarLlamadaDesdeMovil$(): Observable<any> {
        return this.colgarLlamadaDesdeMovil$.asObservable()
    }
    //CIERRE NUEVO

    getDesconnection$(): Observable<any> {
        return this.desconnection$.asObservable()
    }

    nuevaLlamada$(): Observable<any> {
        return this.llamada$.asObservable()
    }

    EnvioPeer$(): Observable<any> {
        return this.enviarPeer$.asObservable()
    }

    ColgarLlamada$(): Observable<any> {
        return this.colgarllamada$.asObservable()
    }

    UsuariosConectados$(): Observable<any> {
        return this.connectedUser$.asObservable()
    }

    UsuariosConectadosNotificacion$(): Observable<any> {
        return this.connectedUserNotification$.asObservable()
    }

    LlamadaExitosa$(): Observable<any> {
        return this.llamadaExitosa$.asObservable()
    }

    PeerResidente$(): Observable<any> {
        return this.peerresidente$.asObservable()
    }

    LlamadaPerdida$(): Observable<any> {
        return this.llamadaPerdida$.asObservable()
    }

    ActiveSelectUser(data) {
        this.selectUser$.next(data)
    }

    ActiveSelectMissingUser(data) {
        this.selectMissingUser$.next(data)
    }

    ChangeselectUser$(): Observable<any> {
        return this.selectUser$.asObservable()
    }

    ChangeselectMissingUser$(): Observable<any> {
        return this.selectMissingUser$.asObservable()
    }

    UpdateData$(): Observable<any> {
        return this.updateData$.asObservable()
    }

    //nuevo emit
    colgarLlamadaWeb(data: Object) {
        if (this.socket) {
            console.log(data, 'DATAAAASOCKET')
            this.socket.emit('ColgarCitofoniaDesdeWeb', data)
        }
    }

    realizarLlamada(data: Object) {
        if (this.socket) {
            this.socket.emit('LlamarCitofonia', data)
        }
    }

    estoyOcupado(data: Object) {
        if (this.socket) {
            this.socket.emit('EstadoCitofoniaConserje', data)
        }
    }

    usuariosConectados() {
        if (this.socket) {
            this.socket.emit('UsuariosIniciadosNotificacion')
        }
    }
    //nuevo emit end

    cerrarSocket() {
        if (this.socket) {
            this.socket.emit('id_logout', 'prueba')
        }
    }

    incremento() {
        if (this.socket) {
            this.socket.emit('incremento')
        }
    }
    test() {
        if (this.socket) {
            this.socket.emit('saludos', 'hola')
            this.socket.on('despedida', (res: any) => {
                console.log(res)
            })
            // this.socket.on('despedida', function(data){
            //   console.log(data);
            // });
        }
    }
    bitacoraPendiente(data) {
        if (this.socket) {
            this.socket.emit('bitacora-pendiente', data)
        }
    }

    crearIdentidad(_identidad) {
        this.socket.emit('id_login', _identidad)
    }

    listar() {
        this.socket.emit('listar')
    }

    panico(mensaje?) {
        console.log(mensaje)
        this.socket.emit('panico', mensaje)
    }

    playAudio(panico?) {
        if (panico) {
            this.audio.src = 'assets/sonidos/alarma_humo.mp3'
        } else {
            this.audio.src = 'assets/sonidos/SD_ALERT_28.mp3'
        }
        this.audio.load()
        this.audio.loop = true
        this.audio.play()
    }

    /*registrarBitacora(token, bitacora) {
    // TODO: registrar un nuevo plan
    const params = JSON.stringify(bitacora);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this._http.post(this.url + 'bitacora/crear', params, { headers: headers }).pipe(map(res => res.json()));
  }*/

    //panico
    alertaModal(data) {
        console.log('swal', data)
        let html: any
        if (data.msn == '') {
            html =
                '<b>Usuario: ' +
                data.usuario.nombre_usuario +
                '</b><br>' +
                '<b>Unidad: ' +
                data.usuario.numero_bien +
                '</b>'
        } else {
            html =
                '<b>Usuario: ' +
                data.usuario.nombre_usuario +
                '</b><br>' +
                '<b>Unidad: ' +
                data.usuario.numero_bien +
                '</b><br>' +
                '<b>Mensaje: ' +
                data.msn +
                '</b>'
        }
        let optionNoCovid = {
            title: 'Pánico tipo: ' + data.usuario.nombre_modulo,
            html: html,
            imageUrl: 'assets/images/' + data.usuario.icono,
            imageAlt: 'A tall image',
            width: '100%',
            animation: false,
            customClass: 'animated shake bounce',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'En camino!'
        }
        let optionCovid = {
            title: 'Pánico tipo: ' + data.usuario.nombre_modulo,
            html: html,
            imageUrl: 'assets/images/' + data.usuario.icono,
            imageAlt: 'A tall image',
            width: '100%',
            animation: false,
            customClass: 'animated shake bounce',
            allowOutsideClick: false,
            showCancelButton: false,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar'
        }
        let optionSwal
        console.log(data.usuario.icono, 'data.usuario')
        if (data.usuario.icono == 'ico-covid.png') {
            optionSwal = optionCovid
        } else {
            optionSwal = optionNoCovid
        }
        // let optionSwal = data.usuario.icono = 'ico-covid.png' ? optionCovid : optionNoCovid
        // console.log(option,"option")
        Swal.fire(optionSwal).then((result) => {
            if (result.value && data.usuario.icono != 'ico-covid.png') {
                // console.log(data)
                const self = this
                const info = {
                    destinatario: data.id_usuario.toString(),
                    remitente: JSON.parse(sessionStorage.getItem('identidad')).id_login_usuario,
                    mensaje: 'ayuda en camino'
                }
                this.notificacion = this.notificacion = new Notificacion(
                    data.id_usuario.toString(),
                    'conserje',
                    'Ayuda en camino',
                    'Botón de panico',
                    moment().format()
                )

                this.notificarResidente()
                this.socket.emit('repuesta-panico', info, function (confirmation) {
                    // console.log('confirmation--------------->', confirmation);
                })
            }
            this.audio.pause()
        })
    }

    NewClient() {
        this.socket.emit('NewClient')
    }

    notificarResidente() {
        this.globalServicesService.enviarNotificacion(this.notificacion).subscribe(
            (response) => {
                this.alertService.success('Notificación enviada.')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            },
            (error) => {
                this.alertService.danger('Ocurrio un error al enviar la notificacion')
            }
        )
    }
}
