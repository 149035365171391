import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs/internal/Subscription'
import { VerDetalleMultaService } from './ver-detalle-multa.service'

@Component({
    selector: 'app-ver-detalle-multa',
    templateUrl: './ver-detalle-multa.component.html',
    styles: []
})
export class VerDetalleMultaComponent implements OnInit, OnDestroy {
    public $detalle: Subscription
    public url: string = ''

    constructor(public _verDetalleService: VerDetalleMultaService) {}

    ngOnInit() {
        this.$detalle = this._verDetalleService.detalle.subscribe((resp: string) => {
            
            this.url = resp
        })
    }

    closeModal() {
        this._verDetalleService.hideModal()
    }

    ngOnDestroy() {
        this.$detalle.unsubscribe()
    }
}
