import { NgModule } from '@angular/core'
import { HeaderComponent } from './header/header.component'
import { NopagefoundComponent } from './nopagefound/nopagefound.component'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { PipesModule } from '../pipes/pipes.module'
import { RecibirLlamadaComponent } from '../modal/recibir-llamada/recibir-llamada.component'
import { UserVideoComponent } from '../modal/call-component/user-video.component'
import { OpenViduVideoComponent } from '../modal/call-component/ov-video.component'
import { RealizarLlamadaComponent } from '../modal/realizar-llamada/realizar-llamada.component'
import { RealizarLlamadaPerdidaComponent } from '../modal/realizar-llamada-perdida/realizar-llamada-perdida.component'

@NgModule({
    imports: [RouterModule, CommonModule, PipesModule],
    declarations: [
        HeaderComponent,
        NopagefoundComponent,
        RecibirLlamadaComponent,
        UserVideoComponent,
        OpenViduVideoComponent,
        RealizarLlamadaComponent,
        RealizarLlamadaPerdidaComponent
    ],
    exports: [HeaderComponent, NopagefoundComponent]
})
export class LayoutModule {}
