import { Component, OnInit } from '@angular/core'
import { UsuarioService } from '../../services/service.index'
import { Usuario } from 'src/app/models/usuario.model'
import { Router } from '@angular/router'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { Observable, Subscription } from 'rxjs'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { RecibirLlamadaService } from 'src/app/modal/recibir-llamada/recibir-llamada.service'
import { CitofoniaService } from 'src/app/services/citofonia/citofonia.service'
import { BitacoraService } from 'src/app/services/bitacora/bitacora.service'
import { ToastrService } from 'ngx-toastr'
import Swal from 'sweetalert2'
declare var swal: any

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styles: []
})
export class HeaderComponent implements OnInit {
    usuario: Usuario
    public binnacle_count: number
    public missing_call_count: number
    public llamada_entrante$: Observable<any>
    public llamada_perdida$: Observable<any>
    public refresh_table$: Observable<any>
    public refresh_binnacle_count$: Observable<any>
    public desconexion$: Observable<any>
    public residentes: any = []
    public nombre_proyecto: any
    public nombre_usuario: any
    public usuariosConectados$: Subscription
    public user_data: any = JSON.parse(sessionStorage.getItem('identidad'))
    public salon: any

    constructor(
        public _usuarioService: UsuarioService,
        public _router: Router,
        public _globalService: GlobalServicesService,
        public _socketService: SocketServiceService,
        public _recibirLlamadaService: RecibirLlamadaService,
        private _citofoniaService: CitofoniaService,
        private _binnacleService: BitacoraService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.nombre_usuario = JSON.parse(sessionStorage.getItem('identidad'))

        //count binnacle header start (observable)
        this.nombre_proyecto = JSON.parse(sessionStorage.getItem('LoginUsuario'))
        this.nombre_proyecto = this.nombre_proyecto.filter((name: any) => {
            return name.id_proyecto == sessionStorage.getItem('IdSelectProject')
        })[0].razon_social
        this.usuario = this._usuarioService.usuario
        this._globalService.getCountBinnacle().subscribe((resp: any) => {
            this.binnacle_count = resp.cantidad_bi
        })
        this.refresh_binnacle_count$ = this._binnacleService.RefreshBinnacleCount$()
        this.refresh_binnacle_count$.subscribe(() => {
            this._globalService.getCountBinnacle().subscribe((resp: any) => {
                this.binnacle_count = resp.cantidad_bi
            })
        })
        //count binnacle header end (observable)
        this.llamada_entrante$ = this._socketService.RecibirLlamada$()
        this.llamada_entrante$.subscribe((element: any) => {
            //aca settimeout creo
            this._socketService.playAudioCitofonia()
            this.salon = `Sala${element.id_usuario_remitente}Proyecto${sessionStorage.getItem('IdSelectProject')}`
            this._recibirLlamadaService.showModal(element)
            this._socketService.usuariosConectados()
            this.usuariosConectados$ = this._socketService.UsuariosConectadosNotificacion$().subscribe((resp: any) => {
                //Se filtra el arreglo devuelto para ver si este se encuentra conectado
                let encontrado = resp.filter((user: any) => {
                    return user.id_usuario == element.id_usuario_remitente
                })
                //De no encontrarse se le emite una notificacion de que el conserje lo esta llamando
                /* if(encontrado.length == 0) {
                    let data = {
                        "titulo": "Citofonia",
                        "mensaje": `Llamada entrante de ${this.user_data.nombre} ${this.user_data.apellido}`,
                        "modulo": "citofonia",
                        "nombre_remitente": `${this.user_data.nombre} ${this.user_data.apellido}`,
                        "id_sala": this.salon,
                        "id_usuario": element.id_usuario_remitente,
                        "id_usuario_remitente": this.user_data.id_login_usuario
                    }
                    this._globalService.envioNotificacionBkCitofonia(data).subscribe()
                } */
            })
            // let timer_into = setTimeout(() => {
            //     if(!this._citofoniaService.session) {
            //         this._recibirLlamadaService.hideModal()
            //debo guardar en llamadas perdidas, no seguro q este bien
            //aparentemente la APP debe guardar el dato de llamada perdida, esto solo es para el mensaje, ya que existe la posibilidad de que el conserje no este conectado

            //     }
            // }, 20000)
            // this._citofoniaService.timer.push(timer_into)
        })
        //session desconnection start (observable)
        this.desconexion$ = this._socketService.getDesconnection$()
        /* this.desconexion$.subscribe((data: any) => {
            let device: String
            if (data.deviceInfo.isMobile) device = 'teléfono'
            if (data.deviceInfo.isTablet) device = 'tablet'
            if (data.deviceInfo.isDesktopDevice) device = 'pc'
            Swal.fire({
                title: 'Sesión finalizada',
                text:x
                    'Se ha iniciado sesión desde un(a) ' +
                    device +
                    ', OS: ' +
                    data.deviceInfo.os_version +
                    ', navegador: ' +
                    data.deviceInfo.browser +
                    ', versión navegador: ' +
                    data.deviceInfo.browser_version,
                type: 'question',
                showCancelButton: false,
            }).then((salir: any) => {
                if (salir) {
                    this._usuarioService.logout()
                }
            })
            this.toastr.info('Su sesión finalizará en 10 segundos', 'Cerrando sesión', {
                positionClass: 'toast-bottom-left',
                progressBar: true,
                timeOut: 10000
            })
            setTimeout(() => {
                this._usuarioService.logout()
            }, 10000)
        }) */
        //session desconnection end
        //missing call table start (observable)
        this.llamada_perdida$ = this._socketService.LlamadaPerdida$()
        this.llamada_perdida$.subscribe(() => {
            this.loadCountMissingCall()
        })
        this.refresh_table$ = this._citofoniaService.TableService$()
        this.refresh_table$.subscribe(() => {
            this.loadCountMissingCall()
        })
        this.loadCountMissingCall()
        //missing call table end
    }

    loadCountMissingCall() {
        this._citofoniaService.loadMissingCallPaginated().subscribe((resp: any) => {
            /* this.missing_call_count = resp[1][0].cantidad_llamadas; */
        })
    }

    closeSession() {
        Swal.fire({
            title: 'Cerrar sesión',
            type: 'question',
            text: '¿Esta seguro de que desea cerrar la sesión?',
            confirmButtonText: 'Salir',
            cancelButtonText: 'Cancelar',
        }).then((result: any) => {
            if (result.value) {
                // this._usuarioService.closeToken()
                // .subscribe();
                this._usuarioService.logout()
            }
        })
    }
}
