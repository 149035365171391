<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla">
            <div class="centered-title">
                <b>Ingresa el nombre del evento</b><br />
                <div class="subtitle">Ingresa los datos para ver resultados de búsqueda</div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-striped" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="ico-estado-2">Evento</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-3">Nombre residente</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Fecha inicio</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Hora inicio</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Gestionar invitados</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of eventos">
                            <td scope="row">{{ item.nombre_evento }}</td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.fecha_inicio }}</td>
                            <td>{{ item.hora_inicio }}</td>
                            <td width="10%">
                                <div class="contenedor-acciones">
                                    <a (click)="display_event(item)">
                                        <input type="button" class="event_btn" value="Gestionar invitados" />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
<app-gestionar-invitados></app-gestionar-invitados>
