<div class="margen-lateral">
    <div class="bloque-reserva-botonera">
        <div class="bloque-titulo citofonia-margen">
            <h1 class="h1-icono">Llama a un usuario:</h1>
        </div>
        <div class="contenedor-selectores-bloque mobile-citofonia">
            <div class="contenedor-selector-area contenedor-boton-select-complicado">
                <span class="titulo alineacion momentaneo">Residente:</span>
                <ngx-select-dropdown
                    class="min-max-233"
                    tabindex="0"
                    [multiple]="false"
                    [(ngModel)]="selected_resident"
                    [config]="config"
                    [options]="residentes"
                >
                </ngx-select-dropdown>
                <span class="contenedor-btn-mobile flotante-boton">
                    <input class="btn-ico pointer" value="Llamar" type="button" (click)="makeCall()" />
                </span>
            </div>
            <div class="borrado"></div>
        </div>
        <div class="borrado"></div>
    </div>
</div>

<div class="margen-lateral">
    <div class="bloque-redondeado citofonia">
        <div class="bloque-tabla">
            <div class="msj-2">
                <h2 class="titulo-2">Llamadas</h2>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped"
                    [dtTrigger]="dtTrigger"
                    datatable
                    [dtOptions]="dtOptions"
                >
                    <thead>
                        <tr>
                            <th scope="col"><div class="ico-estado-2">Fecha</div></th>
                            <th scope="col"><div class="ico-estado-3">Departamento</div></th>
                            <th scope="col"><div class="ico-estado-1">Usuario</div></th>
                            <!-- <th scope="col" ><div class="ico-estado-1">Estado</div></th> -->
                            <th scope="col"><div class="ico-estado-1">Tipo</div></th>
                            <th scope="col"><div class="ico-estado-1">Mensaje</div></th>
                            <th scope="col"><div class="ico-estado-1">Detalle</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let llamada of llamadas">
                            <td>{{ llamada.fecha }}</td>
                            <td>{{ llamada.departamento }}</td>
                            <td>{{ llamada.nombre }}</td>
                            <!-- <td *ngIf="llamada.activo"><div class="txt-float txt-negrita">Online</div><span class="aviso-verde"></span></td>
							<td *ngIf="!llamada.activo"><div class="txt-float txt-negrita">Offline</div><span class="aviso-rojo"></span></td>
							 -->
                            <td>{{ llamada.sistema }}</td>
                            <td>{{ llamada.mensaje }}</td>
                            <td>
                                <input
                                    (click)="makeCallMiss(llamada)"
                                    class="btn-verde-llamar pointer"
                                    type="button"
                                    value="Llamar"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
<!-- <app-realizar-llamada [selected_resident]="selected_resident"></app-realizar-llamada> -->
<!-- <app-realizar-llamada-perdida [selected_resident_missing]="selected_resident_missing"></app-realizar-llamada-perdida> -->
