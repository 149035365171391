import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class EntregaLlavesService {
    constructor(private http: HttpClient) {}

    public ListBienesCheckIn(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ListBienesCheckIn', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public EntregarLlaves(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/EntregarLlaves', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public NotificarLlaves(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'Residentes/NotificarLlaves', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    errorHandler(error: any): any {
        throw new Error('Method not implemented.')
    }
}
