import { Injectable } from '@angular/core'
import { EncomiendaService } from 'src/app/services/encomienda/encomienda.service'
import { Encomienda } from 'src/app/models/encomienda.model'
import * as moment from 'moment'

@Injectable({
    providedIn: 'root'
})
export class EntregarEncomiendaService {
    public oculto: String = 'oculto'
    public encomiendasByUser: Array<Encomienda>

    constructor(public _encomiendaService: EncomiendaService) {}

    hideModal() {
        this.oculto = 'oculto'
    }

    showModal(id: any) {
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_usuario: id
        }
        this._encomiendaService.getAllRegister(data).subscribe((resp: any) => {
            this.encomiendasByUser = resp.map((element: any) => {
                return {
                    correo: element.correo,
                    estado: element.estado,
                    fecha_entrega: element.fecha_entrega,
                    fecha_recepcion: moment(element.fecha_recepcion).format('YYYY-MM-DD HH:mm'),
                    id_bien: element.id_bien,
                    id_conserje: element.id_conserje,
                    id_encomienda: element.id_encomienda,
                    id_tipo_encomienda: element.id_tipo_encomienda,
                    id_usuario: element.id_usuario,
                    nombre_encomienda: element.nombre_encomienda,
                    nombre_residente: element.nombre_residente,
                    numero_bien: element.numero_bien,
                    observacion: element.observacion,
                    procedencia: element.procedencia
                }
            })
            this.oculto = ''
        })
    }
}
