import { Injectable, EventEmitter } from '@angular/core'
import { Multa } from 'src/app/interfaces/interface'

@Injectable({
    providedIn: 'root'
})
export class EditarMultaService {
    public $dataMulta: EventEmitter<any> = new EventEmitter<any>()
    public oculto: string = 'oculto'

    constructor() {}

    hideModal() {
        this.oculto = 'oculto'
    }

    showModal(multa: Multa) {
        this.$dataMulta.emit(multa)
        this.oculto = ''
    }
}
