export class Usuario {
    constructor(
        public user_name: string,
        public user_email: string,
        public password: string,
        public dispositivo?: number,
        public user_image?: string,
        public token?: string,
        public user_role?: string,
        public estado?: boolean,
        public user_id?: string
    ) {}
}
