<style>
    .color-borde {
        border: 1px solid #ababab;
        background-color: #dfdfdf;
    }
</style>
<div class="fondo-4">
    <div class="barra-superior-principal">
        <div class="alineacion-derecha-barra-sup">
            <!-- <div class="bloque-1-barra">
            Visitar <a href="#">BuildingClerk</a>
        </div>
        <div class="bloque-2-barra">
            Visitar <a href="#">Administrar</a>
        </div> -->
        </div>
        <div class="borrado"></div>
    </div>

    <section id="wrapper" class="login-register login-sidebar">
        <div class="container">
            <div class="row margen-superior-login">
                <div class="col-sm-12 my-auto">
                    <div class="login-box romo card">
                        <div class="card-body fondo-login">
                            <form
                                ngNativeValidate
                                #f="ngForm"
                                class="form-horizontal form-material"
                                (ngSubmit)="ingresar(f)"
                            >
                                <a href="javascript:void(0)" class="text-center db center-logo">
                                    <img class="logo-login" src="assets/images/logo-conserje-grande.png" alt="Home" />
                                </a>
                                <div class="form-group m-t-40 margen-superior-pantalla">
                                    <div class="col-xs-12 centrado-login">
                                        <input
                                            autocomplete="username"
                                            [ngModel]="email"
                                            name="email"
                                            class="romo form-control fondo-campo color-borde"
                                            type="email"
                                            required
                                            placeholder="usuario"
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-12 centrado-login">
                                        <input
                                            autocomplete="current-password"
                                            ngModel
                                            name="password"
                                            class="romo form-control fondo-campo color-borde"
                                            type="password"
                                            required
                                            placeholder="clave"
                                        />
                                    </div>
                                </div>

                                <div class="form-group text-center m-t-20 margen-inferior">
                                    <div class="col-xs-12">
                                        <div>
                                            <button class="estilo-btn-ingresar" type="submit">Ingresar</button>
                                        </div>
                                        <!-- <div>
                                    <button class="estilo-btn-descubrir"
                                    type="submit">Descubrir</button>
                                </div> -->
                                    </div>
                                </div>
                                <div class="estilo-msj">
                                    ¿Olvidaste tu contraseña?,
                                    <a class="pointer" (click)="recuperarClave()">haz click aquí­</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="logo-inferior-login">
                        <img src="assets/images/logo-edificio.png" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="fondo-negro animated fadeIn" [hidden]="!StatusModal">
        <div class="modal" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Seleccione Proyecto</h5>
                        <button (click)="cerrarModal()" type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="message-text" class="control-label">Proyecto:</label>
                            <select
                                class="form-control"
                                [(ngModel)]="IdSelectProject"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <option selected="" value="0">Ninguno</option>
                                <option *ngFor="let item of DatosUsuarios.proyectos" value="{{ item.id_proyecto }}">
                                    {{ item.razon_social }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn-ingresar" (click)="ProjectDefault()">Aceptar</button>
                        <button type="button" class="btn-cerrar" (click)="cerrarModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="fondo-negro animated fadeIn" [hidden]="!recuperaModal">
        <div class="modal" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">¿Olvidaste tu contraseña?</h5>
                        <button (click)="cerrarModalRecuperar()" type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="message-text" class="control-label"
                                >Ingresa tu usuario para realizar la recuperación de tu contraseña</label
                            >
                            <input
                                [(ngModel)]="recupera"
                                name="recupera"
                                class="form-control"
                                type="text"
                                required
                                placeholder="Ingresa tu usuario"
                            />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn-ingresar" (click)="RecuperarContrasena()">Aceptar</button>
                        <button type="button" class="btn-cerrar" (click)="cerrarModalRecuperar()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="clear: both"></div>
</div>
