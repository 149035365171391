import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
    providedIn: 'root'
})
export class FileServiceService {
    constructor(private toastr: ToastrService) {}

    base64Encoder(evt: any): Promise<any> {
        return new Promise((res, rej) => {
            let file = evt.target.files[0]
            const formats = ['jpg', 'png', 'gif', 'tiff', 'jfif', 'jpeg']
            if (!file.type || !formats.includes(file.type.split('/')[1].toLowerCase())) {
                this.toastr.error('Archivo con formato inválido.', 'Error', {
                    timeOut: 3000,
                    closeButton: true,
                    progressBar: true,
                    positionClass: 'toast-bottom-left'
                })
                rej(true)
                return
            }
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                res(reader.result/* this.compressImage(reader.result, 100, 100) */)
            }
            reader.onerror = (error) => {
                rej(error)
            }
        })
    }

    private compressImage(src: any, newX: any, newY) {
        return new Promise((res, rej) => {
            const img = new Image()
            img.src = src
            img.onload = () => {
                const elem = document.createElement('canvas')
                elem.width = newX
                elem.height = newY
                const ctx = elem.getContext('2d')
                ctx.drawImage(img, 0, 0, newX, newY)
                const data = ctx.canvas.toDataURL()
                res(data)
            }
            img.onerror = (error) => rej(error)
        })
    }
}
