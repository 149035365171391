import { Component, OnInit, Input } from '@angular/core'
import { EntregarEncomiendaService } from './entregar-encomienda.service'
import { EncomiendaService } from 'src/app/services/encomienda/encomienda.service'
import { Encomienda } from 'src/app/models/encomienda.model'
import { ToastrService } from 'ngx-toastr'
import swal from 'sweetalert2'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { Notificacion } from 'src/app/models/notificacion.model'
import { EncomiendaComponent } from 'src/app/pages/encomienda/encomienda.component'
import { AlertService } from '@full-fledged/alerts'

@Component({
    selector: 'app-entregar-encomienda',
    templateUrl: './entregar-encomienda.component.html',
    styles: []
})
export class EntregarEncomiendaComponent implements OnInit {
    @Input('encomienda') encomienda: Encomienda
    public notificacion = new Notificacion(null, 'conserje', '', '')
    public commissions_id: Array<number> = []
    nombre_receptor: any = ''
    rut_receptor: any

    constructor(
        public _entregarEncomiendaService: EntregarEncomiendaService,
        public _encomiendaService: EncomiendaService,
        private toastr: ToastrService,
        public globalServicesService: GlobalServicesService,
        public encomiendaComponent: EncomiendaComponent,
        public alertServices: AlertService
    ) { }

    ngOnInit() {
        /*         this.encomienda.nombre_receptor = ""
                this.encomienda.rut_receptor = "" */
    }

    fillCommisssion(id: number) {
        let founded_id = this.commissions_id.find((element: number) => {
            return element == id
        })
        if (!founded_id) {
            this.commissions_id.push(id)
        } else {
            let removed_id: any = this.commissions_id.filter((element: any) => {
                return element != id
            })
            this.commissions_id = []
            this.commissions_id = removed_id
        }
    }

    async giveCommission() {
        let rut = await this.globalServicesService.validateRut(this.rut_receptor)

        if (this.nombre_receptor == '') {
            this.alertServices.danger('Debe ingresar nombre')
            return
        }

        if (!rut) {
            this.alertServices.danger('Debe ingresar rut valido')
            return
        } else {
            this.rut_receptor = rut
        }

        this.encomienda.nombre_receptor = this.nombre_receptor
        this.encomienda.rut_receptor = this.rut_receptor

        swal.fire({
            title: 'Esta seguro de entregar esta encomienda?',
            text: 'Verifique que sea el residente correcto',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#a0c62a',
            cancelButtonColor: '##d32a2a',
            confirmButtonText: 'Entregar encomienda'
        }).then((result) => {
            if (result.value) {
                this.encomienda.ids = this.commissions_id
                this._encomiendaService.changeState(this.encomienda).subscribe((resp: any) => {
                    this.toastr.success(resp.res, 'Entregado!', {
                        positionClass: 'toast-bottom-left',
                        progressBar: true
                    })
                    let commisions: Array<any> = []
                    this.commissions_id.forEach((id: any) => {
                        this._entregarEncomiendaService.encomiendasByUser.forEach((commission: any) => {
                            if (id == commission.id_encomienda) {
                                commisions.push(commission)
                            }
                        })
                    })
                    let data = {
                        correo: this.encomienda.correo,
                        nombre: commisions[0].nombre_residente,
                        data: commisions,
                        receptor: `${this.nombre_receptor} - ${this.rut_receptor}`
                    }
                    this.closeModal()
                    this.notificarEncomienda()
                    this.encomiendaComponent.GetEncomiendas()
                    this._encomiendaService.sendEmailReceived(data).subscribe()
                })
            }
        })
    }

    notificarEncomienda() {
        this.notificacion = new Notificacion(
            this.encomienda.id_usuario.toString(),
            'conserje',
            'Se ha entregado ' +
            this.encomienda.ids.length +
            ' encomienda(s) dirigida(s) a usted. ---> Observaciones: ' +
            this.encomienda.observacion,
            'Encomienda ' + '(' + this.encomienda.nombre_encomienda + ')'
        )
        this.globalServicesService.enviarNotificacion(this.notificacion).subscribe(
            (response) => {
                this.alertServices.success('Notificación enviada.')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            },
            (error) => {
                this.alertServices.danger('Problemas al enviar notificación')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            }
        )

        this.commissions_id = []
    }

    closeModal() {
        this._entregarEncomiendaService.hideModal()
    }
}
