import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, tap } from 'rxjs/operators'
import { Encomienda } from 'src/app/models/encomienda.model'
import { Subject } from 'rxjs'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class EncomiendaService {
    public _refresh = new Subject<void>()
    public encomiendas: Array<Encomienda>
    public encomiendasFiltered: Array<Encomienda> = []
    public encomienda: any = ''

    constructor(public http: HttpClient) {}

    get refresh() {
        return this._refresh
    }

    getTypes() {
        let url = environment.URL_SERVICIOS + 'GetTypeCommission'
        return this.http.get(url).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    sendCommission(encomienda: Encomienda) {
        encomienda.id_proyecto = sessionStorage.getItem('IdSelectProject')
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/CrearEncomienda'
        return this.http.post(url, encomienda).pipe(
            map((resp: any) => {
                return resp
            }),
            tap(() => {
                return this._refresh.next()
            })
        )
    }

    getCommission(data) {
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ListEncomienda'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                this.encomiendas = resp
                this.encomiendasFiltered = resp
                return resp
            })
        )
    }

    changeState(encomienda: Encomienda) {
        encomienda.id_proyecto = sessionStorage.getItem('IdSelectProject')
        encomienda.fecha_entrega = moment().format('YYYY-MM-DD HH:mm')
        let url = environment.URL_SERVICIOS + 'changeState'
        this.encomiendas.forEach((commission: Encomienda) => {
            for (let i = 0; i < encomienda.ids.length; i++) {
                if (commission.id_encomienda == encomienda.ids[i].toString()) {
                    commission.estado = 1
                    commission.fecha_entrega = moment().format('YYYY-MM-DD HH:mm')
                }
            }
        })
        // return
        return this.http.post(url, encomienda).map((resp: any) => {
            this._refresh.next()
            return resp
        })
    }

    searchTerm(param: String) {
        let data: Object = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            term: param
        }
        let url = environment.URL_SERVICIOS + 'SearchRegister'
        return this.http.post(url, data).map((resp: any) => {
            this.encomiendasFiltered = resp
        })
    }

    sendEmail(correo: Object) {
        let data: Object = {
            email: correo
        }
        let url = environment.URL_SERVICIOS + 'SendEmail'
        return this.http.post(url, data).map((resp: any) => {
            console.log(resp)
        })
    }

    sendEmailReceived(data: any) {
        let url = environment.URL_SERVICIOS + 'SendEmailReceived'
        return this.http.post(url, data).map((resp: any) => {
            console.log(resp)
        })
    }

    sendEmailToClient(data: any) {
        let url = environment.URL_SERVICIOS + 'ConserjeRecibeEncomiendaEmail'
        return this.http.post(url, data).map((resp: any) => {
            console.log(resp)
        })
    }

    getAllRegister(data: Object) {
        let url = environment.URL_SERVICIOS + 'GetAllCommission'
        return this.http.post(url, data).map((resp: any) => {
            return resp
        })
    }
}
