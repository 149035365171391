<div class="contenedor-modal-bitacora-simple modal-body">
    <div *ngIf="respAux != ''" class="titulo-agenda">
        Reserva <span class="naranjo">{{tipo_bien}}</span> {{ viewDate | date:'yyyy-MM-dd' }}
    </div>
    <div class="table-responsive">
        <div class="bloque-areas">
            <div class="listado">
                <ul>
                    <li><span class="amarillo"></span>En uso</li>
                    <li><span class="celeste"></span>Reservado</li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="btn-group">
                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="'day'" [(viewDate)]="viewDate">
                        Anterior
                    </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">Hoy</div>
                    <div class="btn btn-primary" mwlCalendarNextView [view]="'day'" [(viewDate)]="viewDate">
                        Siguiente
                    </div>
                </div>
            </div>
        </div>
        <br />

        <mwl-day-view-scheduler
            (eventClicked)="eventClicked($event)"
            [refresh]="refresh"
            [viewDate]="viewDate"
            [events]="events"
            [users]="users"
            (eventTimesChanged)=" ($event)"
            (userChanged)="userChanged($event)"
        >
        </mwl-day-view-scheduler>
    </div>
</div>
