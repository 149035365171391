// export const Horas = [
//     {
//         hora: '01:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '02:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '03:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '04:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '05:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '06:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '07:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '08:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '09:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '10:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '11:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '12:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '13:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '14:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '15:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '16:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '17:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '18:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '19:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '20:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '21:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '22:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '23:00',
//         ocupado: false,
//         minutos: 0
//     },
//     {
//         hora: '00:00',
//         ocupado: false,
//         minutos: 0
//     }
// ]

const buildHoras = () => {
    let hours = []
    let minutos = []
    for (let j = 0; j <= 60; j++) {
        minutos.push({ min: formatNumber(j), ocupado_min: false })
    }
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            hours.push({
                hora: `0${i}:00`,
                bienes: []
            })
        } else {
            hours.push({
                hora: `${i}:00`,
                bienes: []
            })
        }
    }
    return hours
}

const formatNumber = (number: number): string => {
    let formattedNumber = number.toString()
    return formattedNumber.length === 1 ? `0${formattedNumber}` : formattedNumber
}

export const Horas = buildHoras()
