import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UsuarioService, LoginGuardGuard } from './service.index'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule],
    providers: [UsuarioService, LoginGuardGuard]
})
export class ServiceModule {}
