import { Component, OnInit, ViewChild } from '@angular/core'
import { CovidService } from 'src/app/services/covid/covid.service'
import { Contagiado } from 'src/app/models/contagiado.model'
import { Subject } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'

@Component({
    selector: 'app-covid',
    templateUrl: './covid.component.html',
    styles: []
})
export class CovidComponent implements OnInit {
    public contagiados: Array<Contagiado> = []
    public total: Array<Number> = []
    public totalPages: Number
    public thisPage: Number = 1
    title = 'angulardatatables'
    dtOptions: any = {}
    dtTrigger: Subject<any> = new Subject<any>()
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false
    constructor(public _covidService: CovidService) {}

    ngOnInit() {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
            order: [[0, 'desc']],
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel'
                } /* ,
				{
					extend: 'csvHtml5',
					text: '<i class="fas fa-file-csv"></i>',
					titleAttr: 'CSV'
				} */
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }
        this.getAllUser()
    }

    getAllUser(page: Number = 1) {
        this._covidService.getUserCovidPaginated(page).subscribe((resp: any) => {
            this.contagiados = resp
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
        })
    }

    changePage(page: Number) {
        this.getAllUser(page)
    }
}
