<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>

<div class="margen-lateral">
    <div class="bloque-reserva-botonera">
        <div class="contenedor-botones-opciones-2">
            <input
                (click)="insertBinnacle()"
                class="estilo-boton-bitacora pointer"
                type="button"
                value="INGRESAR BITACORA"
            />
        </div>
        <div class="borrado"></div>
    </div>
</div>

<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla">
            <div class="contenedor-selectores">
                <span class="bloque-1-area">
                    <div class="contenedor-selector-area">
                        <span class="titulo">Fecha Inicio</span>
                        <input
                            (click)="d.toggle()"
                            autocomplete="off"
                            placeholder="yyyy-mm-dd"
                            name="dp"
                            [(ngModel)]="fechaSelectedInicio"
                            [ngModelOptions]="{ standalone: true }"
                            ngbDatepicker
                            #d="ngbDatepicker"
                            class="estilo-input-1"
                            type="text"
                        />
                    </div>
                    <div class="contenedor-selector-datepicker">
                        <span class="titulo">Fecha Fin</span>
                        <input
                            (click)="e.toggle()"
                            autocomplete="off"
                            placeholder="yyyy-mm-dd"
                            name="dp"
                            [(ngModel)]="fechaSelectedFin"
                            [ngModelOptions]="{ standalone: true }"
                            ngbDatepicker
                            #e="ngbDatepicker"
                            class="estilo-input-1"
                            type="text"
                        />
                    </div>
                </span>

                <span class="bloque-area-2">
                    <div (click)="onDateSelected()" class="contenedor-btn-disponibilidad margin-btn">
                        <input class="estilo-boton" type="button" value="Buscar" />
                    </div>
                </span>
            </div>
            <div class="centered-title">
                <b>Ingresa el nombre, apellido o número de departamento</b><br />
                <div class="subtitle">Ingresa los datos para ver resultados de búsqueda</div>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped"
                    [dtTrigger]="dtTrigger"
                    datatable
                    [dtOptions]="dtOptions"
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="ico-estado-2">TORRE</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-2">Fecha de ingreso</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-3">Referencia</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-3">Tipo de usuario</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Nombre</div>
                            </th>
                            <!--  <th scope="col">
                                <div class="ico-estado-1">Módulo</div>
                            </th> -->
                            <th scope="col">
                                <div class="ico-estado-1">Estado</div>
                            </th>
                            <!-- <th scope="col">
                                <div class="ico-estado-1">Sistema</div>
                            </th> -->
                            <th scope="col">
                                <div class="ico-estado-1">Detalle</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let binnacle of binnacles">
                            <td *ngIf="binnacle.id_etapa != 0">{{ binnacle.nombre_torre }}</td>
                            <td *ngIf="binnacle.id_etapa == 0">Comunidad</td>
                            <td>{{ binnacle.fecha }}</td>
                            <td>
                                {{ binnacle.texto }}
                                <span *ngIf="binnacle.modulo == 'Mis visitas'"> (Visita Autorizada) </span>
                            </td>
                            <td>{{ binnacle.tipo_usuario }}</td>
                            <td>{{ binnacle.usuario }}</td>
                            <!-- <td>{{ binnacle.modulo }}</td> -->
                            <td>{{ binnacle.estado }}</td>
                            <!-- <td>{{ binnacle.sistema }}</td> -->
                            <td>
                                <input
                                    class="btn-verde-detalle pointer"
                                    type="button"
                                    value="VER DETALLE"
                                    (click)="showDetail(binnacle.id_bitacora)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
<app-ingresar-bitacora></app-ingresar-bitacora>
<app-ver-detalle [binnacle]="binnacle"></app-ver-detalle>
