<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>
<div class="margen-lateral">
    <div class="bloque-reserva-botonera text-center">
        <div class="contenedor-botones-opciones d-none d-sm-none d-md-block">
            <input data-toggle="modal" data-target="#modal-notificacion" class="estilo-boton-naranjo pointer"
                type="button" value="Notificación" />
            <input class="estilo-boton-verde-2 pointer" data-toggle="modal" data-target="#modal-correspondencia"
                type="button" value="Correspondencia" />
            <input class="estilo-boton-rojo pointer" data-toggle="modal" data-target="#modal-emergencia" type="button"
                value="Emergencia" />
        </div>

        <div class="contenedor-botones-opciones d-sm-block d-md-none d-lg-none .d-xl-none">
            <input data-toggle="modal" data-target="#modal-notificacion" class="estilo-boton-naranjo pointer"
                type="button" value="" />
            <input class="estilo-boton-verde pointer" data-toggle="modal" data-target="#modal-correspondencia"
                type="button" value="" />
            <input class="estilo-boton-rojo pointer" data-toggle="modal" data-target="#modal-emergencia" type="button"
                value="" />
        </div>



        <div class="contenedor-selectores-opciones col-12 mt-3">
            <div class="contenedor-selector-area text-center col-12">
                <span class="titulo">Ver Visitas Autorizadas:</span>
                <select [(ngModel)]="DataVisita" class="estilo-select-1 ancho">
                    <option value="0">Seleccione Visita</option>
                    <option value="{{ item.id_visita }}" *ngFor="let item of VisitasArray">
                        {{ item.nombre }} {{ item.apellido }}
                    </option>
                </select>
                <!-- <span class="titulo">Validar código:</span>
                <input id="visitaCode" [(ngModel)]="DataVisitaCode" class="estilo-input-hora ancho" placeholder="Ingrese codigo de visita" type="text">
                 -->
                <span (click)="ValidarVisita()" class="ico-ok ml-2"><a href="#"><img
                            src="assets/images/ico-ticket-v2.png" /></a></span>
            </div>
        </div>
        <div class="borrado"></div>
    </div>
</div>

<!--tabla-->
<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla">
            <div class="centered-title">
                <b>Ingresa el nombre, apellido o número de departamento</b><br />
                <div class="subtitle">Ingresa los datos para ver resultados de búsqueda</div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-striped" [dtTrigger]="dtTrigger" datatable
                    [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="ico-estado-2">Torre</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-2">Descripción Bien</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-2">Número del bien</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Residente</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Tipo Residente</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Acciones por vivienda</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ResidentesArray">
                            <td scope="row">{{ item.nombre_etapa }}</td>
                            <td scope="row">{{ item.nombre_tipo_bien }}</td>
                            <td scope="row">{{ item.numero_bien }}</td>
                            <td>{{ item.nombre }} {{ item.apellido }}</td>
                            <td>{{item.nombre_perfil}}</td>
                            <td>
                                <div class="contenedor-acciones">
                                    <a href="#" (click)="GetVisitID(item)">
                                        <img data-toggle="modal" data-target="#modal-ingreso-visita"
                                            src="assets/images/ico-accion-1.png" alt="" title="Administrar visita"
                                            data-backdrop="static" data-keyboard="false" />
                                    </a>
                                    <a href="#">
                                        <img data-toggle="modal" (click)="GetUserID(item)"
                                            data-target="#modal-notificacion-accion"
                                            src="assets/images/ico-accion-2.png" alt="" title="Enviar mensaje" />
                                    </a>
                                    <a href="#">
                                        <img data-toggle="modal" (click)="GetUserID(item)" data-target="#modal-email"
                                            src="assets/images/ico-accion-3.png" alt="" title="Enviar correo" />
                                    </a>
                                    <a href="#">
                                        <img data-toggle="modal" (click)="GetUserID(item)"
                                            data-target="#modal-tipo-de-correspondencia"
                                            src="assets/images/ico-accion-4.png" alt=""
                                            title="Notificar correspondencia" />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!--fin tabla-->

<!-- Modal Notificaciones -->

<div class="contenedor-modal-notificacion">
    <div class="modal fade" id="modal-notificacion">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Notificación a la comunidad</h4>
                    <button type="button" class="close pointer" (click)="close()" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="titulo-modal">Titulo del mensaje</div>
                    <input [(ngModel)]="difusion.titulo" class="select-modal-1" type="select"
                        placeholder="Informativo" />
                    <div class="titulo-modal">Mensaje</div>
                    <textarea [(ngModel)]="difusion.mensaje" class="textarea" rows="10" cols="10"
                        placeholder="Ingrese información..."></textarea>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar pointer" (click)="clearModalNotification()"
                        data-dismiss="modal">
                        Cancelar
                    </button>
                    <button (click)="enviarDifusion()" type="button" class="btn btn-modal-enviar pointer"
                        data-dismiss="modal">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Correspondencia -->

<div class="contenedor-modal-correspondencia">
    <div class="modal fade" id="modal-correspondencia">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Correspondencia</h4>
                    <button type="button" class="close pointer" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" (click)="clearModalNotification()" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="titulo-modal">Titulo del mensaje</div>
                    <div class="capsula-check">
                        <div class="contenedor-eleccion">
                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="luz" />
                                <span class="tit-serv">Luz</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="agua" />
                                <span class="tit-serv">Agua</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="gas" />
                                <span class="tit-serv">Gas</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="Otra" />
                                <span class="tit-serv">Otra</span>
                            </div>
                        </div>
                    </div>
                    <div class="titulo-modal-2">Observaciones:</div>
                    <textarea class="textarea" rows="10" cols="10" [(ngModel)]="encomienda.observaciones"></textarea>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar pointer" data-dismiss="modal"
                        (click)="clearModalNotification()">
                        Cancelar
                    </button>
                    <button (click)="notificarEncomiendaMasiva()" type="button" class="btn btn-modal-enviar pointer"
                        data-dismiss="modal">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Emergencia -->

<div class="contenedor-modal-emergencia">
    <div class="modal fade" id="modal-emergencia">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Emergencia</h4>
                    <button type="button" class="close pointer" data-dismiss="modal" (click)="closeEmergency()">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="titulo-modal">Seleccione tipo de emergencia:</div>
                    <div class="capsula-check">
                        <div class="contenedor-eleccion">
                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="difusion.titulo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="incendio" (click)="handlingFields(0)" />
                                <span>Incendio</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="difusion.titulo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="Corte de agua"
                                    (click)="handlingFields(1)" />
                                <span>Corte de agua</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="difusion.titulo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="Corte de gas"
                                    (click)="handlingFields(2)" />
                                <span>Corte de gas</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="difusion.titulo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="Corte de luz"
                                    (click)="handlingFields(3)" />
                                <span>Corte de luz</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="difusion.titulo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="otro" (click)="handlingFields(4)" />
                                <span>Otra</span>
                            </div>
                        </div>
                    </div>
                    <div class="capsula-otras-opciones">
                        <div *ngIf="emergency_title" class="contenedor-ingreso-titulo">
                            <input [(ngModel)]="difusion.titulo" placeholder="Ingrese un titulo" class="input-buscador"
                                type="text" />
                        </div>
                        <div *ngIf="emergency_date">
                            <div class="centrado-flex">
                                <div class="contenedor-selector-datepicker-emergencia">
                                    <input (click)="d.toggle()" placeholder="yyyy-mm-dd" name="dp"
                                        [(ngModel)]="fechaSelected" [ngModelOptions]="{ standalone: true }"
                                        ngbDatepicker #d="ngbDatepicker" class="estilo-input-1 form-control" type="text"
                                        placeholder="Fecha Término" />
                                </div>
                                <div class="contenedor-selector-hora">
                                    <input class="estilo-input-1" type="text" placeholder="Hora Término"
                                        [(ngModel)]="hora" />
                                </div>
                            </div>
                            <div class="contenedor-eleccion individual">
                                <div class="margen">
                                    <input class="check-op" type="checkbox" />
                                    <span>No lo sé</span>
                                </div>
                            </div>
                        </div>
                        <div class="borrado"></div>
                    </div>
                    <div class="titulo-modal-2">Observaciones:</div>
                    <textarea [(ngModel)]="difusion.mensaje" class="textarea" rows="10" cols="10"></textarea>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar pointer" data-dismiss="modal"
                        (click)="closeEmergency()">
                        Cancelar
                    </button>
                    <button (click)="enviarAlerta()" type="button" class="btn btn-modal-enviar pointer"
                        data-dismiss="modal">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal ingreso de visita -->
<div class="contenedor-modal-ingreso-visita">
    <div class="modal fade" id="modal-ingreso-visita">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Ingreso de Visitas</h4>
                    <button type="button" class="close pointer" (click)="closeVisitModal()" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="form-row">
                        <div *ngIf="!general_select" class="form-group col">
                            <label class="label-txt">Visitas ya autorizadas</label>
                            <select [(ngModel)]="selectedValue" class="estilo-select-1"
                                (change)="fillFields($event.target.value)">
                                <option value="0">Seleccionar Visita</option>
                                <option *ngFor="let item of selectVisita" value="{{ item | json }}">
                                    {{ item.nombre }} {{ item.apellido }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6 estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">RUT</label>
                            <input [(ngModel)]="visita.rut" #input (keyup)="formatRut(visita.rut)" autocomplete="off"
                                class="form-control estilo-input-2 gris" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6 estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">Nombre</label>
                            <input #nombre [ngClass]="error" autocomplete="off" [(ngModel)]="visita.nombre"
                                class="form-control estilo-input-2" placeholder="Nombre" />
                        </div>
                        <div class="form-group col-md-6 margen-izquierdo estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">Apellido</label>
                            <input autocomplete="off" [(ngModel)]="visita.apellido" class="form-control estilo-input-2"
                                placeholder="Apellido" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="label-txt margen-superior">Tipo de visita</label>
                            <select [ngClass]="errorselect" [(ngModel)]="visita.id_tipo_visita_temporal"
                                class="estilo-select-2" (change)="buildFields($event.target.value)"
                                [attr.disabled]="state ? '' : null">
                                <option value="0">Seleccione tipo de visita</option>
                                <option value="1">Reparto a domicilio</option>
                                <option value="2">Familiar o amigo</option>
                                <option value="3">Servicio</option>
                            </select>
                        </div>
                        <div *ngIf="tipo_visita == 2 || tipo_visita == 0"
                            class="form-group col-md-6 margen-izquierdo estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">Autorizado por:</label>
                            <input [(ngModel)]="residente_visita" class="form-control estilo-input-2" disabled />
                        </div>
                    </div>
                    <div *ngIf="tipo_visita == 2 || tipo_visita == 0 || visita.id_tipo_visita_temporal == 2"
                        class="form-row">
                        <div *ngIf="IsIntegracion != 1" class="form-group col-md-6">
                            <div class="margen">
                                <input [(ngModel)]="visita.estacionamiento" type="checkbox" />
                                <label class="no-padding tamano-txt margen-superior">Usar mi estacionamiento</label>
                            </div>
                        </div>

                        <div class="form-group col-md-6 margen-izquierdo">
                            <div class="margen">
                                <input [(ngModel)]="visita.recepcion" type="checkbox" />
                                <label class="no-padding tamano-txt margen-superior">Esperar en recepción</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row margen-inferior">
                        <div class="form-group col-md-6">
                            <label class="label-txt margen-superior">Estacionamiento</label>
                            <select [(ngModel)]="id_bien_selected" *ngIf="!visita.estacionamiento"
                                class="estilo-select-2 gris">
                                {{
                                id_bien_selected
                                }}
                                <option selected value="0">Sin Estacionamiento</option>
                                <!--aca->-->
                                <option *ngFor="let item of common_parking" [value]="item.id_bien">
                                    Estacionamiento {{ item.alias_ubicacion }}, {{ item.disponible_hasta }}
                                </option>
                            </select>
                            <select [(ngModel)]="id_bien_me_selected" *ngIf="visita.estacionamiento && general_select"
                                class="estilo-select-2 gris">
                                <option value="0">Sin Estacionamiento</option>
                                <option *ngFor="let item of my_parking" [value]="item.id_bien">
                                    {{ item.numero_bien }}
                                </option>
                            </select>
                            <select [(ngModel)]="id_bien_me_selected" *ngIf="visita.estacionamiento && !general_select"
                                class="estilo-select-2 gris">
                                <option value="0" selected>Sin Estacionamiento</option>
                                <option *ngFor="let item of my_parking" [value]="item.id_bien">
                                    {{ item.numero_bien }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 margen-izquierdo estilo-contenido-redondeado">
                            <label class="label-txt margen-superior">Patente</label>
                            <input maxlength="8" [(ngModel)]="visita.patente" #input autocomplete="off"
                                class="form-control estilo-input-2 gris" />
                        </div>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-enviar float-right pointer" (click)="ingresarVisita()">
                        Ingresar
                    </button>
                    <button type="button" class="btn btn-modal-cancelar pointer float-right" data-dismiss="modal"
                        (click)="closeVisitModal()">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--modal video-->
<div class="contenedor-modal-ingreso-visita">
    <div class="modal fade" id="modal-video">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Introducción Reservas de areas comunes</h4>
                    <button type="button" class="close pointer" (click)="closeVideoModal()" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    
                    <div class="video-container col-12">
                        <youtube-player width="100%" height="664"
                          [videoId]="videoId"
                          autoplay
                          (ready)="savePlayer($event)"
                          (stateChange)="onStateChange($event)"
                        ></youtube-player>
                      </div>
                </div>

                <!-- Modal footer -->
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-modal-enviar float-right pointer" (click)="ingresarVisita()">
                        Ingresar
                    </button>
                    <button type="button" class="btn btn-modal-cancelar pointer float-right" data-dismiss="modal"
                        (click)="closeVisitModal()">
                        Cancelar
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!--modal video-->

<!-- Modal tipo de correspondencia -->
<div class="contenedor-modal-tipo-de-correspondencia margen-circulos">
    <div class="modal fade" id="modal-tipo-de-correspondencia">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Correspondencia a {{ NombreResidente }}</h4>
                    <button type="button" class="close pointer" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="titulo-modal">Seleccione el tipo de correspondencia</div>
                    <div class="capsula-check">
                        <div class="contenedor-eleccion">
                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="sobre" />
                                <span>Sobre</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="caja" />
                                <span>Caja</span>
                            </div>

                            <div class="form-check margen">
                                <input autocomplete="off" [(ngModel)]="encomienda.tipo" class="form-check-input pointer"
                                    type="radio" name="exampleRadios" value="no especificado" />
                                <span>Otro</span>
                            </div>
                        </div>
                    </div>
                    <div class="titulo-modal-2">Observaciones:</div>
                    <textarea [(ngModel)]="encomienda.observaciones" class="textarea" rows="10" cols="10"></textarea>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar pointer" data-dismiss="modal">Cancelar</button>
                    <button (click)="notificarEncomienda()" type="button" class="btn btn-modal-enviar pointer"
                        data-dismiss="modal">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Notificaciones Accion-->

<div class="contenedor-modal-notificacion-accion">
    <div class="modal fade" id="modal-notificacion-accion">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Notificación a {{ NombreResidente }}</h4>
                    <button type="button" class="close pointer" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="titulo-modal">Titulo del mensaje</div>
                    <input [(ngModel)]="notificacion.titulo" class="select-modal-1" type="select"
                        placeholder="Ingrese un titulo" />
                    <div class="titulo-modal">Mensaje</div>
                    <textarea [(ngModel)]="notificacion.mensaje" class="textarea" rows="10" cols="10"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar pointer" data-dismiss="modal">Cancelar</button>
                    <button (click)="notificarResidente()" type="button" class="btn btn-modal-enviar pointer"
                        data-dismiss="modal">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal  Email-->

<div class="contenedor-modal-email">
    <div class="modal fade" id="modal-email">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Notificación a {{ NombreResidente }}</h4>
                    <button type="button" class="close pointer" data-dismiss="modal">
                        <img src="assets/images/ico-x.png" />
                    </button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="titulo-modal">Asunto del email</div>
                    <input [(ngModel)]="correo.asunto" class="select-modal-1" type="select"
                        placeholder="Ingrese un titulo" />
                    <div class="contenedor-editor">
                        <ckeditor [(ngModel)]="correo.texto" [ngModelOptions]="{ standalone: true }"></ckeditor>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-modal-cancelar pointer" data-dismiss="modal">Cancelar</button>
                    <button (click)="enviarCorreo()" type="button" class="btn btn-modal-enviar pointer"
                        data-dismiss="modal">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>