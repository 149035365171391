 <style>
            .controles-video {
                position: absolute;
                bottom: 0px;
                right: 0px;
            }
        </style>
        <video
            controls
            class="test-5"
            #videoElement
            style="object-fit: cover; width: 100%!important;"
            [style.width.px]="_citofoniaService.width"
            [style.height.px]="_citofoniaService.height"
        ></video>