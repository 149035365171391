import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core'
import { GlobalServicesService } from '../../services/global-services.service'
import { Notificacion, Correo, Difusion } from '../../models/notificacion.model'
import { Visita } from '../../models/visita.model'
import { ToastrService } from 'ngx-toastr'
import { VisitasService } from 'src/app/services/visitas/visitas.service'
import * as moment from 'moment'
import { Subject, Subscription } from 'rxjs'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { AreasComunesService } from '../areas-comunes/areas-comunes.service'
import { DataTableDirective } from 'angular-datatables'
import { NgxSpinnerService } from 'ngx-spinner'
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@full-fledged/alerts'
declare var $: any
declare var swal: any

@Component({
    selector: 'app-visitas',
    templateUrl: './visitas.component.html',
    styleUrls: ['./visitas.css']
})
export class VisitasComponent implements OnInit {

    videoId = 'HLExe8nHeM8';


    @ViewChild(DataTableDirective, { static: false })
    private played: boolean = false;
    dtElement: DataTableDirective
    dtTrigger: Subject<any> = new Subject<any>()
    public IsIntegracion: any = 0
    public horaSelected: any
    public minutosSelected: any
    public numero_dia = moment().isoWeekday()
    public ResidentesArray: any = []
    public ResidentesArrayFilter: any = []
    public VisitasArray: any = []
    public residente_visita: string
    public my_parking: Array<any> = []
    public DataVisita: any = 0
    public visita: any = new Visita(null, '', '', '', null, '', null, null, null, 0, 0, null, null, null, null, '')
    public searchTerm: any = []
    public correo = new Correo('', '', '', '', '')
    public notificacion = new Notificacion(null, 'conserje', '', '')
    public encomienda: { id_usuario: any; tipo: any; observaciones: any }
    public fechaSelected: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public DataVisitaCode: string = '';
    public difusion = new Difusion('', '', '', false)
    public NombreResidente: string
    public selectVisita: any
    public general_select: boolean
    public tipo_visita: number = 2
    public item: any
    public selectedValue = 0
    public emergency_title: boolean = false
    public emergency_date: boolean = false
    public state: boolean = false
    public common_parking: any
    public id_bien_selected: any = 0
    public id_bien_me_selected: any = 0
    public init_table: boolean = false
    public hora: any
    public error: string = ''
    public errorselect: string = ''
    public rut_message: any
    @ViewChild('nombre', { static: false }) searchElement: ElementRef
    public updateVisitas$: Subscription
    areas_actuales: any
    area_comun: any[]
    boton_reservas: boolean
    Horas: any[]
    Minutos: any[]
    HorasSelect: any[]
    MinutosSelect: any[]
    horaInicioMantencion: any
    public SetEstados = []
    public aux: boolean = false
    public TituloFilter: any
    FechaModalSelect: string
    title = 'angulardatatables'
    dtOptions: any = {}
    isDtInitialized: boolean = false

    constructor(
        public areascomunesService: AreasComunesService,
        public globalServicesService: GlobalServicesService,
        public toastr: ToastrService,
        public _visitaService: VisitasService,
        public _socketService: SocketServiceService,
        private spinner: NgxSpinnerService,
        private sanitizer: DomSanitizer,
        private alertService: AlertService
    ) { }


    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe()
    }

    ngOnInit() {
        //this.initvideo()

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            'lengthMenu': [
                [10, 25, 50, -1],
                ['10 rows', '25 rows', '50 rows', 'Show all']
            ],
            processing: true,
            order: [[0, 'desc']],
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel'
                }
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }
        this.IsIntegracion = sessionStorage.getItem('Integracion')
        this.updateVisitas$ = this._socketService.UpdateData$().subscribe((item) => {
            this.ngOnInit()

        })
        this.GetResidentes()
        this.iCkeck()
        this.notificacion = new Notificacion(null, 'misvisitas', '', '')
        this.correo = new Correo('', '', '', '', '')
        this.encomienda = { id_usuario: null, tipo: 'asdsadas', observaciones: '' }
        this.GetDataAreas()
    }

    savePlayer(player: any) {
        console.log('player instance', player);
        // El reproductor de YouTube está listo para ser utilizado
    }

    onStateChange(event) {
        console.log('player state', event.data);
    }






    initvideo() {
        $('#modal-video').modal('show')
        /* const flag = localStorage.getItem('played');
        if (flag === 'true') {
            this.played = true;
        } else {
            $('#modal-video').modal('show')
        } */

    }

    GetDataAreas() {
        let self = this
        self.GetAreaComun(function () {
            self.LlenarHoras('date', function () {
                self.VerDisponibilidad()
            })
        })
    }

    /*Test*/
    GetAreaComun(res = null) {
        let fecha = moment(moment()).format('YYYY-MM-DD')
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            fecha: fecha,
            numero_dia: this.numero_dia
        }

        this.areascomunesService.GetAreasComunes(data).subscribe(
            (result) => {
                this.areas_actuales = result.filter((resultado) => resultado.tipo == 'Estacionamientos')
                this.area_comun = []
                this.areas_actuales.forEach((ElementAreas, idx, array) => {
                    //si el id_etsado = 8 (mantencion) buscaremos el motivo
                    let tempestadospendientes = ElementAreas.reservas.filter((reservas) => reservas.estado == 1)
                    let tempestadosenuso = ElementAreas.reservas.filter((reservas) => reservas.estado == 5)
                    let tempconfiguraciondia = ElementAreas.configuraciones.filter(
                        (configuraciones) => configuraciones.id_dia == this.numero_dia
                    )
                    if (tempconfiguraciondia.length > 0) {
                        ElementAreas.reserva_hoy = tempconfiguraciondia[0].reserva
                    } else {
                        ElementAreas.reserva_hoy = 0
                    }

                    let obj = {
                        id_reserva: '',
                        id_modelo_ubicacion: ElementAreas.id_modelo_ubicacion,
                        id_bien: ElementAreas.id_bien,
                        tipo: ElementAreas.tipo,
                        configuraciones: ElementAreas.configuraciones,
                        reservas: ElementAreas.reservas,
                        nombre_area: '',
                        alias_ubicacion: '',
                        diponible_por: '',
                        disponible_hasta: '',
                        dia_entrada_area: '',
                        horas: 0,
                        RelojArea: '',
                        progreso: false,
                        horas_max: '',
                        estado: '',
                        hora_entrada: '',
                        hora_salida: '',
                        numero_bien: '',
                        patente: '',
                        id_login_usuario: '',
                        id_estado: '',
                        mantenciones: [],
                        configurado: '',
                        hora_inicio_para_reserva: '',
                        hora_fin_para_reserva: '',
                        nombre_usuario: ''
                    }

                    obj.alias_ubicacion = ElementAreas.nombre_area
                    obj.mantenciones = ElementAreas.mantenciones
                    obj.id_estado = ElementAreas.id_estado
                    obj.configurado = ElementAreas.configurado
                    obj.dia_entrada_area = ''
                    obj.RelojArea = ''
                    obj.nombre_usuario = tempestadospendientes.length > 0 ? tempestadospendientes[0].nombre_usuario : ''
                    if (tempconfiguraciondia.length > 0) {
                        obj.horas_max = tempconfiguraciondia[0].horas_max
                    }

                    if (
                        (ElementAreas.reservas.length == 0 || tempestadospendientes.length > 0) &&
                        tempestadosenuso.length == 0
                    ) {
                        obj.estado = '4'
                        this.area_comun.push(obj)
                    } /* else if (tempestadosenuso.length > 0) {
						obj.estado = "5";
						obj.numero_bien = tempestadosenuso[0].numero_bien;
						obj.patente = tempestadosenuso[0].patente;
						obj.hora_entrada = tempestadosenuso[0].hora_inicio;
						//eso es nuevo
						obj.hora_salida = tempestadosenuso[0].hora_fin;
						obj.id_reserva = tempestadosenuso[0].id_reserva;
						obj.dia_entrada_area = tempestadosenuso[0].dia_entrada_area;
						obj.id_login_usuario = tempestadosenuso[0].id_login_usuario;
						obj.nombre_usuario = tempestadosenuso[0].nombre_usuario;
					} */
                })

                this.common_parking = this.area_comun


                if (res) {
                    res()
                }
            },
            (error) => { }
        )
    }

    LlenarHoras(origen, res = null) {
        let desde
        let hasta
        let fechaSelected = moment(`${this.fechaSelected.year}-${this.fechaSelected.month}-${this.fechaSelected.day}`)
        if (fechaSelected.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            // esto es para hoy
            desde = moment(fechaSelected.format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))
        } else {
            //no es para hoy
            desde = moment(fechaSelected.format('YYYY-MM-DD') + ' 00:00')
        }

        hasta = moment(fechaSelected.format('YYYY-MM-DD') + ' 23:59')
        this.Horas = []
        this.Minutos = []
        this.HorasSelect = []
        this.MinutosSelect = []
        //generar horas
        for (let index = 0; index < 24; index++) {
            let aux = index.toString()
            if (aux.length == 1) {
                aux = '0' + aux
            }
            this.HorasSelect.push(aux)
        }
        let current = desde
        while (current.isBefore(hasta)) {
            this.Horas.push(current.format('HH'))
            current.add(1, 'hours')
        }
        if (origen == 'date') {
            this.horaSelected = this.Horas[0]
            this.horaInicioMantencion = this.Horas[0]
        }

        if (fechaSelected.format('YYYY-MM-DD') + ' ' + this.horaSelected == moment().format('YYYY-MM-DD HH')) {
            // esto es para hoy
            desde = parseInt(moment().format('mm'))
        } else {
            desde = 0
        }
        hasta = 59
        //generar minutos
        current = desde
        while (current <= hasta) {
            this.Minutos.push(current > 9 ? `${current}` : `0${current}`)
            current++
        }
        for (let index = 0; index < 60; index++) {
            let aux = index.toString()
            if (aux.length == 1) {
                aux = '0' + aux
            }
            this.MinutosSelect.push(aux)
        }

        this.minutosSelected = this.Minutos[0]
        if (res) {
            res()
        }
    }

    VerDisponibilidad(parman?) {
        let fecha = `${this.fechaSelected.year}-${this.fechaSelected.month}-${this.fechaSelected.day}`
        let hora = `${this.horaSelected}:${this.minutosSelected}`
        let numeroDia = moment().isoWeekday()
        this.TituloFilter = moment(moment(fecha).format('YYYY-MM-DD') + ' ' + hora)
        this.FechaModalSelect = moment(fecha).format('YYYY-MM-DD')
        this.common_parking.forEach((element) => {
            //valido si existen reservas

            let filterConfig = element.configuraciones.filter((element) => element.id_dia == numeroDia)
            if (element.reservas.length > 0) {
                let ReservasConflicto = element.reservas.filter(function (reserva) {
                    var actual = moment(`${fecha} ${hora}`)
                    var inicio = moment(`${reserva.dia_entrada_area} ${reserva.hora_inicio}`)
                    var fin = moment(`${reserva.dia_entrada_area} ${reserva.hora_fin}`)
                    var resultMinutesInicio = inicio.diff(actual, 'minutes', true)
                    if (resultMinutesInicio < filterConfig[0].horas_max * 60 && resultMinutesInicio > 0) {
                        return true
                    } else {
                        return false
                    }
                })

                let ReservasSolapadas = element.reservas.filter(function (reserva) {
                    var actual = moment(`${fecha} ${hora}`)
                    var inicio = moment(`${reserva.dia_entrada_area} ${reserva.hora_inicio}`)
                    var fin = moment(`${reserva.dia_entrada_area} ${reserva.hora_fin}`)
                    var resultMinutesFin = fin.diff(actual, 'minutes', true)
                    if (actual.isSameOrAfter(inicio) && resultMinutesFin > 0 && actual.isSameOrBefore(fin)) {
                        return true
                    } else {
                        return false
                    }
                })

                if (ReservasSolapadas.length > 0) {
                    //pintar azul aqui
                    if (ReservasSolapadas[0].estado != 5) {
                        element.estado = 7
                    }

                    element.numero_bien = ReservasSolapadas[0].numero_bien
                    element.hora_entrada = ReservasSolapadas[0].hora_inicio
                    element.hora_salida = ReservasSolapadas[0].hora_fin
                    element.id_reserva = ReservasSolapadas[0].id_reserva
                } else if (ReservasConflicto.length > 0) {
                    //calcular hasta proxima reserva;
                    let actual = moment(`${fecha} ${hora}`)
                    let inicio = moment(`${ReservasConflicto[0].dia_entrada_area} ${ReservasConflicto[0].hora_inicio}`)
                    var resultMinutes = inicio.diff(actual, 'minutes', true)
                    element.estado = 4
                    element.disponible_hasta = `Disponible hasta las ${moment(hora, 'HH:mm')
                        .add(resultMinutes, 'minutes')
                        .format('HH:mm')}`
                    element.diponible_por = `Disponible por ${moment
                        .duration(resultMinutes, 'minutes')
                        .format('d[d] HH:mm')}`
                    element.hora_inicio_para_reserva = hora
                    element.hora_fin_para_reserva = moment(hora, 'HH:mm').add(resultMinutes, 'minutes').format('HH:mm')

                } else {
                    //no hay reservas
                    element.disponible_hasta = `Disponible hasta las ${moment(hora, 'HH:mm')
                        .add(filterConfig[0].horas_max, 'hours')
                        .format('HH:mm')}`

                    element.diponible_por = `Disponible por ${filterConfig[0].horas_max} horas`
                    element.hora_inicio_para_reserva = hora
                    element.hora_fin_para_reserva = moment(hora, 'HH:mm')
                        .add(filterConfig[0].horas_max, 'hours')
                        .format('HH:mm')
                    element.estado = 4
                }
            } else {
                //no hay reservas
                if (filterConfig.length > 0 && filterConfig[0].horas_max != null) {
                    element.disponible_hasta = `Disponible hasta las ${moment(hora, 'HH:mm')
                        .add(filterConfig[0].horas_max, 'hours')
                        .format('HH:mm')}`
                    element.diponible_por = `Disponible por ${filterConfig[0].horas_max} horas`
                    element.hora_inicio_para_reserva = hora
                    element.hora_fin_para_reserva = moment(hora, 'HH:mm')
                        .add(filterConfig[0].horas_max, 'hours')
                        .format('HH:mm')
                } else {
                    element.diponible_por = `Área no configurada`
                }
            }
        })
    }

    iCkeck() {
        $('.check-op').iCheck({
            checkboxClass: 'icheckbox_minimal-green',
            radioClass: 'iradio_minimal-green',
            increaseArea: '20%' // optional
        })

        $('#check_otra').on('ifClicked', function (event) {
            $('.capsula-otras-opciones').toggleClass('invisible')
        })
    }

    async GetResidentes() {
        this.spinner.show()
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        this.globalServicesService.getResidentes(data).subscribe(async (result) => {
            this.ResidentesArray = result
            await this.GetVisitas()
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }

            this.spinner.hide()
        })
    }

    GetVisitas() {
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        this.globalServicesService.getVisitas(data).subscribe((result) => {
            this.VisitasArray = result
        })
    }

    setFilteredItems() {
        if (this.searchTerm == '') {
            this.ResidentesArrayFilter = []
            this.init_table = false
        } else {
            this.ResidentesArrayFilter = this.filterItems(this.ResidentesArray, this.searchTerm)
            this.init_table = true
        }
    }

    filterItems(array: any, searchTerm: any) {
        return array.filter((item) => {
            if (item.nombre_bus != null) {
                return (
                    item.nombre_bus.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
                    item.numero_bien.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
                )
            }
        })
    }

    GetVisitID(item: any) {

        this._visitaService
            .getBienesByUser({
                id_usuario: item.id_login_usuario,
                id_proyecto: sessionStorage.getItem('IdSelectProject')
            })
            .subscribe((resp: any) => {
                resp.forEach((element: object) => {
                    this.my_parking.push(element)
                })
                this.my_parking = this.my_parking.filter((x: any) => x.id_tipo_bien == 3)
            })
        this.visita.id_bien = item.id_bien
        this.residente_visita = item.nombre + ' ' + item.apellido
        this.general_select = false
        let id_login = item.id_login_usuario
        let VisitaTemp = this.VisitasArray.filter((element: any) => element.id_login_usuario == id_login)
        this.selectVisita = VisitaTemp
        this.item = item
    }

    GetUserID(item) {
        this.notificacion.id_usuario = item.id_login_usuario
        this.encomienda.id_usuario = item.id_login_usuario
        this.NombreResidente = `${item.nombre} ${item.apellido}`
        //this.correo.para = item.username.trim();
        this.correo.para = item.username.trim()
        this.correo.nombre = this.NombreResidente
    }

    insertNotificacionBitacora(text: String) {
        let id_login_usuario = JSON.parse(sessionStorage.getItem('identidad'))
        let data = {
            id_sistema: 1,
            id_login_usuario: id_login_usuario.id_login_usuario,
            id_accion: 13,
            texto: text
        }
        this._visitaService.insertBinnacleNotify(data).subscribe((resp: any) => { })
    }

    notificarResidente() {
        this.globalServicesService.enviarNotificacion(this.notificacion).subscribe(
            (response) => {
                this.alertService.success('Notificación enviada.')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            },
            (error) => {
                this.alertService.danger('Ocurrio un error al enviar la notificacion')
            }
        )
    }

    enviarCorreo() {
        this.globalServicesService.enviarCorreo(this.correo).subscribe(
            (response) => {
                this.alertService.success('El correo se a eviado correctamente')
            },
            (error) => {
                this.alertService.danger('Ocurrio un error al enviar el correo, el correo ingresado por el usuario es invalido')
            }
        )
    }

    notificarEncomienda() {
        this.notificacion = new Notificacion(
            this.encomienda.id_usuario,
            'conserje',
            'Se ha recibido una encomienda dirigida a su domicilio, favor retirarla a la brevedad en recepción. Atentamente, la administración. ---> Observaciones: ' +
            this.encomienda.observaciones,
            'Encomienda ' + '(' + this.encomienda.tipo + ')'
        )
        this.globalServicesService.enviarNotificacion(this.notificacion).subscribe(
            (response) => {
                this.alertService.success('Notificación enviada.')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
                this.encomienda = { id_usuario: null, tipo: '', observaciones: '' }
            },
            (error) => {
                this.alertService.danger('Problemas al enviar notificación')
                this.notificacion = new Notificacion(null, 'conserje', '', '')
                this.encomienda = { id_usuario: null, tipo: '', observaciones: '' }
            }
        )
    }

    enviarDifusion() {
        this.difusion.id_proyecto = sessionStorage.getItem('IdSelectProject')
        swal({
            title: 'Esta seguro?',
            text: 'Se enviará este mensaje a toda la comunidad!',
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this.globalServicesService.enviarDifusion(this.difusion).subscribe(
                    (response) => {
                        this.alertService.success('Difusión enviada.')
                        this.insertNotificacionBitacora(this.difusion.titulo)
                        this.difusion = new Difusion('', '', '', false)
                    },
                    (error) => {
                        this.alertService.danger('Problemas al enviar difusión')
                        this.difusion = new Difusion('', '', '', false)
                    }
                )
            }
        })
    }

    notificarEncomiendaMasiva() {
        this.difusion.id_proyecto = sessionStorage.getItem('IdSelectProject')
            ; (this.difusion.titulo = 'Correspondencia (' + this.encomienda.tipo + ')'),
                (this.difusion.mensaje =
                    'Se ha recibido una correspondencia dirigida a su domicilio, favor retirarla a la brevedad en recepción. Atentamente, la administración. ---> Observaciones: ' +
                    this.encomienda.observaciones),
                'Encomienda ' + '(' + this.encomienda.tipo + ')'
        swal({
            title: 'Esta seguro?',
            text: 'Se enviará este mensaje a toda la comunidad!',
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this.globalServicesService.enviarDifusion(this.difusion).subscribe(
                    (response) => {
                        this.alertService.success('Aviso enviado.')
                        this.insertNotificacionBitacora(this.difusion.titulo)
                        this.difusion = new Difusion('', '', '', false)
                        this.encomienda.observaciones = ''
                        this.encomienda.tipo = ''
                    },
                    (error) => {
                        this.alertService.danger('Problemas al enviar el aviso')
                        this.difusion = new Difusion('', '', '', false)
                    }
                )
            }
        })
    }

    enviarAlerta() {
        if (this.fechaSelected === undefined) {
            this.difusion.mensaje = this.difusion.mensaje
        } else {
            this.difusion.mensaje =
                this.difusion.mensaje +
                ' para la fecha ' +
                this.fechaSelected.day +
                '-' +
                this.fechaSelected.month +
                '-' +
                this.fechaSelected.year
        }
        if (this.hora) {
            this.difusion.mensaje = this.difusion.mensaje + ' a las ' + this.hora
        }
        ; (this.difusion.titulo = 'Alerta (' + this.difusion.titulo + ')'),
            (this.difusion.mensaje = this.difusion.mensaje)
        this.difusion.id_proyecto = sessionStorage.getItem('IdSelectProject')
        swal({
            title: 'Esta seguro?',
            text: 'Se enviará este mensaje a toda la comunidad!',
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this.globalServicesService.enviarEmergencia(this.difusion).subscribe(
                    (response) => {
                        this.alertService.success('Alerta enviado.')
                        this.insertNotificacionBitacora(this.difusion.titulo)
                        this.difusion = new Difusion('', '', '', false)
                        this.clearEmergencyField()
                    },
                    (error) => {
                        this.alertService.danger('Problemas al enviar alerta')
                        this.difusion = new Difusion('', '', '', false)
                    }
                )
            }
        })
    }

    MostrarVisita() {
        if (this.DataVisita == 0) {
            this.alertService.warning('Seleccione Visita autorizada')
        } else {
            this.general_select = true
            let VisitaTemp = this.VisitasArray.filter((element: any) => element.id_visita == this.DataVisita)[0]
            this.visita = VisitaTemp
            this.formatRut(this.visita.rut)
            this.state = true
            this.visita.id_tipo_visita_temporal = 2
            this._visitaService
                .getBienesByUser({
                    id_usuario: VisitaTemp.id_login_usuario,
                    id_proyecto: sessionStorage.getItem('IdSelectProject')
                })
                .subscribe((resp: any) => {
                    resp.forEach((element: object) => {
                        this.my_parking.push(element)
                    })
                    this.my_parking = this.my_parking.filter((x: any) => x.id_tipo_bien == 3)
                    this.id_bien_me_selected = this.visita.id_bien_estacionamiento
                })

            var filtered_resident = this.ResidentesArray.filter((n: any) => {
                return n.id_login_usuario == VisitaTemp.id_login_usuario
            })
            this.visita.id_bien = filtered_resident[0].id_bien
            this.residente_visita = filtered_resident[0].nombre + ' ' + filtered_resident[0].apellido
            $('#modal-ingreso-visita').modal('show')
        }
    }

    fillFields(item: any) {
        this.visita = JSON.parse(item)
        if (typeof JSON.parse(item) === 'object') {
            this.formatRut(this.visita.rut)
            this.state = true
            this.visita.id_tipo_visita_temporal = 2
        } else {
            this.selectedValue = 0
            this.visita = new Visita(null, '', '', '', null, '', null, null, null, 0, 0, null, null, null, null, '')
            this.state = false
        }
    }

    ingresarVisita() {
        this.validateRut(this.visita.rut)

        if (this.visita.id_tipo_visita_temporal == 0) {
            this.alertService.danger('Debe seleccionar un tipo de visita')
            this.errorselect = 'errorselect'
            setTimeout(() => {
                this.errorselect = ''
            }, 5000)
            return
        }

        this.errorselect = ''
        if (!this.visita.nombre) {
            this.alertService.danger('Debe ingresar un nombre')
            this.searchElement.nativeElement.focus()
            this.error = 'error'
            setTimeout(() => {
                this.error = ''
            }, 5000)
            return
        }
        this.error = ''
        if (this.visita.id_tipo_visita_temporal == 1 || this.visita.id_tipo_visita_temporal == 3) {
            this.visita.id_tipo_visita = 2
        }

        if (this.visita.estacionamiento) {
            var me_parking = this.my_parking.filter((x: any) => {
                return x.id_bien == this.id_bien_me_selected
            })
            if (me_parking.length == 0) {
                this.visita.id_bien_estacionamiento = null
                this.visita.id_bien_est = null
                this.visita.id_modelo_ubicacion = null
            } else {
                this.visita.id_bien_estacionamiento = me_parking[0].id_bien
                this.visita.id_bien_est = me_parking[0].id_bien
                this.visita.id_modelo_ubicacion = null
            }
        } else {
            let common_parking = this.common_parking.filter((x: any) => {
                return x.id_bien == this.id_bien_selected
            })

            if (common_parking.length == 0) {
                this.visita.id_bien_estacionamiento = null
                this.visita.id_bien_est = null
                this.visita.id_modelo_ubicacion = null
            } else {
                this.visita.hora_fin = common_parking[0].hora_fin_para_reserva
                this.visita.id_bien_estacionamiento = common_parking[0].id_bien
                this.visita.id_bien_est = common_parking[0].id_bien
                this.visita.id_modelo_ubicacion = common_parking[0].id_modelo_ubicacion
            }
        }
        this.visita.id_proyecto = sessionStorage.getItem('IdSelectProject')
        if (this.visita.fecha == null) {
            this.visita.fecha = moment().format('YYYY-MM-DD')
        }

        if (this.visita.id_visita == null) {
            this.visita.id_tipo_visita = 2
            this.visita.id_estado = 2
            this.visita.id_login_usuario = this.item.id_login_usuario
            this.visita.eliminado = 0
        }
        this.visita.id_modelo_ubicacion = 0
        this.visita.id_tipo_visita_temporal = 2
        this.visita.hora = moment().format('HH:mm')

        this._visitaService.saveVisit(this.visita).subscribe((resp: any) => {
            if (resp.response == 1) {
                this.toastr.success('Visita actualizada', 'Listo', {
                    positionClass: 'toast-bottom-left',
                    progressBar: true
                })
            } else {
                this.toastr.success('Visita creada', 'Listo', {
                    positionClass: 'toast-bottom-left',
                    progressBar: true
                })
            }
            this.notificacion = new Notificacion(
                this.visita.id_login_usuario,
                'misvisitas',
                'Su visita ' + this.visita.nombre + ' ' + this.visita.apellido + ' ha sido ingresada',
                'Visita ingresada'
            )
            this.notificarResidente()
            $('#modal-ingreso-visita').modal('hide')
            this.clearFields()
            this.GetVisitas()
            this.closeVisitModal()
            this.GetDataAreas()
            this.DataVisitaCode = '';
        })
    }

    buildFields(e: any) {
        this.tipo_visita = e
        if (this.tipo_visita == 1 || this.tipo_visita == 3) {
            this.visita.estacionamiento = 0
            this.visita.recepcion = 0
        }
    }

    private clearFields() {
        this.visita = new Visita(null, '', '', '', null, '', null, null, null, 0, 0, null, null, null, null, '')
        this.selectedValue = 0
        this.tipo_visita = 0
        this.residente_visita = undefined
        this.DataVisita = 0
        this.my_parking = []
        this.id_bien_selected = 0
        this.id_bien_me_selected = 0
    }

    clearModalNotification() {
        this.difusion.titulo = ''
        this.difusion.mensaje = ''
        this.encomienda.tipo = ''
        this.encomienda.observaciones = ''
    }

    close() {
        $('#modal-ingreso-visita').modal('hide')
        this.clearFields()
    }

    handlingFields(type_incident: number) {
        if (type_incident == 0) {
            this.emergency_date = false
            this.emergency_title = false
        }
        if (type_incident == 1 || type_incident == 2 || type_incident == 3) {
            this.emergency_date = true
            this.emergency_title = false
        }
        if (type_incident == 4) {
            this.emergency_date = true
            this.emergency_title = true
        }
    }

    clearEmergencyField() {
        this.emergency_date = false
        this.emergency_title = false
        this.difusion = new Difusion('', '', '', false)
    }

    closeEmergency() {
        this.clearEmergencyField()
    }

    closeVisitModal() {
        this.clearFields()
        this.GetVisitas()
        this.state = false
    }

    closeVideoModal() {
        localStorage.setItem('played', 'true')
        this.state = false
    }

    formatRut(rut: any) {
        if (rut) {
            if (rut.length > 1) {
                rut = this.cleanRut(rut)
                var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
                for (var i = 4; i < rut.length; i += 3) {
                    result = rut.slice(-3 - i, -i) + '.' + result
                }
                this.visita.rut = result
            }
        }
    }

    formatPlate(plate: any) {
        if (plate) {

            if (plate.length > 1) {
                plate = this.cleanPlate(plate)
                var result = plate.slice(-3, -1) + '-' + plate.substr(plate.length - 2)

                for (var i = 4; i < plate.length; i += 2) {
                    result = plate.slice(-2 - i, -i) + '-' + result
                }
                this.visita.patente = result
            }
        }
    }

    cleanPlate(plate: any) {
        return typeof plate === 'string' ? plate.replace(/^0+|[^0-9A-Za-z]+/g, '').toUpperCase() : ''
    }

    cleanRut(rut: any) {
        return typeof rut === 'string' ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() : ''
    }

    validateRut(rut: any) {
        if (rut) {
            this.visita.rut = this.cleanRut(rut).replace('.', '')
            // Despejar Guión
            this.visita.rutx = this.visita.rut.replace('-', '')
            // Aislar Cuerpo y Dígito Verificador
            var cuerpo = this.visita.rut.slice(0, -1)
            var dv = this.visita.rut.slice(-1).toUpperCase()
            // Formatear RUN
            rut = cuerpo + '-' + dv
            // Si no cumple con el mínimo ej. (n.nnn.nnn)
            if (cuerpo.length < 7 && cuerpo.length < 0) {
                this.rut_message = 'RUT Incompleto'
                return false
            }
            // Calcular Dígito Verificador
            var suma = 0
            var multiplo = 2
            // Para cada dígito del Cuerpo
            for (let i = 1; i <= cuerpo.length; i++) {
                // Obtener su Producto con el Múltiplo Correspondiente
                var index = multiplo * this.visita.rut.charAt(cuerpo.length - i)
                // Sumar al Contador General
                suma = suma + index
                // Consolidar Múltiplo dentro del rango [2,7]
                if (multiplo < 7) {
                    multiplo = multiplo + 1
                } else {
                    multiplo = 2
                }
            }

            // Calcular Dígito Verificador en base al Módulo 11
            var dvEsperado = 11 - (suma % 11)
            // Casos Especiales (0 y K)
            dv = dv == 'K' ? 10 : dv
            dv = dv == 0 ? 11 : dv
            // Validar que el Cuerpo coincide con su Dígito Verificador
            if (dvEsperado != dv) {
                this.rut_message = 'RUT Inválido'
                return false
            }
            // Si todo sale bien, eliminar errores (decretar que es válido)
            this.rut_message = 1
        }
    }

    ValidarVisita() {
        if (this.DataVisitaCode !== '') {
            this._visitaService.validarVisitaQR({ "codigo_visita": this.DataVisitaCode })
                .subscribe(visitaData => {
                    if (visitaData['err'] == 200) {
                        this.alertService.danger(visitaData['message'])
                    } else {
                        this.DataVisita = visitaData['id_visita'];
                        this.MostrarVisita();
                    }
                })
        }
        if (parseInt(this.DataVisita) !== 0 && this.DataVisitaCode === '') this.MostrarVisita();
        if (parseInt(this.DataVisita) === 0 && this.DataVisitaCode === '') this.alertService.warning('Seleccione Visita autorizada');
    }
}
