import { Component, OnInit, Injectable, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { RealizarLlamadaService } from './realizar-llamada.service'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { Observable, Subscription } from 'rxjs'
import { CitofoniaService } from 'src/app/services/citofonia/citofonia.service'
import { GlobalServicesService } from 'src/app/services/global-services.service'

@Injectable({
    providedIn: 'root'
})
@Component({
    selector: 'app-realizar-llamada',
    templateUrl: './realizar-llamada.component.html',
    styles: []
})
export class RealizarLlamadaComponent implements OnInit, OnDestroy {
    public selectUser$: Observable<any>
    public llamadaActiva: boolean = false
    public user_data: any = JSON.parse(sessionStorage.getItem('identidad'))
    public colgar_llamada_desde_movil$: Observable<any>
    public usuariosConectados$: Subscription
    public salon: any
    @ViewChild('videoUser', { static: false }) elementRef: any

    constructor(
        public _realizarLlamadaService: RealizarLlamadaService,
        public _citofoniaService: CitofoniaService,
        public _socketService: SocketServiceService,
        public _globalService: GlobalServicesService
    ) {}

    ngOnInit() {
        this.salon = `Sala${this.user_data.id_login_usuario}Proyecto${sessionStorage.getItem('IdSelectProject')}`
        //Observable, se activa cuando se presiona el boton de llamar en el componente citofonia y existe usuario seleccionado
        this.selectUser$ = this._socketService.ChangeselectUser$()
        this.selectUser$.subscribe((res: any) => {
            let data_not = {
                titulo: 'Citofonia',
                mensaje: `Llamada entrante de ${this.user_data.nombre} ${this.user_data.apellido}`,
                modulo: 'citofonia',
                nombre_remitente: `${this.user_data.nombre} ${this.user_data.apellido}`,
                id_sala: this.salon,
                id_usuario: res.id_login_usuario,
                id_usuario_remitente: this.user_data.id_login_usuario
            }
            this._globalService.envioNotificacionBkCitofonia(data_not).subscribe()
            //Emite socket para preguntar quien esta conectado
            this._socketService.usuariosConectados()
            //Devuelve la respuesta con una arreglo de usuarios conectados y sus atributos
            this.usuariosConectados$ = this._socketService
                .UsuariosConectadosNotificacion$()
                .subscribe((resp: any) => {})
            //Boleano para ocultar la seccion de video, declaracion de la sala y emision de que el conserje esta ocupado
            this._citofoniaService.realizar = true
            let id_salon = this.salon
            let data_ocupado = {
                id_usuario: this.user_data.id_login_usuario,
                ocupado: true
            }
            this._socketService.estoyOcupado(data_ocupado)

            this._citofoniaService.joinSession(id_salon)
            let data = {
                time: 5000,
                id_usuario: res.id_login_usuario,
                nombre_remitente: this.user_data.nombre + ' ' + this.user_data.apellido,
                id_sala: id_salon,
                numero_bien: 'conserjeria',
                id_usuario_remitente: this.user_data.id_login_usuario
            }
            this._socketService.realizarLlamada(data)
        })
    }

    openFullscreen() {
        if (this._citofoniaService.modal) {
            this._citofoniaService.modal = false
            this._citofoniaService.width = this.getWidth()
            this._citofoniaService.height = this.getHeight()
        } else {
            this._citofoniaService.modal = true
            this._citofoniaService.width = 650
            this._citofoniaService.height = 400
        }
    }

    changeVolumen() {
        let id_c = document.getElementsByTagName('video')[0].id
        let id_u = document.getElementsByTagName('video')[1].id
        let id_d = document.getElementsByTagName('video')[2].id
        let elem_c = <HTMLMediaElement>document.getElementById(id_c)
        let elem_u = <HTMLMediaElement>document.getElementById(id_u)
        let elem_d = <HTMLMediaElement>document.getElementById(id_d)

        elem_c.volume = 0
        elem_u.volume = 0
        elem_d.volume = 0
    }

    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        )
    }

    getHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        )
    }

    ngOnDestroy() {
        this.usuariosConectados$.unsubscribe()
    }
}
