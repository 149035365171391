import { RouterModule, Routes } from '@angular/router'
import { PagesComponent } from './pages.component'
import { VisitasComponent } from './visitas/visitas.component'
import { AreasComunesComponent } from './areas-comunes/areas-comunes.component'
import { BitacoraComponent } from './bitacora/bitacora.component'
import { LoginGuardGuard } from '../services/service.index'
import { CitofoniaComponent } from './citofonia/citofonia.component'
import { EventosComponent } from './eventos/eventos.component'
import { CovidComponent } from './covid/covid.component'
import { EncomiendaComponent } from './encomienda/encomienda.component'
import { CalendarioComponent } from './calendario/calendario.component'
import { MultasComponent } from './multas/multas.component'
import { VisitantesComponent } from './visitantes/visitantes.component'
import { EntregaLlavesComponent } from './entrega-llaves/entrega-llaves.component'

const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [LoginGuardGuard],
        children: [
            { path: 'visitas', component: VisitasComponent, data: { titulo: 'Visitas' } },
            { path: 'visitantes', component: VisitantesComponent, data: { titulo: 'Visitantes' } },
            { path: 'areas', component: AreasComunesComponent, data: { titulo: 'Áreas comunes' } },
            { path: 'bitacora', component: BitacoraComponent, data: { titulo: 'Bitácora' } },
            { path: 'citofonia', component: CitofoniaComponent, data: { titulo: 'Citofonía' } },
            { path: 'eventos', component: EventosComponent, data: { titulo: 'Eventos' } },
            { path: 'covid', component: CovidComponent, data: { titulo: 'Covid-19' } },
            { path: 'encomienda', component: EncomiendaComponent, data: { titulo: 'Encomienda' } },
            { path: 'calendario', component: CalendarioComponent, data: { titulo: 'Calendario' } },
            { path: 'multas', component: MultasComponent, data: { titulo: 'Multas' } },
            { path: 'entrega-llaves', component: EntregaLlavesComponent, data: { titulo: 'Entrega-Llaves' } },
            { path: '', redirectTo: '/visitas', pathMatch: 'full' },
            {
                path: 'day-view-scheduler',
                loadChildren: () => import('./day-view-scheduler/module').then((m) => m.DemoModule),
                data: {
                    label: 'Day view scheduler'
                }
            }
        ]
    }
]

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes)
