import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class IngresarBitacoraService {
    public oculto: string = 'oculto'

    constructor() {}

    hideModal() {
        this.oculto = 'oculto'
    }

    showModal() {
        this.oculto = ''
    }
}
