import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgForm } from '@angular/forms'
import { UsuarioService } from '../services/service.index'
import { GlobalServicesService } from '../services/global-services.service'
import { Usuario } from '../models/usuario.model'
import { ToastrService } from 'ngx-toastr'
import swal from 'sweetalert'
import { AlertService } from '@full-fledged/alerts'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public recuerdame: boolean = false
    public email: string
    public StatusModal: boolean = false
    public IdSelectProject: any = 0
    public DatosUsuarios: any = { datos: '', proyectos: '' }
    public recuperaModal: Boolean = false
    public recupera: string

    constructor(
        public _router: Router,
        public _usuarioService: UsuarioService,
        private globalServicesService: GlobalServicesService,
        private toastr: ToastrService,
        private alertService: AlertService
    ) {
        if (sessionStorage.getItem('IsLoginActive')) {
            this._usuarioService.IsLoginActive = 1
        } else {
            this._usuarioService.IsLoginActive = 0
        }
    }

    ngOnInit() { }

    RecuperarContrasena() {
        this.cerrarModalRecuperar()
        var formElem = new FormData()
        formElem.append('list', this.recupera)
        formElem.append('all', 'reset')
        this._usuarioService.Recuperarcontrasena(formElem).subscribe((result) => {
            if (result.html == undefined) {
                this.alertService.danger(result.title)
            } else {
                this.alertService.success(result.html)
            }
        })
    }

    ingresar(forma: NgForm) {
        if (forma.invalid) {
            return
        }
        let usuario = new Usuario(null, forma.value.email, forma.value.password)
        var data = { usuario: usuario.user_email, contrasena: usuario.password, from: 'conserjeria' }
        this._usuarioService.getUsuario(data).subscribe((result) => {
            /* if (!result.Proyectos) {
                this.alertService.danger('No hay proyecto asociado')
                return
            } */
            if (result.error == 0) {
                this.DatosUsuarios.datos = result.DatosUsuario[0]

                this.DatosUsuarios.proyectos = this.globalServicesService.getUniqueValuesOfKey(
                    result.Proyectos,
                    'id_proyecto'
                ) //result.Proyectos;
                sessionStorage.setItem('LoginUsuario', JSON.stringify(this.DatosUsuarios.proyectos))
                sessionStorage.setItem('identidad', JSON.stringify(this.DatosUsuarios.datos))
                sessionStorage.setItem('IsLoginActive', '1')
                this.StatusModal = true
            }
            if (result.error == 200) {
                this.alertService.danger(result.message)
                return
            }
        })
    }

    ProjectDefault() {
        if (this.IdSelectProject != 0) {
            sessionStorage.setItem('IdSelectProject', this.IdSelectProject)
            sessionStorage.setItem(
                'Integracion',
                this.DatosUsuarios.proyectos.filter((element) => {
                    return element.id_proyecto == this.IdSelectProject
                })[0].integracion_id
            )
            sessionStorage.setItem(
                'externo_id',
                this.DatosUsuarios.proyectos.filter((element) => {
                    return element.id_proyecto == this.IdSelectProject
                })[0].externo_id
            )
            this._usuarioService.IsLoginActive = 1
            this._router.navigate(['/visitas'])
            this.cerrarModal(0)
        } else {
            this.alertService.danger('Seleccione proyecto')
        }
    }

    cerrarModal(aux: number = 1) {
        this.StatusModal = false
        if (aux == 1) {
            sessionStorage.clear()
        }
    }

    recuperarClave() {
        this.recuperaModal = true
    }

    cerrarModalRecuperar() {
        this.recuperaModal = false
    }

    RecuperarClaveRequest() {
        this._usuarioService.recuperarClave(this.recupera).subscribe((res: any) => {
            if (res.actualizado == 1) {
                this.alertService.success('Revise su correo electrónico para ver su nueva contraseña')
                this.cerrarModalRecuperar()
                return
            }
        })
    }
}
