import { Injectable } from '@angular/core'
import { Bitacora } from 'src/app/models/bitacora.model'
import { HttpClient } from '@angular/common/http'
import { map, tap } from 'rxjs/operators'
import { Subject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class BitacoraService {
    allBinnacle: Bitacora[] = []
    public _refresh = new Subject<void>()
    public refresh_count$: Subject<any> = new Subject<any>()

    constructor(public http: HttpClient) {}

    get refresh() {
        return this._refresh
    }

    createBinnacle(binnacle: Bitacora) {
        binnacle.id_proyecto = sessionStorage.getItem('IdSelectProject')
        let url = environment.URL_SERVICIOS + 'insertBitacora'
        return this.http.post(url, binnacle).pipe(
            map((resp: any) => {
                return resp
            }),
            tap(() => {
                return this._refresh.next()
            })
        )
    }

    GetTorres() {
        let FilterSchema = JSON.parse(sessionStorage.getItem('LoginUsuario'))
        let schema = FilterSchema.filter((element) => {
            return element.id_proyecto == sessionStorage.getItem('IdSelectProject')
        })
        let data = { schema: schema[0].cliente }
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ListEtapasProyecto'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            }),
            tap(() => {
                return this._refresh.next()
            })
        )
    }

    getAllBinnaclePaginated(data: object) {
        let url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ListBitacora'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    getBinnacle(binnacle_id: number) {
        let data = {
            binnacle_id: binnacle_id,
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        let url = environment.URL_SERVICIOS + 'getBinnacleById'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    editBinnacle(title: string, state: number, commentary: string, binnacle_id: number, modulo: any) {
        let data = {
            title: title,
            state: state,
            commentary: commentary,
            binnacle_id: binnacle_id,
            modulo: modulo,
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        let url = environment.URL_SERVICIOS + 'editBinnacle'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            }),
            tap(() => {
                return this._refresh.next()
            })
        )
    }

    getCountBinnacle() {
        let id_proyecto = sessionStorage.getItem('IdSelectProject')
        let data = {
            id_proyecto: id_proyecto
        }
        let url = environment.URL_SERVICIOS + 'getCountBinnacle'
        return this.http.post(url, data).map((resp: any) => {
            return resp
        })
    }

    refreshBinnacleCount() {
        this.refresh_count$.next()
    }

    RefreshBinnacleCount$(): Observable<any> {
        return this.refresh_count$.asObservable()
    }

    formatDate(nu: any) {
        let new_number: any
        if (nu.toString().length == 1) {
            new_number = '0' + nu
            return new_number
        } else {
            return nu
        }
    }
}
