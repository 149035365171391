import { Injectable, EventEmitter } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class VerDetalleMultaService {
    public hidde: string = 'oculto'
    public detalle: EventEmitter<any> = new EventEmitter<any>()

    constructor() {}

    hideModal() {
        this.hidde = 'oculto'
    }

    showModal(foto: string) {
        this.detalle.emit(foto)
        this.hidde = ''
    }
}
