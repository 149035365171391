import { HttpClient } from '@angular/common/http'
import { Injectable, EventEmitter } from '@angular/core'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class MultasService {
    public multas: Array<any> = []
    public refresh: EventEmitter<any> = new EventEmitter<any>()

    constructor(public http: HttpClient) {}

    public GetMultas(): Observable<any> {
        const url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/GetMultas'
        return this.http.get(url).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    public getMultasByProyect() {
        let schema = JSON.parse(sessionStorage.getItem('LoginUsuario'))
        schema = schema.filter((element) => {
            return element.id_proyecto == sessionStorage.getItem('IdSelectProject')
        })

        const data = { schema: schema[0].cliente }
        const url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/GetMultasProyecto'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }

    public ingresarMulta(data: Object) {
        const url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/InsertarMulta'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                this.refresh.emit()
                return resp
            })
        )
    }

    public editarMulta(data: Object) {
        const url = environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/EditarMulta'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                this.refresh.emit()
                return resp
            })
        )
    }
}
