import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class VerDetalleService {
    public oculto: string = 'oculto'
    public _refresh = new Subject<void>()

    constructor() {}

    get refresh() {
        return this._refresh
    }

    hideModal() {
        this.oculto = 'oculto'
        return this._refresh.next()
    }

    showModal() {
        this.oculto = ''
    }
}
