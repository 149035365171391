import { Injectable } from '@angular/core'
import { SocketServiceService } from 'src/app/services/socket-service.service'

@Injectable({
    providedIn: 'root'
})
export class RecibirLlamadaService {
    public oculto: string = 'oculto'
    public dpto: any
    public user: any
    public id_usuario: any
    public id_sala: string
    // public user_data: any = JSON.parse(sessionStorage.getItem('identidad'))

    constructor() {} // public _socketService: SocketServiceService

    hideModal() {
        this.oculto = 'oculto'
    }

    showModal(element: any) {
        this.dpto = element.numero_bien
        this.user = element.nombre_remitente
        this.id_usuario = element.id_usuario_remitente
        this.id_sala = element.id_sala
        this.oculto = ''
        // let data = {
        //     id_usuario: this.user_data.id_login_usuario
        // }
        // this._socketService.estoyOcupado(data)
    }
}
