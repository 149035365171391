import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core'
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar'

import { addHours, endOfDay, isSameDay, startOfDay } from 'date-fns'
import { User } from './day-view-scheduler.component'
import { Subject, Subscription } from 'rxjs'
import { HorarioDiarioService } from 'src/app/modal/horario-diario/horario-diario.service'
import { AreasComunesService } from '../areas-comunes/areas-comunes.service'

import { DayData } from 'src/app/modal/horario-diario/horario-diario.component'
import * as moment from 'moment'
import Swal from 'sweetalert2'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { AlertService } from '@full-fledged/alerts'

let users: User[] = []

@Component({
    selector: 'mwl-demo-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./style.css'],
    templateUrl: 'template.html'
})
export class DemoComponent implements OnInit {
    viewDate = new Date()
    users = users
    tipo_bien = ''
    events: CalendarEvent[] = []
    refresh = new Subject<void>()
    $Cronograma: Subscription
    public dayData: DayData = { day: '', number_day: 0, available: false, reserva: [{ tipo_bien: '' }] }
    public $day: Subscription
    private id_proyecto: string = sessionStorage.getItem('IdSelectProject')
    public fecha: string = moment(moment()).format('YYYY-MM-DD')
    public numero_dia: moment.MomentInput = moment().isoWeekday()
    public bienes: Array<any> = []
    public todayNumber: number
    public areas = []
    public lengthAreas = 0
    public respAux: any
    public IsIntegracion: any = 0
    constructor(
        private _areasComunesService: AreasComunesService,
        private _horarioDiarioService: HorarioDiarioService,
        private globalServicesService: GlobalServicesService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.IsIntegracion = sessionStorage.getItem('Integracion')
        this.$Cronograma = this._horarioDiarioService.$DataCronograma.subscribe((resp: any) => {
            this.respAux = resp
            this.viewDate = new Date(moment(this.respAux.date).format('YYYY,MM,DD'))

            this.buildCronogram(this.respAux)
        })
    }

    private buildCronogram(resp: any) {

        this.users = users
        this.events = []
        let color: any = ''
        const data = { id_proyecto: this.id_proyecto, fecha: this.fecha, hora: '00:00' }
        this._areasComunesService.GetCronogramaCommonAreas(data).subscribe((respArea: any) => {
            this.bienes = []

            if (resp.reserva[0].tipo_bien) {
                this.bienes = respArea.filter((element) => {
                    this.tipo_bien = resp.reserva[0].tipo_bien
                    return element.tipo === resp.reserva[0].tipo_bien
                })
            } else {
                this.bienes = respArea
            }

            this.users = this.bienes.map((element) => {
                return {
                    id: element.id_bien,
                    name: element.numero_bien
                }
            })

            this.bienes.forEach((element) => {
                let reservas = element.reservas

                reservas.forEach((res) => {
                    if (res != null) {
                        let inicio = new Date(res.fecha_inicio + ' ' + res.hora_inicio)

                        if (res.id_estado == 2 || res.id_estado == 4 || res.id_estado == 3) {
                            color = {
                                primary: '#a7c835',
                                secondary: '#a7c835'
                            }
                        }

                        if (res.estado_area == 7 || res.id_estado == 1) {
                            color = {
                                primary: '#2bb8d6',
                                secondary: '#2bb8d6'
                            }
                        }

                        if (res.estado_area == 5) {
                            color = {
                                primary: '#fdb22b',
                                secondary: '#fdb22b'
                            }
                        }
                        /* if (res.numero_bien_usuario == "1412") { */
                        const momento1 = moment(inicio);
                        const momento2 = moment(res.fecha_real_salida);

                        const diferenciaEnHoras = momento2.diff(momento1, 'hours');
                        const fechaCon6Horas = moment(momento1).add(diferenciaEnHoras, 'hours').toDate(); //momento1.add(diferenciaEnHoras, 'hours');


                        /* } */
                        this.events.push({
                            title: `${res.nombre_usuario} <br> Depto ${res.numero_bien_usuario} <br> ${res.hora_inicio} - ${res.hora_fin}`,
                            cssClass: 'my-custom-class',
                            color: color,
                            start: inicio,
                            end: fechaCon6Horas,//addHours(inicio, moment.duration(res.hora_fin).asHours()),
                            meta: {
                                user: {
                                    id: element.id_bien,
                                    name: element.numero_bien,
                                    color: color,
                                    id_reserva: res.id_reserva
                                },
                                reserva: res
                            },
                            resizable: {
                                beforeStart: false,
                                afterEnd: false
                            },
                            draggable: false
                        })
                    }
                })


            })

            this.refresh.next()
        })
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        event.start = newStart
        event.end = newEnd
        this.events = [...this.events]
    }

    userChanged({ event, newUser }) {
        event.color = newUser.color
        event.meta.user = newUser
        this.events = [...this.events]
    }

    eventClicked({ event }) {
        this.Detalle(event.meta.reserva)
    }

    Detalle(item) {

        if (!item.considerar && item.estado_area == 1) {
            Swal.fire({
                title: 'Detalle de reserva',
                text: '',
                type: 'question',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor: '#a0c62a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Activar cobranza',
                confirmButtonClass: 'btn btn-modal-enviar',
                buttonsStyling: true,
                reverseButtons: true,
                html: `
            <div class="negrita color-negro lineh"> Detalle de la reserva </div>
            <div class="parrafo separacion">
                        <div>Fecha de reserva: <span class="negrita color-negro"> ${item.fecha_inicio}</span></div>
                        <div>Hora de inicio: <span class="negrita color-negro"> ${item.hora_inicio}</span></div>
                        <div>Hora de termino: <span class="negrita color-negro"> ${item.hora_fin}</span></div>
                        <div>Deparamento: <span class="negrita color-negro"> ${item.numero_bien_usuario}</span></div>
                        <div>Estado: <span class="negrita color-negro"> ${item.nombre_estado_area}</span></div>
                    </div>`
            } as any).then((result: any) => {
                if (result.value) {
                    this.FinalizarReserva(item)
                }
            })

            return
        }

        if (item.estado_area == 3) {
            Swal.fire({
                title: 'Detalle de reserva',
                text: '',
                type: 'question',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: 'Reenviar cobranza',
                cancelButtonText: 'Cerrar',
                buttonsStyling: true,
                reverseButtons: true,
                html: `
            <div class="negrita color-negro lineh"> Detalle de la reserva </div>
            <div class="parrafo separacion">
                        <div>Fecha de reserva: <span class="negrita color-negro"> ${item.fecha_inicio}</span></div>
                        <div>Hora de inicio: <span class="negrita color-negro"> ${item.hora_inicio}</span></div>
                        <div>Hora de termino: <span class="negrita color-negro"> ${item.hora_fin}</span></div>
                        <div>Deparamento: <span class="negrita color-negro"> ${item.numero_bien_usuario}</span></div>
                        <div>Estado: <span class="negrita color-negro"> ${item.nombre_estado_area}</span></div>
                    </div>`
            } as any).then((result: any) => {
                if (result.value) {
                    this.FinalizarReserva(item)
                }
            })

            return
        }

        if (item.estado_area == 5 || item.estado_area == 6) {
            Swal.fire({
                title: 'Finalizar reserva',
                text: '¿Desea finalizar la reserva en curso?',
                type: 'question',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonColor: '#a0c62a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Finalizar',
                confirmButtonClass: 'btn btn-modal-enviar',
                html: `
            <div class="negrita color-negro lineh"> Detalle de la reserva </div>
            <div class="parrafo separacion">
                        <div>Fecha de reserva: <span class="negrita color-negro"> ${item.fecha_inicio}</span></div>
                        <div>Hora de inicio: <span class="negrita color-negro"> ${item.hora_inicio}</span></div>
                        <div>Hora de termino: <span class="negrita color-negro"> ${item.hora_fin}</span></div>
                        <div>Deparamento: <span class="negrita color-negro"> ${item.numero_bien_usuario}</span></div>
                        <div>Estado: <span class="negrita color-negro"> ${item.nombre_estado_area}</span></div>
                    </div>`
            } as any).then((result: any) => {
                if (result.value) {
                    this.FinalizarReserva(item)
                }
            })
        }

        if (item.considerar && item.estado_area != 5 && item.estado_area != 6) {
            Swal.fire({
                title: '',
                text: ``,
                type: 'question',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor: '#a0c62a',
                html: `
            <div class="negrita color-negro lineh"> Detalle de la reserva </div>
            <div class="parrafo separacion">
                        <div>Fecha de reserva: <span class="negrita color-negro"> ${item.fecha_inicio}</span></div>
                        <div>Hora de inicio: <span class="negrita color-negro"> ${item.hora_inicio}</span></div>
                        <div>Hora de termino: <span class="negrita color-negro"> ${item.hora_fin}</span></div>
                        <div>Deparamento: <span class="negrita color-negro"> ${item.numero_bien_usuario}</span></div>
                        <div>Estado: <span class="negrita color-negro"> ${item.nombre_estado_area}</span></div>
                    </div>`,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Iniciar Reserva',
                cancelButtonText: 'Eliminar Reserva',
                confirmButtonClass: 'btn btn-modal-cancelar',
                cancelButtonClass: 'btn btn-modal-enviar',
                buttonsStyling: true,
                reverseButtons: true
            } as any).then((result: any) => {
                if (result.value) {
                    this.IniciarReserva(item)
                    
                } else if (result.dismiss == 'cancel') {
                    this.EliminarReserva(item)
                    

                }
            })
        }
    }

    async IniciarReserva(item) {
        let data = {
            id_reserva: item.id_reserva,
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje inicia la reserva correspondiente al depto ${item.numero_bien_usuario} registrada a las ${item.hora_inicio} hasta ${item.hora_fin} para ${item.fecha_inicio}`

        }
        this._areasComunesService.IniciarReserva(data).subscribe(
            (result) => {
                this.alertService.success("Reserva iniciada correctamente")
                this.buildCronogram(this.respAux)
            },
            (error) => { }
        )
    }

    async EliminarReserva(item) {
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_reserva: item.id_reserva,
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje elimina la reserva correspondiente al depto ${item.numero_bien_usuario} registrada a las ${item.hora_inicio} hasta ${item.hora_fin} para ${item.fecha_inicio}`
        }
        this._horarioDiarioService.EliminarReserva(data).subscribe((result) => {
            this.alertService.success("Reserva eliminada correctamente")
            this.buildCronogram(this.respAux)
        })
    }

    async FinalizarReserva(item) {

        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_reserva: item.id_reserva,
            fecha_salida: moment().format('YYYY-MM-DD'),
            hora_salida: moment().format('HH:mm'),
            id_estado: 3,
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje finaliza la reserva correspondiente al depto ${item.numero_bien_usuario} registrada a las ${item.hora_inicio} hasta ${item.hora_fin} para ${item.fecha_inicio}`
        }

        this._areasComunesService.FinalizarReserva(data).subscribe(
            (result) => {
                if (result.error == 0) {
                    this.alertService.success(result.message);
                }

                this.buildCronogram(this.respAux)
                if (this.IsIntegracion == 1) {
                    let dataReserva = {
                        id_proyecto: sessionStorage.getItem('IdSelectProject'),
                        id_reserva: item.id_reserva,
                        proyectoid: sessionStorage.getItem('externo_id')
                    }
                    this._areasComunesService.EnviarReservaLevel(dataReserva).subscribe((result) => {
                        if (result.error == 200) {
                            this.alertService.danger(result.message);
                        } else {
                            this.alertService.success(result.message);
                        }
                    })
                }
                //this.ngOnInit()
            },
            (error) => { }
        )
    }
}
