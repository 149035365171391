import * as moment from 'moment'

export interface AreasData {
    id_proyecto: string
    fecha: string
    numero_dia: moment.MomentInput
}

export interface AreasComunes {
    id_unico: string
    nombre_area: string
    codigo_ubicacion: number
    id_bien: number
    id_modelo: number | null
    id_dia: number | null
    id_estado: number | null
    tipo: null | string
    configurado: boolean
    configuraciones: Configuracione[]
    reservas: Reserva[]
    mantenciones: any[]
}

export interface Configuracione {
    id_dia: number | null
    hora_inicio: Hora | null
    hora_fin: Hora | null
    reserva: number
    horas_max: number | null
    bloque_horas: number | null
    valor_reserva: number | null
}

export enum Hora {
    The0000 = '00:00'
}

export interface Reserva {
    dia_entrada_area: string
    id_reserva: number
    hora_inicio: string
    hora_fin: string
    estado: number
    numero_bien: string
    patente: null | string
    id_login_usuario: number
    nombre_usuario: string
    tipo_bien?: string
}

export interface Multa {
    id?: number
    observacion?: string
    foto?: string
    fecha?: string
    numero_bien?: string
    multa?: string
    bien_id?: number
    multa_id?: number
    estado?: number
}
