export const environment = {
    production: true,
    versionCheckURL: '1.1',
    URL_SERVICIOS: 'https://apresi.bheed.net/',
    URL_SOCKET: 'https://apresi.bheed.net/',
    URL_SERVICIOS_ADMINISTRACION: 'https://apresm.bheed.net/',
    URL_FRAME: 'https://admin.buildingclerk.com/'
}

export const TimeZone = {
    chile: 'America/Santiago'
}
