import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class AreasComunesService {
    constructor(private http: HttpClient) {}

    public GetTotalEspaciosComunes(data): Observable<any> {
        data.id_proyecto = sessionStorage.getItem('IdSelectProject')
        return this.http
            .post(environment.URL_SERVICIOS + 'GetMblTotalEspaciosComunes', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public GetAreasComunes(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'GetAreasComunes', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public GetAreasComunesReservas(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/GetCommonAreas', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public GetCronogramaCommonAreas(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/GetCronogramaCommonAreas', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public GetCommonAreasByBien(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/GetCommonAreasByBien', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public GetCronograma(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'GetCronograma', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    /* public SetReservaTerminada(data): Observable<any> {
    data.id_proyecto = sessionStorage.getItem("IdSelectProject");
    return this.http
      .post(environment.URL_SERVICIOS +"setReservaTerminada", data)
      .map(response => {
        return response;
      }).catch((error: any) => {
        return Observable.throw(this.errorHandler(error))
      });
  } */

    public IniciarReserva(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/IniciarReserva', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public FinalizarReserva(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/FinalizarReserva', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    errorHandler(error: any): any {
        throw new Error('Method not implemented.')
    }

    public sendEmailArea(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'AreaComunEmail', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public BloquesReservas(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/CommonAreas/BloquesReservas', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public EnviarReservaLevel(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'level/AreasComunes/BuscarReservaLevel', data)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }
}
