import { Component, OnInit } from '@angular/core'
import { GestionarInvitadosService } from './gestionar-invitados.service'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-gestionar-invitados',
    templateUrl: './gestionar-invitados.component.html',
    styles: []
})
export class GestionarInvitadosComponent implements OnInit {
    constructor(public _gestionarInvitadoService: GestionarInvitadosService, private toastr: ToastrService) {}

    ngOnInit() {}

    closeModal() {
        this._gestionarInvitadoService.hideModal()
    }

    comming(llegada: Boolean, id_evento_visita: Number) {
        this._gestionarInvitadoService.guestIsHere(!llegada, id_evento_visita).subscribe((resp: any) => {
            this.toastr.info(resp.message, 'Información', {
                positionClass: 'toast-bottom-left',
                progressBar: true
            })
        })
    }

    setFilteredItems() {
        if (this._gestionarInvitadoService.searchTerm.length == 0) {
            this._gestionarInvitadoService.invitados_filtered = this._gestionarInvitadoService.invitados
        } else {
            this._gestionarInvitadoService.invitados_filtered = this.filterItems(
                this._gestionarInvitadoService.invitados,
                this._gestionarInvitadoService.searchTerm
            )
        }
    }

    filterItems(array: any, searchTerm: any) {
        return array.filter((item: any) => {
            return (
                item.nombre_invitado.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
                item.rut_invitado.toLowerCase().replace('-', '').replace('.', '').indexOf(searchTerm.toLowerCase()) > -1
            )
        })
    }
}
