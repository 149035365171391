import { OnDestroy, ViewChild } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { Session } from 'openvidu-browser'
import { forkJoin, Subject } from 'rxjs'
import { Subscription } from 'rxjs/internal/Subscription'
import { Multa } from 'src/app/interfaces/interface'
import { EditarMultaService } from 'src/app/modal/editar-multa/editar-multa.service'
import { MultasService } from 'src/app/services/multas/multas.service'
import { IngresarMultaService } from '../../modal/ingresar-multa/ingresar-multa.service'
import { VerDetalleMultaService } from '../../modal/ver-detalle-multa/ver-detalle-multa.service'
import { DataTableDirective } from 'angular-datatables'
import { AlertService } from '@full-fledged/alerts'
declare var swal: any
@Component({
    selector: 'app-multas',
    templateUrl: './multas.component.html',
    styleUrls: ['./multas.component.css']
})
export class MultasComponent implements OnInit, OnDestroy {
    public multas: Array<any> = []
    public $refresh: Subscription
    public tipoMultas: Array<any> = []
    public idMulta: any
    public searchTerm: any = []
    public IsIntegracion: any = 0
    dtOptions: any = {}
    encomiendaArray: any = []
    dtTrigger: Subject<any> = new Subject<any>()
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false

    constructor(
        public _ingresarMultasService: IngresarMultaService,
        public _multasService: MultasService,
        public _verDetalleService: VerDetalleMultaService,
        public _editarMultasService: EditarMultaService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
            order: [[4, 'desc']],
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel'
                } /* ,
				{
					extend: 'csvHtml5',
					text: '<i class="fas fa-file-csv"></i>',
					titleAttr: 'CSV'
				} */
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }
        this.IsIntegracion = sessionStorage.getItem('Integracion')
        this.GetMultas()
        this.$refresh = this._multasService.refresh.subscribe(() => {
            this.GetMultas()
        })
    }

    GetMultas() {
        forkJoin(this._multasService.getMultasByProyect(), this._multasService.GetMultas()).subscribe((resp: any) => {
            this.multas = resp[0]
            this._multasService.multas = resp[0]

            this.tipoMultas = resp[1]
            if (this.IsIntegracion == 1) {
                this.tipoMultas = this.tipoMultas.filter((element) => {
                    return element.externo_id != null
                })
            }
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
        })
    }

    insertMulta() {
        this._ingresarMultasService.showModal()
    }

    verDetalle(foto: string) {

        this._verDetalleService.showModal(foto)
    }

    public filtrar() {
        if (!this.idMulta) return

        this.multas = this.multas.filter((multa: any) => {
            return multa.multa === this.idMulta
        })
    }

    public reiniciarTabla() {
        this.multas = this._multasService.multas
        this.idMulta = undefined
    }

    setFilteredItems() {
        if (this.searchTerm == '') {
            this.multas = this._multasService.multas
        } else {
            this.multas = this.filterItems(this.multas, this.searchTerm)
        }
    }

    filterItems(array: any, searchTerm: any) {
        return array.filter((item) => {
            return (
                item.multa.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
                item.numero_bien.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
                item.observacion.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            )
        })
    }

    public editarMulta(multa: Multa) {

        if (multa.estado == 1) {
            this.alertService.warning('Las multas aprobadas no se puede modificar')
        } else {
            this._editarMultasService.showModal(multa)
        }
    }

    ngOnDestroy() {
        this.$refresh.unsubscribe()
    }
}
