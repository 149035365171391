import { NgModule } from '@angular/core'
import { LayoutModule } from '../layout/layout.module'
import { PagesComponent } from './pages.component'
import { VisitasComponent } from './visitas/visitas.component'
import { PAGES_ROUTES } from './pages.routes'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ChartsModule } from 'ng2-charts'
import { PipesModule } from '../pipes/pipes.module'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { CKEditorModule } from 'ckeditor4-angular'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ClickOutsideModule } from 'ng-click-outside'
import { NgxSpinnerModule } from 'ngx-spinner'

import { AreasComunesComponent } from './areas-comunes/areas-comunes.component'
import { BitacoraComponent } from './bitacora/bitacora.component'
import { IngresarBitacoraComponent } from '../modal/ingresar-bitacora/ingresar-bitacora.component'
import { VerDetalleComponent } from '../modal/ver-detalle/ver-detalle.component'
import { CitofoniaComponent } from './citofonia/citofonia.component'
import { SelectDropDownModule } from 'ngx-select-dropdown'
// import { RealizarLlamadaComponent } from '../modal/realizar-llamada/realizar-llamada.component';
// import { RealizarLlamadaPerdidaComponent } from '../modal/realizar-llamada-perdida/realizar-llamada-perdida.component';
import { EventosComponent } from './eventos/eventos.component'
import { GestionarInvitadosComponent } from '../modal/gestionar-invitados/gestionar-invitados.component'
import { CovidComponent } from './covid/covid.component'
import { EncomiendaComponent } from './encomienda/encomienda.component'
import { RecibirEncomiendaComponent } from '../modal/recibir-encomienda/recibir-encomienda.component'
import { EntregarEncomiendaComponent } from '../modal/entregar-encomienda/entregar-encomienda.component'
import { CalendarioComponent } from './calendario/calendario.component'
import { MultasComponent } from './multas/multas.component'
import { IngresarMultaComponent } from '../modal/ingresar-multa/ingresar-multa.component'
import { VerDetalleMultaComponent } from '../modal/ver-detalle-multa/ver-detalle-multa.component'
import { HorarioDiarioComponent } from '../modal/horario-diario/horario-diario.component'
import { EditarMultaComponent } from '../modal/editar-multa/editar-multa.component'
import { VisitantesComponent } from './visitantes/visitantes.component'
import { DataTablesModule } from 'angular-datatables'
import { EntregaLlavesComponent } from './entrega-llaves/entrega-llaves.component'

import { FullCalendarModule } from '@fullcalendar/angular' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { DemoComponent } from './day-view-scheduler/component'


import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { DemoUtilsModule } from './demo-utils/module'
import { DemoModule } from './day-view-scheduler/module'

FullCalendarModule.registerPlugins([
    // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin
])

@NgModule({
    declarations: [
        //DemoComponent,
        //DayViewSchedulerComponent,
        PagesComponent,
        VisitasComponent,
        AreasComunesComponent,
        BitacoraComponent,
        IngresarBitacoraComponent,
        VerDetalleComponent,
        CitofoniaComponent,
        IngresarMultaComponent,
        HorarioDiarioComponent,
        EventosComponent,
        GestionarInvitadosComponent,
        CovidComponent,
        EncomiendaComponent,
        RecibirEncomiendaComponent,
        EntregarEncomiendaComponent,
        CalendarioComponent,
        MultasComponent,
        VerDetalleMultaComponent,
        EditarMultaComponent,
        VisitantesComponent,
        EntregaLlavesComponent
        
    ],
    exports: [VisitasComponent, DemoComponent],
    imports: [
        NgxYoutubePlayerModule.forRoot(),
        DemoUtilsModule,
        DemoModule,
        LayoutModule,
        PAGES_ROUTES,
        FormsModule,
        ChartsModule,
        PipesModule,
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        CKEditorModule,
        NgbModule,
        SelectDropDownModule,
        ClickOutsideModule,
        NgxSpinnerModule,
        DataTablesModule,
        FullCalendarModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    
})
export class PagesModule {}
