import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class RealizarLlamadaService {
    public hide: string = 'oculto'
    public residente: string
    public dpto: string
    public id_usuario: number

    constructor() {}

    hideModal() {
        this.hide = 'oculto'
    }

    showModal(resident: any) {
        this.hide = ''
        this.residente = resident.nombre + resident.apellido
        this.dpto = resident.numero_bien
        this.id_usuario = resident.id_login_usuario
    }
}
