<div class="fondo-negro animated fadeIn" [ngClass]="_horarioDiarioService.oculto">
    <!-- <div class="fondo-negro animated fadeIn" > -->
    <div class="contenedor-modal-bitacora-detalle modal-multa">
        <div id="modal-bitacora-resumen">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header fondo-blanco">
                        <button (click)="closeModal()" class="close pointer posicion-x" type="button">
                            <img src="assets/images/x-verde.png" />
                        </button>
                    </div>

                    <div>
                        <div class="contenedor-modal-bitacora-simple modal-body">
                            <mwl-demo-component></mwl-demo-component>

                            <!-- <div>
								<div class="titulo-agenda">Reserva <span class="naranjo">{{ dayData.reserva }}</span> {{
									dayData.day }} {{ dayData.number_day }} </div>
								<div class="table-responsive">
									<div class="bloque-areas">
										<div class="listado">
											<ul>
												<li><span class="amarillo"></span>En uso</li>
												<li><span class="celeste"></span>Reservado</li>
											</ul>
										</div>
									</div>
									<table class="tabla-agenda " width="100%" border="0" cellspacing="0"
										cellpadding="0">
										<tbody>
											<tr>
												<td>&nbsp;</td>
												<ng-container *ngFor="let bien of bienes; let i = index">
													<td
														[ngClass]="[i === 0 ? 'fondo borde-izquierdo' : '', i > 0 && i < lengthAreas ? 'fondo' : '', i === lengthAreas ? 'fondo borde-derecho' : '' ]">
														{{ bien.nombre_area }}
													</td>
												</ng-container>
											</tr>
											<tr>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
											</tr>
											<tr *ngFor="let area of areas; let i = index">
												<ng-container *ngFor="let reserva of area; let j = index">
													<td class="hora borde-inferior {{ reserva.style.table }}">
														<div class="margen">{{ reserva.hora }}</div>
														<div (click)="Detalle(reserva)" *ngIf="reserva.ocupado"
															data-toggle="tooltip" data-placement="top"
															title="Hora inicio: {{ reserva.hora_inicio }}, Hora fin: {{ reserva.hora_fin }} "
															class="{{ reserva.style.capsule }}">
															<ng-container>
																{{ reserva.dpto }}
																<td>&nbsp;</td>
																{{ reserva.horario }}
																<div [innerHTML]="reserva.icon"></div>
															</ng-container>
														</div>
													</td>
												</ng-container>
											</tr>
										</tbody>
									</table>
								</div>
							</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
