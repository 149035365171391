import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { EventosService } from 'src/app/services/eventos/eventos.service'
import { Evento } from 'src/app/models/evento.model'
import { GestionarInvitadosService } from 'src/app/modal/gestionar-invitados/gestionar-invitados.service'
import { Subject, Subscription } from 'rxjs'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { DataTableDirective } from 'angular-datatables'

@Component({
    selector: 'app-eventos',
    templateUrl: './eventos.component.html',
    styles: []
})
export class EventosComponent implements OnInit, OnDestroy {
    public eventos: Array<Evento>
    public eventos_filtered: Array<Evento>
    public searchTerm: any = []
    public init_table: Boolean = false
    public updateTable$: Subscription
    title = 'angulardatatables'
    dtOptions: any = {}
    dtTrigger: Subject<any> = new Subject<any>()
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false

    constructor(
        public _eventosService: EventosService,
        public _gestionarInvitadoService: GestionarInvitadosService,
        public _socketService: SocketServiceService
    ) {}

    ngOnInit() {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
            order: [[0, 'desc']],
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel'
                } /* ,
				{
					extend: 'csvHtml5',
					text: '<i class="fas fa-file-csv"></i>',
					titleAttr: 'CSV'
				} */
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }

        this.updateTable$ = this._socketService.UpdateData$().subscribe(() => {
            this.GetEventos()
        })
        this.GetEventos()
    }

    GetEventos() {
        this._eventosService.getEvents().subscribe((resp: any) => {
            this.eventos = resp
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
        })
    }

    display_event(event: Evento) {
        this._gestionarInvitadoService.showModal(event)
    }

    ngOnDestroy() {
        this.updateTable$.unsubscribe()
    }
}
