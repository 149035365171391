<div class="fondo-negro animated fadeIn" [ngClass]="_gestionarInvitadoService.oculto">
    <div class="contenedor-modal-evento-simple">
        <div id="modal-evento">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Gestionar invitados</h4>
                        <button type="button" class="close pointer" (click)="closeModal()">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="leyend">
                            <div class="icon-green">
                                <i></i>
                            </div>
                            Asiste al evento
                            <div class="icon-red">
                                <i></i>
                            </div>
                            No asiste al evento
                            <input
                                (input)="setFilteredItems()"
                                [(ngModel)]="_gestionarInvitadoService.searchTerm"
                                class="search-guest"
                                type="text"
                                placeholder="Buscar invitado..."
                            />
                        </div>
                        <div class="finished-event" *ngIf="_gestionarInvitadoService.evento_finalizado">
                            El evento ya ha finalizado
                        </div>
                        <div *ngFor="let item of _gestionarInvitadoService.invitados_filtered" class="RNNXgb">
                            <div *ngIf="item.asiste">
                                <div class="icon-green">
                                    <i></i>
                                </div>
                            </div>
                            <div *ngIf="!item.asiste">
                                <div class="icon-red">
                                    <i></i>
                                </div>
                            </div>
                            <div class="">
                                {{ item.nombre_invitado + ' ' + item.apellido_invitado + ' - ' + item.rut_invitado }}
                            </div>
                            <div *ngIf="item.asiste" class="check-guest">
                                <div class="pretty p-icon p-round p-tada">
                                    <input
                                        *ngIf="_gestionarInvitadoService.evento_finalizado"
                                        type="checkbox"
                                        [(ngModel)]="item.llegada"
                                        disabled
                                    />
                                    <input
                                        *ngIf="!_gestionarInvitadoService.evento_finalizado"
                                        type="checkbox"
                                        [(ngModel)]="item.llegada"
                                        (click)="comming(item.llegada, item.id_eventos_visita)"
                                    />
                                    <div class="state p-success">
                                        <i class="icon mdi mdi-check"></i>
                                        <label class="subtitle-modal"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn cancel-btn pointer" (click)="closeModal()">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
