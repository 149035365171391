<div class="margen-lateral">
    <div class="bloque-reserva-botonera">
        <div (click)="insertMulta()" class="contenedor-botones-opciones-2">
            <input class="estilo-boton-multa pointer" type="button" value="INGRESAR MULTA" />
        </div>
        <!--     <div class="contenedor-selectores-bloque">
      <div class="contenedor-selector-area">
        <span class="titulo">Filtrar registros:</span>
        <select [(ngModel)]="idMulta" class="estilo-select-1 ng-untouched ng-pristine ng-valid">
          <option *ngFor="let tipo of tipoMultas" [value]="tipo.nombre">{{ tipo.nombre }}</option>
        </select>
      </div>
     
      <span (click)="filtrar()"  class="ico-ok pointer margen-btn btn-ticket"><img src="assets/images/ico-ticket.png"></span>&nbsp;&nbsp;
      <span (click)="reiniciarTabla()"  class="ico-ok pointer margen-btn btn-ticket"><img src="assets/images/boton-cerrar.jpg"></span>
      <div class="contenedor-btn-filtro">
        <button class="btn btn-modal-enviar pointer btn-filtro" type="button">Filtrar</button>
      </div>
      
      <div class="borrado"></div>
    </div> -->
        <div class="borrado"></div>
    </div>
</div>

<div class="contenedor-redondeado">
    <div class="bloque-tabla bloque-tabla-extra">
        <!--  <div class="msj">
      <span class="titulo color-gris">
        <b>Últimas multas emitidas:</b></span>
    </div> -->
        <!--  <div class="contenedor-buscador-central mb-4">
      <input (input)="setFilteredItems()" [(ngModel)]="searchTerm" class="input-buscador" placeholder="Buscar..." type="text">
      <span class="contenedor-lupa">
        <div class="lupa"></div>
      </span>
    </div> -->

        <div class="table-responsive">
            <table class="table table-bordered table-striped" [dtTrigger]="dtTrigger" datatable [dtOptions]="dtOptions">
                <thead>
                    <tr>
                        <th>
                            <div class="ico-estado-2">Fecha</div>
                        </th>
                        <th>
                            <div class="ico-estado-3">Tipo de Multa</div>
                        </th>
                        <th>
                            <div class="ico-estado-3">Observación</div>
                        </th>
                        <th>
                            <div class="ico-estado-1">Depto</div>
                        </th>
                        <th>
                            <div class="ico-estado-1">Detalle</div>
                        </th>
                        <th>
                            <div class="ico-estado-1">Editar</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!---->
                    <tr *ngFor="let multa of multas">
                        <td>{{ multa.fecha | date : 'shortDate' }}</td>
                        <td>{{ multa.multa }}</td>
                        <td>{{ multa.observacion }}</td>
                        <td>{{ multa.numero_bien }}</td>
                        <td>
                            <input
                                *ngIf="multa.foto != 'null'"
                                class="btn-verde-detalle pointer"
                                type="button"
                                value="FOTOGRAFÍA"
                                (click)="verDetalle(multa.foto)"
                            />
                        </td>
                        <td>
                            <input
                                class="btn-verde-detalle pointer"
                                type="button"
                                value="EDITAR MULTA"
                                (click)="editarMulta(multa)"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div class="texto-filtro">Mostrando registros del 1 al 6 de un total de 6 registros (filtrado de un total de 7
      registros)</div>
    <span class="contenedor-paginador">
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-end">
          <li class="page-item no-bold">
            <a class="page-link pointer">Anterior</a>
          </li>
          <li class="page-item">
            <a _ngcontent-bmm-c4="" class="page-link">1</a>
          </li>
          <li class="page-item no-bold">
            <a _ngcontent-bmm-c4="" class="page-link pointer">Siguiente</a>
          </li>
        </ul>
      </nav>
    </span> -->
    </div>
</div>

<div class="espaciado-fin"></div>

<app-ingresar-multa></app-ingresar-multa>
<app-ver-detalle-multa></app-ver-detalle-multa>
<app-editar-multa></app-editar-multa>
