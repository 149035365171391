<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#a0c62a" type="ball-scale-multiple">
    <p style="font-size: 20px; color: #a0c62a">Cargando...</p>
</ngx-spinner>

<div class="margen-lateral">
    <div class="bloque-reserva-botonera">
        <div class="contenedor-botones-opciones mod-responsive">
            <input
                class="estilo-boton-verde mod-margenes pointer"
                type="button"
                value="Correspondencia"
                (click)="ingresarEncomienda()"
            />
        </div>
        <div class="borrado"></div>
    </div>
</div>

<!--table-->
<div class="margen-lateral">
    <div class="bloque-redondeado">
        <div class="bloque-tabla">
            <div class="centered-title">
                <b>Ingresa el nombre, apellido o número de departamento</b><br />
                <div class="subtitle">Ingresa los datos para ver resultados de búsqueda</div>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped"
                    [dtTrigger]="dtTrigger"
                    datatable
                    [dtOptions]="dtOptions"
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="ico-estado-2">Nº Encomienda</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-2">Residente</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-3">Depto</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Tipo</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Procedencia</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Recepción</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Entrega</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Observacion</div>
                            </th>
                            <th scope="col">
                                <div class="ico-estado-1">Retira</div>
                            </th>
                            <th scope="col" width="15%;">
                                <div class="ico-estado-1">Estado encomienda</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let encomienda of encomiendaArray">
                            <td scope="row">{{ encomienda.id_encomienda }}</td>
                            <td>{{ encomienda.nombre_residente }}</td>
                            <td>{{ encomienda.numero_bien }}</td>
                            <td>{{ encomienda.nombre_encomienda }}</td>
                            <td>{{ encomienda.procedencia }}</td>
                            <td>{{ encomienda.fecha_recepcion }}</td>
                            <td>{{ encomienda.fecha_entrega }}</td>
                            <td>{{ encomienda.observacion }}</td>
                            <td>{{ encomienda.receptor }}</td>
                            <td (click)="giveCommission($event,encomienda)">
                                <div  *ngIf="encomienda.estado" class="contenedor-acciones">
                                        <img src="assets/images/entregado.png" alt="" title="Entregado" />
                                    
                                </div>
                                <div *ngIf="!encomienda.estado" class="contenedor-acciones">
                                        <img src="assets/images/recepcion.png" alt="" title="Recepcionado" />
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="espaciado-fin"></div>
<app-recibir-encomienda></app-recibir-encomienda>
<app-entregar-encomienda [encomienda]="encomienda"></app-entregar-encomienda>
