import { Component, OnInit, NgModule, ViewChild } from '@angular/core'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { Observable, Subject } from 'rxjs'
import { RecibirLlamadaService } from 'src/app/modal/recibir-llamada/recibir-llamada.service'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { RealizarLlamadaService } from 'src/app/modal/realizar-llamada/realizar-llamada.service'
import { ToastrService } from 'ngx-toastr'
import { RealizarLlamadaComponent } from 'src/app/modal/realizar-llamada/realizar-llamada.component'
import { RealizarLlamadaPerdidaComponent } from 'src/app/modal/realizar-llamada-perdida/realizar-llamada-perdida.component'
import { CitofoniaService } from 'src/app/services/citofonia/citofonia.service'
import { RealizarLlamadaPerdidaService } from 'src/app/modal/realizar-llamada-perdida/realizar-llamada-perdida.service'
import { DataTableDirective } from 'angular-datatables'
import { NgxSpinnerService } from 'ngx-spinner'
import * as moment from 'moment'
import 'moment/locale/es'
import { AlertService } from '@full-fledged/alerts'

@NgModule({
    providers: [{ provide: RealizarLlamadaComponent }, { provide: RealizarLlamadaPerdidaComponent }]
})
@Component({
    selector: 'app-citofonia',
    templateUrl: './citofonia.component.html',
    styles: [],
    providers: []
})
export class CitofoniaComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false
    dtTrigger: Subject<any> = new Subject<any>()
    //title = 'angulardatatables';
    dtOptions: any = {}
    public identidadUsuario = JSON.parse(sessionStorage.getItem('identidad'))
    public stream: any
    public peer: any
    public mypeerid: any
    public anotherid: any
    public init_page: number
    public final_page: number
    public registerPage: number
    public desde: number = 1
    public page: number = 1
    public totalRegistros: number = 0
    public llamada_entrante$: Observable<any>
    public usuarios_conectados$: Observable<any>
    public connected_users: any = []
    // public llamada_colgada$: Observable<any>;
    public dpto: string = ''
    public residentes: any = []
    public selected_resident: any = []
    public resident_to_send: any = []
    public llamadas: any = []
    public selected_resident_missing: any = []
    public llamada_perdida$: Observable<any>
    public refresh_table$: Observable<any>
    title = 'app'
    tab = 1

    multiSelect: any = []
    stringArray: any = []
    objectsArray: any = []

    config = {
        displayKey: 'dato_completo', // if objects array passed which key to be displayed defaults to description
        search: true,
        limitTo: 3
    }

    constructor(
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public _socketService: SocketServiceService,
        private _citofoniaService: CitofoniaService,
        public _recibirLlamadaService: RecibirLlamadaService,
        private _globalService: GlobalServicesService,
        public _realizarLlamadaService: RealizarLlamadaService,
        public _realizarLlamadaComponent: RealizarLlamadaComponent,
        public _realizarLlamadaPerdidaComponent: RealizarLlamadaPerdidaComponent,
        public _realizarLlamadaPerdidaService: RealizarLlamadaPerdidaService,
        public alertServices: AlertService
    ) {}

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe()
    }

    ngOnInit() {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
            //order: [[0, "desc"]],
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel'
                } /* ,
				{
					extend: 'csvHtml5',
					text: '<i class="fas fa-file-csv"></i>',
					titleAttr: 'CSV'
				} */
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }
        this.GetResidentes()
        this.llamada_perdida$ = this._socketService.LlamadaPerdida$()
        this.llamada_perdida$.subscribe((data) => {
            this.loadMissingCall()
        })
        this.refresh_table$ = this._citofoniaService.TableService$()
        this.refresh_table$.subscribe(() => {
            this.loadMissingCall()
        })
        this.loadMissingCall()
    }

    SaveSortOrder($event: any) {
        
        /* ... */
    }
    loadMissingCall() {
        // this._socketService.ConsultarUsuariosIniciados();
        this._citofoniaService.loadMissingCallPaginated().subscribe((resp: any) => {
            /* this.totalRegistros = resp[1][0].cantidad_llamadas; */
            this.llamadas = resp
            this.llamadas = this.llamadas.map((element) => {
                return {
                    ...element,
                    date: moment(element.fecha).format('yyyy-mm-dd, h:mm:ss a')
                }
            })
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
            this.spinner.hide()
        })
    }

    GuardarLlamada(item) {
        let data = {
            id_sistema: 1,
            fecha: moment().format('dddd, MMMM Do YYYY, HH:mm'),
            departamento: item.numero_bien ? item.numero_bien : item.departamento,
            id_login_usuario: this._globalService.getLocalData().id_login_usuario,
            recibida: 1,
            activo: 0,
            mensaje: item.dato_completo
                ? `Llamada desde conserjeria a: ${item.dato_completo}`
                : `Llamada desde conserjeria`,
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        this._citofoniaService.CreateLlamadaCitofonia(data).subscribe((resp: any) => {
          
        })
    }

    changeFrom(value: number, page: number) {
        let desde = this.desde + value - 1
        if (desde >= this.totalRegistros) {
            return
        }
        if (desde < 0) {
            return
        }
        this.page = this.page + page
        this.desde += value
        this.loadMissingCall()
    }

    GetResidentes() {
        let data = { id_proyecto: sessionStorage.getItem('IdSelectProject') }
        this._globalService.getResidentes(data).subscribe((resp: any) => {
            var residentes: Array<any> = []
            resp.forEach((user: any) => {
                if (user.id_login_usuario != this.identidadUsuario.id_login_usuario) {
                    user.dato_completo = user.nombre + ' ' + user.apellido + ' - ' + user.numero_bien
                    residentes.push(user)
                }
            })
            this.residentes = residentes
        })
    }

    makeCall() {
        if (this.selected_resident === undefined) {
            this.alertServices.danger('Debe seleccionar un residente para realizar la llamada')
            return
        }
        this.GuardarLlamada(this.selected_resident)
        this._socketService.ActiveSelectUser(this.selected_resident)
        this._realizarLlamadaService.showModal(this.selected_resident)
    }

    makeCallMiss(user: any) {
        this.selected_resident_missing = user
        this.GuardarLlamada(this.selected_resident_missing)
        this._socketService.ActiveSelectMissingUser(this.selected_resident_missing)
        this._realizarLlamadaPerdidaService.showModal(this.selected_resident_missing)
    }
}
