import { Component, OnInit } from '@angular/core'
import { IngresarBitacoraService } from './ingresar-bitacora.service'
import { BitacoraService } from 'src/app/services/bitacora/bitacora.service'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { Bitacora } from 'src/app/models/bitacora.model'
import { ToastrService } from 'ngx-toastr'
import * as moment from 'moment'
import { AlertService } from '@full-fledged/alerts'

@Component({
    selector: 'app-ingresar-bitacora',
    templateUrl: './ingresar-bitacora.component.html',
    styles: []
})
export class IngresarBitacoraComponent implements OnInit {
    public oculto: string = 'oculto'
    public title: any = 0
    public selectedValue: number = 10
    public selectedTorre: number = 0
    public comentary: string
    public title_text: string
    public modulo: any = 15 //0;
    public fecha: boolean = false
    public fecha_termino: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public hora_actual: any = moment().format('HH')
    public minuto_actual: any = moment().format('mm')
    public horas: Array<any> = []
    public minutos: Array<any> = []
    public torres: Array<any> = []
    public selected_hour: any
    public selected_minute: any

    constructor(
        public _ingresarBitacoraService: IngresarBitacoraService,
        private _binnacleService: BitacoraService,
        private _globalService: GlobalServicesService,
        private toastr: ToastrService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.GetTorres()
        this.selected_hour = this.hora_actual
        this.selected_minute = this.minuto_actual
        for (let i: any = this.hora_actual; i <= 23; i++) {
            if (i.toString(10).length == 1) {
                i = '0' + i
            }
            this.horas.push(i.toString(10))
        }
        for (let i: any = this.minuto_actual; i <= 60; i++) {
            if (i.toString(10).length == 1) {
                i = '0' + i
            }
            this.minutos.push(i)
        }
    }

    closeModal() {
        this._ingresarBitacoraService.hideModal()
        this.resetModal()
    }

    createBinnacle() {
        var title: any
        let fecha: any
        if (this.title === 0) {
            this.alertService.danger('Debe seleccionar un tipo de Bitácora')
            return
        }

        if (this.selectedTorre === 0) {
            this.alertService.danger('Debe seleccionar una Torre')
            return
        }
        if (this.modulo === 0) {
            this.alertService.danger('Debe seleccionar un módulo')
            return
        }

        if (this.title == 1) {
            title = this.title_text
        } else {
            title = this.title
        }

        if (title === undefined) {
            this.alertService.danger('Error inesperado, refresque su navegador o contacte con su administrador')
            return
        }
        if (this.fecha) {
            if (this.fecha_termino.month.toString().length == 1)
                this.fecha_termino.month = '0' + this.fecha_termino.month
            fecha =
                this.fecha_termino.year.toString(10) +
                '-' +
                this.fecha_termino.month.toString(10) +
                '-' +
                this.fecha_termino.day.toString(10) +
                ' ' +
                this.selected_hour.toString(10) +
                ':' +
                this.selected_minute.toString(10)
        } else {
            fecha = moment().format('YYYY-MM-DD HH:mm')
        }
        let localData = this._globalService.getLocalData()

        var binnacle = {
            id_login_usuario: localData.id_login_usuario,
            texto: title,
            id_estado: this.selectedValue,
            descripcion: this.comentary,
            id_sistema: 1,
            id_accion: 12,
            id_contexto: null,
            id_modulo: 15,
            fecha: fecha,
            id_bitacora: null,
            id_etapa: this.selectedTorre
        }

        this._binnacleService.createBinnacle(binnacle).subscribe((resp: any) => {
            if (resp) {
                this._binnacleService.refreshBinnacleCount()
                this._ingresarBitacoraService.oculto = 'oculto'
                this.toastr.success(resp.message, 'Creado', {
                    positionClass: 'toast-bottom-left',
                    progressBar: true
                })
                this.resetModal()
            }
        })
    }

    private resetModal() {
        this.title = 0
        this.selectedValue = 10
        this.modulo = 15 //0;
        this.comentary = ''
        this.fecha = false
    }

    GetTorres() {
        this._binnacleService.GetTorres().subscribe((resp: any) => {
            this.torres = resp
            this.torres.push({ id_etapa: 0, nombre_etapa: 'Comunidad' })
        })
    }
}
