export class Visita {
    constructor(
        public id_visita: String,
        public rut: string,
        public nombre: string,
        public apellido: string,
        public fecha: Date,
        public hora: string,
        public id_tipo_visita: Number,
        public id_estado: String,
        public id_login_usuario: String,
        public estacionamiento: Number,
        public id_tipo_visita_temporal: Number,
        public recepcion: Number,
        public correo: string,
        public eliminado: Number,
        public id_bien: String,
        public patente: String,
        public id_proyecto?: String
    ) {}
}
