import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Notificacion, Correo, Difusion } from '../models/notificacion.model'
import * as moment from 'moment'
import 'moment-timezone'
moment.locale('es')
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class GlobalServicesService {
    constructor(private http: HttpClient) {}

    public getResidentes(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Proyect/ListUsuariosProyecto', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public getBienesByUser(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'getBienesByUser', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public getBienesByProyecto(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'getBienesByProyecto', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public getUserBybienes(data): Observable<any> {
        data.id_proyecto = sessionStorage.getItem('IdSelectProject')
        return this.http
            .post(environment.URL_SERVICIOS + 'getUserBybienes', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public getVisitas(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'getVisitas', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public enviarNotificacion(notificacion: Notificacion): Observable<any> {
        notificacion.fecha = moment().tz(sessionStorage.getItem('TimeZone'))
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Residentes/OtherNotification', notificacion)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public envioNotificacionBkCitofonia(notificacion: any): Observable<any> {
        notificacion.fecha = moment().tz(sessionStorage.getItem('TimeZone'))
        return this.http
            .post(environment.URL_SERVICIOS + 'EnvioNotificacionBkCitofonia', notificacion)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public enviarCorreo(correo: Correo): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'enviarCorreo', correo)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public enviarDifusion(difusion: Difusion): Observable<any> {
        difusion.fecha = moment().tz(sessionStorage.getItem('TimeZone'))
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Residentes/ProyectNotification', difusion)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public enviarEmergencia(difusion: Difusion): Observable<any> {
        difusion.fecha = moment().tz(sessionStorage.getItem('TimeZone'))
        difusion.emergencia = true
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'buildingclerk/Residentes/ProyectNotification', difusion)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    getUniqueValuesOfKey(arr, key) {
        const unique = arr
            .map((e) => e[key])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => arr[e])
            .map((e) => arr[e])

        return unique
    }

    errorHandler(error: any): any {
        throw new Error('Method not implemented.')
    }

    public getLocalData() {
        return JSON.parse(sessionStorage.getItem('identidad'))
    }

    getCountBinnacle() {
        let id_proyecto = sessionStorage.getItem('IdSelectProject')
        let data = {
            id_proyecto: id_proyecto
        }
        let url = environment.URL_SERVICIOS + 'getCountBinnacle'
        return this.http.post(url, data).map((resp: any) => {
            return resp
        })
    }

    getLogedUserId() {
        return JSON.parse(sessionStorage.getItem('identidad'))
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD hh:mm')
    }

    async cleanRut(rut) {
        return typeof rut === 'string' ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() : ''
    }

    async validateRut(rut) {
        return new Promise(async (resolve) => {
            rut = await this.cleanRut(rut)

            var t = parseInt(rut.slice(0, -1), 10)
            var m = 0
            var s = 1

            while (t > 0) {
                s = (s + (t % 10) * (9 - (m++ % 6))) % 11
                t = Math.floor(t / 10)
            }

            var v = s > 0 ? '' + (s - 1) : 'K'
            if (v === rut.slice(-1)) {
                rut = await this.formatRut(rut)
                
                resolve(rut)
            } else {
                resolve(false)
            }
        })
    }

    async dvRut(T) {
        var M = 0,
            S = 1
        for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11
        return S ? S - 1 : 'K'
    }

    async formatRut(rut) {
        rut = await this.cleanRut(rut)

        var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
        for (var i = 4; i < rut.length; i += 3) {
            result = rut.slice(-3 - i, -i) + '.' + result
        }
        return result
    }
}
