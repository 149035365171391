<div class="fondo-negro-2 animated fadeIn" [ngClass]="_ingresarMultaService.oculto">
    <div class="contenedor-modal-bitacora-detalle modal-multa">
        <div id="modal-bitacora-resumen">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Creación de Multa</h4>
                        <button (click)="closeModal()" class="close pointer" type="button">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>

                    <div>
                        <div class="contenedor-modal-bitacora-simple modal-body">
                            <div class="separador">
                                <div class="contenedor-selector-izq">
                                    <div class="titulo-modal">Número de Bien</div>
                                    <select [(ngModel)]="numeroBien" class="estilo-select-1 fondo-blanco txt-campo">
                                        <option *ngFor="let dpto of dptos" [value]="dpto.id_bien">
                                            {{ dpto.numero_bien }}
                                        </option>
                                    </select>
                                </div>
                                <div class="contenedor-selector-der">
                                    <div class="titulo-modal">Fecha</div>
                                    <input
                                        (click)="d.toggle()"
                                        autocomplete="off"
                                        placeholder="yyyy-mm-dd"
                                        name="dp"
                                        [(ngModel)]="fechaSelected"
                                        [maxDate]="fechaMaxDate"
                                        [ngModelOptions]="{ standalone: true }"
                                        ngbDatepicker
                                        #d="ngbDatepicker"
                                        class="estilo-input-1"
                                        type="text"
                                    />
                                    <select [(ngModel)]="hora" class="estilo-input-hora" type="text">
                                        <option *ngFor="let hora of horas">{{ hora }}</option></select
                                    >:
                                    <select [(ngModel)]="minuto" class="estilo-input-hora" type="text">
                                        <option *ngFor="let minuto of minutos">{{ minuto }}</option>
                                    </select>
                                </div>

                                <div class="titulo-modal">Tipo de Multa</div>
                                <select [(ngModel)]="idMulta" class="estilo-select-1 fondo-blanco txt-campo">
                                    <option *ngFor="let multa of multas" [value]="multa.id">
                                        {{ multa.nombre }}
                                    </option>
                                </select>

                                <div class="titulo-modal">Observación</div>
                                <textarea
                                    [(ngModel)]="observacion"
                                    class="textarea fondo-blanco"
                                    cols="10"
                                    placeholder="{{ textAreaPlaceholder }}"
                                    rows="5"
                                    (focus)="clearPlaceHolder()"
                                    (blur)="onBlur()"
                                ></textarea>

                                <div class="btn-subir-multa">
                                    <input type="file" (change)="handleFileSelect($event)" />
                                    <!-- <a href="#">Subir Imagen</a> -->
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button (click)="closeModal()" class="btn btn-modal-cancelar pointer" type="button">
                                Cancelar
                            </button>
                            <button (click)="crearMulta()" class="btn btn-modal-enviar pointer" type="button">
                                Crear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
