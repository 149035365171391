<head>
    <title>Building Clerk</title>
</head>

<header class="contenedor-header">
    <div class="container-fluid">
        <div class="row.no-flex">
            <div class="nombre-proyecto">Proyecto: {{ nombre_proyecto }}</div>
            <div class="nombre-proyecto">Usuario: {{ nombre_usuario.nombre }} {{ nombre_usuario.apellido }}</div>

            <div class="bloque-cuenta">
                <div class="contenedor-login">
                    <div class="cuenta">
                        <p>Salir</p>
                    </div>
                    <img
                        class="img-login"
                        src="assets/images/img-login-medio.png"
                        alt=""
                        title=""
                        (click)="closeSession()"
                    />
                </div>
            </div>
            <div class="bloque-logo-izquierda">
                <a href="#"><img src="assets/images/logo.jpg" alt="" title="" /></a>
            </div>

            <div class="nombre-proyecto-mobile">Proyecto: {{ nombre_proyecto }}</div>

            <div class="nombre-proyecto-mobile">Usuario: {{ nombre_usuario.nombre }} {{ nombre_usuario.apellido }}</div>

            <div class="borrado-2"></div>
            <div class="bloque-central-header">
                <!-- <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top"> -->
                <nav class="navbar navbar-expand-lg navbar-light">
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarsExampleDefault"
                        aria-controls="navbarsExampleDefault"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="contenedor-botones collapse navbar-collapse" id="navbarsExampleDefault">
                        <div class="contenedor-botones">
                            <ul>
                                <li><a routerLink="/visitas" routerLinkActive="active">Residentes</a></li>
                                <li><a routerLink="/visitantes" routerLinkActive="active">Visitantes</a></li>
                                <li><a routerLink="/areas" routerLinkActive="active">Áreas </a></li>
                                <li>
                                    <a routerLink="/citofonia" routerLinkActive="active"
                                        >Citofonía
                                        <!-- <span
                                            [ngClass]="{'animated infinite bounce delay-2s': binnacle_count > 0}">{{
                                            missing_call_count }}</span> --></a
                                    >
                                </li>
                                <li>
                                    <a routerLink="/bitacora" routerLinkActive="active"
                                        >Bitácora
                                        <span [ngClass]="{ 'animated infinite bounce delay-2s': binnacle_count > 0 }">{{
                                            binnacle_count
                                        }}</span></a
                                    >
                                </li>
                                <li><a routerLink="/covid" routerLinkActive="active">Covid-19</a></li>
                                <li><a routerLink="/encomienda" routerLinkActive="active">Encomiendas</a></li>
                                <li><a routerLink="/multas" routerLinkActive="active">Multas</a></li>
                                <!-- <li><a routerLink="/entrega-llaves" routerLinkActive="active">Entrega Llaves</a></li> -->
                                <li class="redondeo-final">
                                    <a routerLink="/eventos" routerLinkActive="active">Eventos</a>
                                </li>

                                <li class="login-menu">
                                    <a routerLink="/eventos" routerLinkActive="active "
                                        >Mi cuenta
                                        <img
                                            class="img-login"
                                            src="assets/images/img-login-medio.png"
                                            alt=""
                                            title=""
                                            (click)="closeSession()"
                                    /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="borrado"></div>
        </div>
    </div>
</header>

<app-recibir-llamada></app-recibir-llamada>
<app-realizar-llamada></app-realizar-llamada>
<app-realizar-llamada-perdida></app-realizar-llamada-perdida>
