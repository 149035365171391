import { Component, OnInit } from '@angular/core'
import * as moment from 'moment'
import 'moment/locale/es'
import { AreasComunes, AreasData } from 'src/app/interfaces/interface'
import { AreasComunesService } from '../areas-comunes/areas-comunes.service'
import { Reserva } from '../../interfaces/interface'
import { NgxSpinnerService } from 'ngx-spinner'
import { trigger, state, style, animate, transition } from '@angular/animations'
import { HorarioDiarioService } from 'src/app/modal/horario-diario/horario-diario.service'
import { Observable } from 'rxjs'
import { CalendarOptions } from '@fullcalendar/angular' // useful for typechecking
import Eslocale from '@fullcalendar/core/locales/es'
import Swal, { SweetAlertOptions } from 'sweetalert2'

@Component({
    selector: 'app-calendario',
    templateUrl: './calendario.component.html',
    styleUrls: ['./calendario.component.css'],
    animations: [
        trigger('popOverState', [
            state(
                'show',
                style({
                    opacity: 1
                })
            ),
            state(
                'hide',
                style({
                    opacity: 0
                })
            ),
            transition('show => hide', animate('600ms ease-out')),
            transition('hide => show', animate('600ms ease-out'))
        ])
    ]
})
export class CalendarioComponent implements OnInit {
    public events: Array<any> = []
    public getReservas$: Observable<any>
    private id_proyecto: string = sessionStorage.getItem('IdSelectProject')
    public cargando: boolean = true
    public numero_dia: moment.MomentInput = moment().isoWeekday()
    public fecha: string = moment(moment()).format('YYYY-MM-DD')
    public reservedAreas: Array<any>
    public showAreas: boolean = false
    public selectedDay: number
    public areaSelected: string = 'default'
    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',

        eventColor: 'green',
        eventClick: (info) => {
            info.el.style.borderColor = 'red'
            let AuxObj: any = { day: '', number_day: '', available: true, reserva: [] }
            let reservastemp = []
            this.reservedAreas.forEach((area: AreasComunes) => {
                area.reservas.forEach((reservatemp: any) => {
                    if (reservatemp.fecha_inicio == info.event.startStr) {
                        AuxObj.day = moment(info.event.startStr).format('dddd')
                        AuxObj.number_day = parseInt(moment(info.event.startStr).format('D'))
                        AuxObj.reserva.push(reservatemp)
                    }
                })
            })
            this.showHourModal(AuxObj)
        },
        locale: Eslocale,
        events: this.events
    }
    constructor(
        private _areasComunesService: AreasComunesService,
        private spinner: NgxSpinnerService,
        private _horarioDiarioService: HorarioDiarioService
    ) {}

    get stateName() {
        return this.showAreas ? 'show' : 'hide'
    }

    handleDateClick(arg) {
        alert('date click! ' + arg.dateStr)
    }

    public toggle(selectedDay: any) {
        this.selectedDay = selectedDay.number_day
        this.showAreas = !this.showAreas
    }

    ngOnInit() {
        this.filterAreas()
        this.getReservas$ = this._horarioDiarioService.ObsReservas$()
        this.getReservas$.subscribe(async () => {
            this.filterAreas()
        })
    }

    public showHourModal(selectedDay: any) {
        
        this._horarioDiarioService.showModal(selectedDay)
    }

    RestartCalendar() {
        this.events = []
        this.calendarOptions = {
            initialView: 'dayGridMonth',
            eventColor: 'green',
            eventClick: (info) => {
                info.el.style.borderColor = 'red'
                let AuxObj: any = { day: '', number_day: '', available: true, reserva: [] }
                this.reservedAreas.forEach((area: AreasComunes) => {
                    area.reservas.forEach((reservatemp: any) => {
                        if (reservatemp.fecha_inicio == info.event.startStr) {
                            AuxObj.day = moment(info.event.startStr).format('dddd')
                            AuxObj.number_day = parseInt(moment(info.event.startStr).format('D'))
                            AuxObj.date = info.event.startStr
                            AuxObj.reserva.push(reservatemp)
                        }
                    })
                })
                
                this.showHourModal(AuxObj)
            },
            locale: Eslocale,
            events: this.events
        }
    }

    filterAreas() {
        this.RestartCalendar()
        this.reservedAreas = []
        this.allocateReservationsForTheMonth()
    }

    /**
     * Asigna las reservas para el mes correspondiente
     */
    allocateReservationsForTheMonth() {
        this.RestartCalendar()
        this.spinner.show()
        const data = { id_proyecto: this.id_proyecto, fecha: this.fecha, hora: '00:00' }
        this._areasComunesService.GetCronogramaCommonAreas(data).subscribe(
            (resp: Array<AreasComunes>) => {
                this.reservedAreas = resp.filter((area: AreasComunes) => {
                    area.reservas = area.reservas.filter((reserva: Reserva) => {
                        /* if(this.areaSelected == "default"){
                            return reserva
                        }else{ */
                        if (reserva != null) {
                            reserva.tipo_bien = area.tipo
                            return this.areaSelected === reserva.tipo_bien
                        }

                        /*  } */
                    })
                    return area.reservas.length
                })

                this.reservedAreas.forEach((area: any) => {
                    area.reservas.forEach((reserva: any) => {
                        let color = ''
                        
                        if (reserva.id_estado == 2 || reserva.id_estado == 4 || reserva.id_estado == 3) {
                            color = '#a7c835'
                        }
                        if (reserva.id_estado == 1) {
                            color = '#2bb8d6'
                        }

                        if (reserva.id_estado == 5) {
                            color = '#fdb22b'

                            /* let time = {
                                days: 0, hours: 0, minutes: 0, seconds: 0
                            };

                            let finishDate: any = new Date(moment(reserva.fecha_real_salida).format('YYYY-MM-DD HH:mm'));
                            const now = new Date();
                            const diff = finishDate.getTime() - now.getTime();
                            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                            const hours = Math.floor(diff / (1000 * 60 * 60));
                            const mins = Math.floor(diff / (1000 * 60));
                            const secs = Math.floor(diff / 1000);
                            time.days = days;
                            time.hours = hours - days * 24;
                            time.minutes = mins - hours * 60;
                            time.seconds = secs - mins * 60;

                            if (time.hours > 0 && time.minutes > 0 && time.seconds > 0) {
                                reserva.estado_area = 6
                                color = "#d32929"
                            } */
                        }

                        this.events.push({
                            title: `AC ${area.numero_bien} - DEPTO ${reserva.numero_bien_usuario}`,
                            start: reserva.fecha_inicio,
                            historico: false,
                            color: color
                        })
                    })
                })

                this.spinner.hide()
            },
            (error: Error) => {
                console.error('Error request GetAreasComunes', error)
            }
        )
    }
}
