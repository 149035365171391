import { Component } from '@angular/core'
import { TimeZone } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import Swal from 'sweetalert2'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: []
})
export class AppComponent {
    private config: {
        version: string
    }

    constructor(private httpClient: HttpClient) {}

    ngOnInit() {
        sessionStorage.setItem('TimeZone', TimeZone.chile)
        this.config = require('./../assets/config.json')
        
        this.getVersionServer()
    }

    showNotification() {}

    getVersionServer() {
        const headers = new HttpHeaders().set('Cache-Control', 'no-cache').set('Pragma', 'no-cache')

        this.httpClient
            //.get<{ version: string }>("/conserje/assets/config.json", {headers})
            .get<{ version: string }>('/assets/config.json', { headers })
            .subscribe((config) => {
                if (config.version !== this.config.version) {
                    Swal.fire({
                        title: 'Nueva versión disponible',
                        text: '¿Dese actualizar a la nueva versión?',
                        type: 'question',
                        showCloseButton: true,
                        showCancelButton: false,
                        confirmButtonColor: '#a0c62a',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar',
                        confirmButtonClass: 'btn btn-modal-enviar'
                    } as any).then((result: any) => {
                        if (result.value) {
                            window.location.reload()
                        }
                    })
                }
            })
    }
}
