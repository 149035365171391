import { Component, Input, OnInit } from '@angular/core'
import { AreasComunesService } from './areas-comunes.service'
import { GlobalServicesService } from '../../services/global-services.service'
import { AreasService } from '../../services/areas/areas.service'
import { NgxSpinnerService } from 'ngx-spinner'
import * as moment from 'moment'
import 'moment-duration-format'
import Swal from 'sweetalert2'
import { Notificacion } from 'src/app/models/notificacion.model'
import { HorarioDiarioService } from 'src/app/modal/horario-diario/horario-diario.service'
import { AlertService } from '@full-fledged/alerts'

declare var $: any
declare var swal: any
moment.locale('es')
moment.updateLocale('es', {
    relativeTime: {
        past: function (input) {
            return input === 'just now' ? input : input + ' ago'
        },
        s: 'just now',
        future: '%s'
    }
})
@Component({
    selector: 'app-areas-comunes',
    templateUrl: './areas-comunes.component.html',
    styles: []
})
export class AreasComunesComponent implements OnInit {
    public Horarios_Reservas: any = []
    public IsIntegracion: any = 0
    public total_estacionamientos: any = 0
    public total_quinchos: any = 0
    public total_lavadoras: any = 0
    public total_secadoras: any = 0
    public total_piscina: any = 0
    public total_lavanderia: any = 0
    public total_salon_gourmet: any = 0
    public total_salon_eventos: any = 0
    public total_salones: any = 0
    public total_ascensores: any = 0
    public aux: boolean = false
    public NombreAreaSelected: any = ''
    public idBienSelected: any = ''
    public id_login_usuario = this.globalServicesService.getLocalData().id_login_usuario
    public id_proyecto = sessionStorage.getItem('IdSelectProject')
    public ProjectSelect = JSON.parse(sessionStorage.getItem('LoginUsuario'))
    public id_bien = this.ProjectSelect.filter((element) => element.id_proyecto == this.id_proyecto)[0].id_bien
    /* Var areas*/
    public areaSelected: string = 'ESTACIONAMIENTO VISITA'
    public fechaSelected: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public fechaMinDate: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment().format('DD'))
    }
    public fechaMaxDate: any = {
        year: parseInt(moment().format('YYYY')),
        month: parseInt(moment().format('MM')),
        day: parseInt(moment(moment()).endOf('month').format('DD'))
    }
    public areas_actuales: any = []
    public fecha_reserva: any = ''
    public hora_inicio: any = ''
    public hora_fin: any = ''
    public hora_critica: boolean = false
    public emitir_mensaje: any = false
    public usuario_emitir_mensaje: any
    public Mensaje: any
    public ObjReservaActual: any = {
        nombre_area: '',
        fecha_reserva: '',
        hora_reserva: '',
        hora_fin: ''
    }
    public boton_reservas: boolean = true
    public area_comun: any = [
        {
            nombre_area: '',
            hora_entrada: '',
            dia_entrada_area: '',
            estado: '',
            horas: '',
            RelojArea: '',
            progreso: false,
            id_estado: ''
        }
    ]

    public Horas: any
    public HorasSelect: any
    public MinutosSelect: any
    public horaSelected: any
    public minutosSelected: any
    public ObjReserva: any = {}
    public FechaModalSelect: any
    public ArrayBienes: any = []
    public selectedValueBien: any = 0
    public selectedValueHorario: any = 0
    public selectedValueUsuario: any = 0
    public areaSelectedMantencion: any = 0
    public zonaSelectedMantencion: any = 0
    public inputPatente: any
    public ArrayUsuarios: any = []
    public zonas: any[]
    public horaInicioMantencion
    public fechaInicioMantencion
    public horaFinMantencion
    public fechaFinMantencion
    public observacion: any

    public notificacion = new Notificacion(null, 'conserje', '', '')
    public valor_reserva: any
    /* Var areas*/
    /*Count Down*/
    time!: {
        days: number
        hours: number
        minutes: number
        seconds: number
    }
    @Input() finishDateString: string = ''
    finishDate: Date = new Date()
    /*Count Down*/

    constructor(
        private areascomunesService: AreasComunesService,
        private globalServicesService: GlobalServicesService,
        private areasService: AreasService,
        public _horarioDiarioService: HorarioDiarioService,
        private spinner: NgxSpinnerService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        let self = this
        this.IsIntegracion = sessionStorage.getItem('Integracion')
        self.GetTotalEspacios()
        self.GetBienesByProyecto()
        self.GetAreaComun()
    }

    GetBienesByProyecto() {
        let data = { id_proyecto: this.id_proyecto }

        this.globalServicesService.getBienesByProyecto(data).subscribe(
            (result) => {
                this.ArrayBienes = result
                this.ArrayBienes.sort((a, b) => (a.numero_bien < b.numero_bien ? -1 : 1))
            },
            (error) => {
                // this.globalService.dismissLoading();
            }
        )
    }

    GetTotalEspacios() {
        let fecha = moment(moment()).format('YYYY-MM-DD')
        let hora = moment().format('LT')
        let data = { id_bien: this.id_bien, fecha: fecha, hora: hora }

        this.areascomunesService.GetTotalEspaciosComunes(data).subscribe(
            (result) => {
                let cantidad = result

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Piscina')[0] == null) {
                    this.total_piscina = 0
                } else {
                    this.total_piscina = cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Piscina')[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Quinchos')[0] == null) {
                    this.total_quinchos = 0
                } else {
                    this.total_quinchos = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Quinchos'
                    )[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Estacionamientos')[0] == null) {
                    this.total_estacionamientos = 0
                } else {
                    this.total_estacionamientos = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Estacionamientos'
                    )[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Secadoras')[0] == null) {
                    this.total_secadoras = 0
                } else {
                    this.total_secadoras = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Secadoras'
                    )[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Lavadoras')[0] == null) {
                    this.total_lavadoras = 0
                } else {
                    this.total_lavadoras = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Lavadoras'
                    )[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Salon Gourmet')[0] == null) {
                    this.total_salon_gourmet = 0
                } else {
                    this.total_salon_gourmet = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Salon Gourmet'
                    )[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Salon de eventos')[0] == null) {
                    this.total_salon_eventos = 0
                } else {
                    this.total_salon_eventos = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Salon de eventos'
                    )[0].total
                }

                if (cantidad.filter((cantidad) => cantidad.codigo_ubicacion == 'Ascensores')[0] == null) {
                    this.total_ascensores = 0
                } else {
                    this.total_ascensores = cantidad.filter(
                        (cantidad) => cantidad.codigo_ubicacion == 'Ascensores'
                    )[0].total
                }

                this.total_salones = this.total_salon_eventos + this.total_salon_gourmet
                this.total_lavanderia = this.total_lavadoras
                // this.globalService.dismissLoading();
            },
            (error) => {
                // this.globalService.dismissLoading();
            }
        )
    }

    /* Areas comunes reservas*/

    GetAreaComunByBien(date) {
        let fecha = `${date.year}-${date.month}-${date.day}`
        fecha = moment(fecha).format('YYYY-MM-DD')
        this.spinner.show()

        let data = {
            id_proyecto: this.id_proyecto,
            fecha: fecha,
            hora: moment(moment()).format('HH:mm'),
            id_bien: this.idBienSelected
        }

        this.areascomunesService.GetCommonAreasByBien(data).subscribe(
            (result) => {
                let now = moment()
                let add = now.add(moment.duration("05:00"))
                result.forEach((element) => {
                    if (element.tipo == "ESTACIONAMIENTO VISITA") {

                        let enow = {
                            "id_config_area_comun": 295,
                            "id_bien": element.id_bien,
                            "activo": 1,
                            "hora_inicio": moment().format("HH:mm"),
                            "hora_fin": add.format('HH:mm'),
                            "valor_reserva": 5000,
                            "horas_max": null,
                            "garantia": 0,
                            "aseo": 9,
                            "retraso_max": 0,
                            "dia": "Martes"
                        }
                        element.disponibles.push(enow)
                    }

                    this.Horarios_Reservas = element.disponibles
                })
                this.spinner.hide()
            },
            (error) => { }
        )
    }

    GetAreaComun() {
        this.spinner.show()
        let fecha = moment(moment()).format('YYYY-MM-DD')
        let hora = moment(moment()).format('HH:mm')
        let data = {
            id_proyecto: this.id_proyecto,
            fecha: fecha,
            hora: hora
        }

        this.areascomunesService.GetAreasComunesReservas(data).subscribe(
            (result) => {
                this.area_comun = result
                this.areas_actuales = this.area_comun.filter((element) => {
                    let separador = element.numero_bien.split("-")
                    element.numero_bien = separador[0]
                    element.nombre_torre = separador[1]
                    return element.tipo == this.areaSelected
                })
                this.area_comun.forEach((element) => {
                    let reservas = element.reservas

                    let consideraciones = reservas.filter((res) => {
                        return res != null && res.considerar == true
                    })
                    if (consideraciones[0] != undefined) {

                        element.nombre_usuario = consideraciones[0].nombre_usuario
                        element.numero_bien_usuario = consideraciones[0].numero_bien_usuario
                        element.fecha_entrada_area = moment(
                            consideraciones[0].fecha_inicio + ' ' + consideraciones[0].hora_inicio
                        ).format('YYYY-MM-DD HH:mm')
                        element.fecha_salida_area = moment(consideraciones[0].fecha_real_salida).format(
                            'YYYY-MM-DD HH:mm'
                        )
                        element.hora_entrada = consideraciones[0].hora_inicio
                        element.hora_salida = consideraciones[0].hora_fin
                        element.estado = consideraciones[0].estado_area
                        element.id_reserva = consideraciones[0].id_reserva
                        element.patente = consideraciones[0].patente
                    }
                })
                this.spinner.hide()
                this.ActualizarEstados()
            },
            (error) => { }
        )
    }

    FilterAreas(tipo) {
        this.areas_actuales = this.area_comun.filter((element) => {
            return element.tipo == tipo
        })
    }

    ActualizarEstados() {
        setInterval(() => {
            this.area_comun.forEach((obj) => {
                if (obj.estado == 6) {
                    this.time = {
                        days: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    }
                    this.finishDate = new Date()
                    const now = new Date(obj.fecha_salida_area)
                    const diff = this.finishDate.getTime() - now.getTime()
                    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
                    const hours = Math.floor(diff / (1000 * 60 * 60))
                    const mins = Math.floor(diff / (1000 * 60))
                    const secs = Math.floor(diff / 1000)
                    this.time.days = days
                    this.time.hours = hours - days * 24
                    this.time.minutes = mins - hours * 60
                    this.time.seconds = secs - mins * 60
                    obj.RelojArea = `${this.time.hours}: ${this.time.minutes}: ${this.time.seconds}`
                }
                if (obj.estado == 5) {
                    this.time = {
                        days: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    }
                    this.finishDate = new Date(obj.fecha_salida_area)
                    const now = new Date()
                    const diff = this.finishDate.getTime() - now.getTime()
                    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
                    const hours = Math.floor(diff / (1000 * 60 * 60))
                    const mins = Math.floor(diff / (1000 * 60))
                    const secs = Math.floor(diff / 1000)
                    this.time.days = days
                    this.time.hours = hours - days * 24
                    this.time.minutes = mins - hours * 60
                    this.time.seconds = secs - mins * 60
                    obj.RelojArea = `${this.time.hours}: ${this.time.minutes}: ${this.time.seconds}`

                    if (this.time.hours == 0 && this.time.minutes == 0 && this.time.seconds == 0) {
                        obj.estado = 6
                    }
                }
            })
        }, 1000)
    }

    updateTime() {
        const now = new Date()
        const diff = this.finishDate.getTime() - now.getTime()

        // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
        const days = Math.floor(diff / (1000 * 60 * 60 * 24))
        const hours = Math.floor(diff / (1000 * 60 * 60))
        const mins = Math.floor(diff / (1000 * 60))
        const secs = Math.floor(diff / 1000)

        // La diferencia que se asignará para mostrarlo en la pantalla
        this.time.days = days
        this.time.hours = hours - days * 24
        this.time.minutes = mins - hours * 60
        this.time.seconds = secs - mins * 60
    }

    // Ejecutamos la acción cada segundo, para obtener la diferencia entre el momento actual y el objetivo
    /* start() {
        return interval(1000).pipe(
            map((x: number) => {
                this.updateTime();
                return x;
            })
        );
    } */

    setReserva(item, tipo?) {
        let texto
        let fecha = moment().format('YYYY-MM-DD')
        let hora = moment().format('HH:mm')
        let data
        let mensaje
        if (tipo == 'iniciar') {
            data = { id_reserva: item.id_reserva, fecha: fecha, hora: hora, id_estado: 5 }
            texto = 'Desea iniciar la reserva?'
            mensaje = 'Reserva iniciada correctamente'
        } else {
            data = { id_reserva: item.id_reserva, fecha: fecha, hora: hora, id_estado: 3 }
            texto = 'Desea finalizar la reserva?'
            mensaje = 'Reserva finalizada correctamente'
        }

        swal({
            title: texto,
            //text: "Desea Finalizar la reserva?",
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                /* this.areascomunesService.SetReservaTerminada(data).subscribe(
                    (result) => {
                        swal(mensaje)
                        this.ngOnInit()
                    },
                    (error) => {

                    }
                ) */
            }
        })
    }

    ChangeInactiveFilter() {
        this.ngOnInit()
    }

    GetUserBybienes(item) {
        let id_bien = JSON.parse(item).id_bien
        let data = { id_bien: id_bien }

        this.globalServicesService.getUserBybienes(data).subscribe(
            (result) => {
                this.ArrayUsuarios = result
            },
            (error) => {
                // this.globalService.dismissLoading();
            }
        )
    }

    formatNumber(num: any) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    notificarResidente() {
        this.globalServicesService.enviarNotificacion(this.notificacion).subscribe(
            (response) => {
                this.notificacion = new Notificacion(null, 'conserje', '', '')
            },
            (error) => {

            }
        )
    }

    DesactivarMantencion(item) {
        let data = {
            id_bien: item.id_bien,
            id_estado: 7,
            id_proyecto: sessionStorage.getItem('IdSelectProject')
        }
        this.areasService.SetEstadoArea(data).subscribe(
            (result) => {
                this.alertService.success(result.output.message)
                this.ngOnInit()
            },
            (error) => {
                // this.globalService.dismissLoading();
            }
        )
    }

    AreaMentecion() {
        this.zonas = this.area_comun.filter((element) => element.tipo == this.areaSelectedMantencion)
    }

    AplicarMantencion() {
        if (this.areaSelectedMantencion == 0) {
            this.alertService.warning('Seleccione Área')
            return
        }

        if (this.zonaSelectedMantencion == 0) {
            this.alertService.warning('Seleccione Zona')
            return
        }

        if (this.fechaInicioMantencion == undefined) {
            this.alertService.warning('Seleccione fecha de inicio de mantención')
            return
        }

        if (this.fechaFinMantencion == undefined) {
            this.alertService.warning('Seleccione fecha de fin de mantención')
            return
        }

        if (this.observacion == undefined) {
            this.alertService.warning('Ingrese observación')
            return
        }

        $('#modal-confirmacion-mantencion').modal('show')
        $('#modal-mantenimiento').modal('hide')
    }

    InsertMantencion() {
        let data = {
            id_login_usuario: this.id_login_usuario,
            id_modelo_ubicacion: JSON.parse(this.zonaSelectedMantencion).id_modelo_ubicacion,
            id_bien: JSON.parse(this.zonaSelectedMantencion).id_bien,
            hora_inicio: '', //this.horaInicioMantencion + ':00',
            fecha_inicio: moment(
                `${this.fechaInicioMantencion.year}-${this.fechaInicioMantencion.month}-${this.fechaInicioMantencion.day}`
            ).format('YYYY-MM-DD'),
            hora_fin: '', //this.horaFinMantencion + ':00',
            fecha_fin: moment(
                `${this.fechaFinMantencion.year}-${this.fechaFinMantencion.month}-${this.fechaFinMantencion.day}`
            ).format('YYYY-MM-DD'),
            observacion: this.observacion
        }

        this.areasService.insertMantencion(data).subscribe(
            (result) => {
                this.alertService.success(result.output.message)
                this.ngOnInit()
            },
            (error) => {
                // this.globalService.dismissLoading();
            }
        )
    }

    /* Areas comunes reservas*/
    DetalleAreaComun(index, item) {


        this.NombreAreaSelected = `${item.numero_bien}  \n ${item.nombre_torre}`
        this.idBienSelected = item.id_bien
        this.ObjReserva = item
        this.Horarios_Reservas = item.disponibles
        this.GetAreaComunByBien(this.fechaSelected)

        if (item.disponibles.length == 0) {
            this.alertService.warning(`${this.NombreAreaSelected}, no cuenta con reservas disponibles`)
        } else {
            $('#modal-reserva-integracion').modal('show')
        }
    }


    informarCargos() {
        if (this.selectedValueHorario == 0) {
            this.alertService.warning('Seleccione horario')
            return
        }
        if (this.selectedValueUsuario == 0) {
            this.alertService.warning('Seleccione Usuario')
            return
        }
        if (this.selectedValueBien == 0) {
            this.alertService.warning('Seleccione Bien')
            return
        }
        $('#modal-reserva-integracion').modal('hide')
        this.valor_reserva = JSON.parse(this.selectedValueHorario).valor_reserva
        $('#modal-confirmacion').modal('show')
    }

    EnviarReserva() {
        let usuario = JSON.parse(this.selectedValueUsuario)
        let bien = JSON.parse(this.selectedValueBien)

        let nombre_usuario = this.ArrayUsuarios.filter((x: any) => x.id_login_usuario == usuario.id_login_usuario)
        let nombre_area_comun = this.area_comun.filter((x: any) => x.id_bien == this.ObjReserva.id_bien)
        this.FechaModalSelect = `${this.fechaSelected.year}-${this.fechaSelected.month}-${this.fechaSelected.day}`
        this.FechaModalSelect = moment(this.FechaModalSelect).format('YYYY-MM-DD')

        let data = {
            id_login_usuario: usuario.id_login_usuario,
            fecha_inicio: this.FechaModalSelect,
            id_estado: 1,
            hora_inicio: JSON.parse(this.selectedValueHorario).hora_inicio,
            hora_fin: JSON.parse(this.selectedValueHorario).hora_fin,
            id_bien: JSON.parse(this.selectedValueHorario).id_bien,
            id_modelo_ubicacion: null,
            id_bien_usuario: bien.id_bien,
            patente: this.inputPatente
        }
        
        //esto debe ir dentro del result del obs
        this.notificacion = new Notificacion(
            usuario.id_login_usuario,
            'conserje',
            `Se ha realizado una reserva de un área común de tipo ${nombre_area_comun[0].tipo} (${nombre_area_comun[0].numero_bien
            }) con fecha ${moment().format('D [de] MMMM, YYYY')}`,
            'Reserva área común'
        )
        this.notificarResidente()

        this.areasService.InserReservaAreasComunes(data).subscribe(
            (result) => {
                this.selectedValueUsuario = 0
                this.selectedValueBien = 0
                this.inputPatente = ''
                this.alertService.success(result.message)
                this.ngOnInit()
            },
            (error) => {
                //aqui quede
            }
        )
    }

    async EliminarReserva(item) {
        
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_reserva: item.id_reserva,
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje elimina la reserva correspondiente al depto ${item.numero_bien_usuario} registrada a las ${item.hora_entrada} hasta ${item.hora_salida} para ${moment(item.fecha_entrada_area).format('YYYY-MM-DD')}`
        }
        this._horarioDiarioService.EliminarReserva(data).subscribe((result) => {
            this.alertService.success(result.message)
            this.ngOnInit()
        })
    }

    async IniciarReserva(item) {
        let data = {
            id_reserva: item.id_reserva,
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje inicia la reserva correspondiente al depto ${item.numero_bien_usuario} registrada a las ${item.hora_entrada} hasta ${item.hora_salida} para ${moment(item.fecha_entrada_area).format('YYYY-MM-DD')}`
        }
        this.areascomunesService.IniciarReserva(data).subscribe(
            (result) => {
                this.alertService.success(result.message)
                this.ngOnInit()
            },
            (error) => {
                this.ngOnInit()
            }
        )
    }

    async FinalizarReserva(item) {

        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_reserva: item.id_reserva,
            fecha_salida: moment().format('YYYY-MM-DD'),
            hora_salida: moment().format('HH:mm'),
            id_estado: 3,
            id_login_usuario: this.globalServicesService.getLocalData().id_login_usuario,
            texto: `Conserje finaliza la reserva correspondiente al depto ${item.numero_bien_usuario} registrada a las ${item.hora_entrada} hasta ${item.hora_salida} para ${moment(item.fecha_entrada_area).format('YYYY-MM-DD')}`
        }
        this.areascomunesService.FinalizarReserva(data).subscribe((result) => {
            if (result.error == 0) {
                this.alertService.success(result.message)
            }
            if (this.IsIntegracion == 1 && item.tipo != "ESTACIONAMIENTO VISITA") {

                let dataReserva = {
                    id_proyecto: sessionStorage.getItem('IdSelectProject'),
                    id_reserva: item.id_reserva,
                    proyectoid: sessionStorage.getItem('externo_id')
                }
                this.areascomunesService.EnviarReservaLevel(dataReserva).subscribe((resultLevel) => {
                    if (resultLevel.error == 200) {
                        this.alertService.danger(resultLevel.message)
                    } else {
                        this.alertService.success(resultLevel.message)
                    }
                })
            }
            this.ngOnInit()
        })
    }
}
