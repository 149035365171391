<div class="fondo-negro animated fadeIn" [ngClass]="_recibirEncomiendaService.oculto">
    <div class="contenedor-modal-bitacora-detalle">
        <div id="modal-bitacora-resumen">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Recibir Encomienda</h4>
                        <button type="button" class="close pointer" (click)="closeModal()">
                            <img src="assets/images/ico-x.png" />
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="contenedor-lineal">
                            <label class="estilo-label-4 txt-full">Tipo de encomienda:</label>
                            <select
                                class="estilo-select-2 nuevo-select select-full no-icono nuevo-select-2"
                                [(ngModel)]="selectedType"
                            >
                                <option value="0">Seleccione una opción</option>
                                <option *ngFor="let type of types" [value]="type.id_tipo_encomienda">
                                    {{ type.nombre_encomienda }}
                                </option>
                            </select>
                        </div>

                        <div class="contenedor-lineal">
                            <label class="estilo-label-4 txt-full">Residente:</label>
                            <div class="buscador-personalizado-2">
                                <span
                                    [hidden]="!input"
                                    class="estilo-btn-select-2 pointer"
                                    name="btn"
                                    (click)="testingSelect()"
                                >
                                    {{ selectText }}
                                </span>
                                <div [hidden]="input" class="contenedor-sombreado-2">
                                    <input
                                        [hidden]="!input"
                                        class="buscador-select"
                                        #search
                                        [hidden]="input"
                                        type="text"
                                        name="input"
                                        (keyup)="setFilteredItems(search.value)"
                                    />
                                    <div class="listado-buscador-2" [hidden]="input">
                                        <ul>
                                            <li
                                                class="pointer"
                                                (click)="getUserData(user)"
                                                *ngFor="let user of usersFiltered"
                                            >
                                                {{ user.nombre }} {{ user.apellido }} - {{ user.numero_bien }} -
                                                {{ user.nombre_etapa }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="borrado"></div>
                        </div>
                        <div class="row margen-inferior-3">
                            <div class="col-md-6">
                                <label class="estilo-label-4 txt-full">Procedencia:</label>
                                <input class="estilo-input-11 full-ancho" [(ngModel)]="procedencia" type="text" />
                            </div>
                            <div class="col-md-6">
                                <label class="estilo-label-4 txt-full margen-sup-2">Código:</label>
                                <input class="estilo-input-11 full-ancho" [(ngModel)]="codigo" type="text" />
                            </div>
                        </div>

                        <textarea
                            class="textarea fondo-blanco text-area-encomienda"
                            rows="5"
                            cols="10"
                            placeholder="Observación..."
                            [(ngModel)]="observacion"
                        ></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-modal-cancelar no-margin pointer" (click)="closeModal()">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-modal-enviar pointer" (click)="sendData()">
                            Guardar encomienda
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
