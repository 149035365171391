import { Component, OnInit, ViewChild } from '@angular/core'
import { RecibirEncomiendaService } from 'src/app/modal/recibir-encomienda/recibir-encomienda.service'
import { EncomiendaService } from 'src/app/services/encomienda/encomienda.service'
import { Encomienda } from 'src/app/models/encomienda.model'
import { EntregarEncomiendaService } from 'src/app/modal/entregar-encomienda/entregar-encomienda.service'
import { GlobalServicesService } from '../../services/global-services.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subject } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'
import * as moment from 'moment'
declare var $: any

@Component({
    selector: 'app-encomienda',
    templateUrl: './encomienda.component.html',
    styles: []
})
export class EncomiendaComponent implements OnInit {
    public encomienda: Encomienda
    public page: number = 1
    public quantity: number
    public total: number = 1
    public totalPage: number = 1
    public dtOptions: any = {}
    encomiendaArray: any = []
    dtTrigger: Subject<any> = new Subject<any>()

    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false
    constructor(
        public _recibirEncomiendaService: RecibirEncomiendaService,
        public _encomiendaService: EncomiendaService,
        public _entregarEncomiendaService: EntregarEncomiendaService,
        public _globalService: GlobalServicesService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe()
    }

    ngOnInit() {
        this.GetEncomiendas()
        let self = this
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
            order: [[9, 'desc']],
            dom: 'Bfrtip',
            aoColumns: [
                { mData: 'id_encomienda' },
                { mData: 'nombre_residente' },
                { mData: 'numero_bien' },
                { mData: 'nombre_encomienda' },
                { mData: 'procedencia' },
                { mData: 'fecha_recepcion' },
                { mData: 'fecha_entrega' },
                { mData: 'observacion' },
                { mData: 'receptor' },
                { mData: 'estado' }
            ],

            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel',
                    action: function (e, dt, node, config) {
                        self.spinner.show()
                        self._encomiendaService
                            .getCommission({
                                id_proyecto: sessionStorage.getItem('IdSelectProject')
                            })
                            .subscribe((resp: any) => {
                                dt.rows.add(resp).draw()
                                $.fn.dataTable.ext.buttons.excelHtml5.action.call(this, e, dt, node, config)
                                self.spinner.hide()
                            })
                    }
                }
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }

        /*  this._encomiendaService._refresh.subscribe(() => {
             this.loadTable()
         })
         this.loadTable() */
    }

    /*   GetDataToExport() {
          return new Promise((resolve, reject) => {
              let data = {
                  id_proyecto: sessionStorage.getItem('IdSelectProject')
              }
  
              this._encomiendaService.getCommission(data).subscribe((resp: any) => {
                  this.encomiendaArray = resp
                  return resolve(resp)
                  this.spinner.hide()
              })
          })
      } */

    GetEncomiendas() {
        
        this.spinner.show()
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            fecha: moment().format('YYYY-MM-DD')
        }

        this._encomiendaService.getCommission(data).subscribe((resp: any) => {
            this.encomiendaArray = resp
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
            this.spinner.hide()
        })
    }

    ingresarEncomienda() {
        this._recibirEncomiendaService.showModal()
    }

    giveCommission(event: any, encomienda: Encomienda) {
        event.stopPropagation();
        
        
        
        this.encomienda = encomienda
        this._encomiendaService.encomienda = encomienda
        this._entregarEncomiendaService.showModal(encomienda.id_usuario)
    }

    setFilteredItems(event: any) {
        if (event.length >= 2) {
            this._encomiendaService.searchTerm(event).subscribe()
        }
        if (event.length == 0) {
            this.ngOnInit()
        }
    }

    changeFrom(page: Boolean) {
        if (page) {
            if (this.quantity > this.page) {
                this.page++
            }
        } else {
            if (this.page > 1) {
                this.page--
            }
        }
        this.ngOnInit()
    }
}
