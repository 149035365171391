import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class AreasService {
    constructor(private http: HttpClient) {}

    public InserReservaAreasComunes(data): Observable<any> {
        data.id_proyecto = sessionStorage.getItem('IdSelectProject')
        return this.http
            .post(environment.URL_SERVICIOS + 'InserMblReservaAreasComunes', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public GetAreasComunesLevel(): Observable<any> {
        let data = { PROYECTOID: sessionStorage.getItem('externo_id') }
        return this.http
            .post(environment.URL_SERVICIOS_ADMINISTRACION + 'level/AreasComunes/GetAreasComunes', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public SetEstadoArea(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'setEstadoArea', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public insertMantencion(data): Observable<any> {
        data.id_proyecto = sessionStorage.getItem('IdSelectProject')
        return this.http
            .post(environment.URL_SERVICIOS + 'insertMantencion', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    public getMantencion(data): Observable<any> {
        return this.http
            .post(environment.URL_SERVICIOS + 'getMantencion', data)
            .timeout(20000)
            .map((response) => {
                return response
            })
            .catch((error: any) => {
                return Observable.throw(this.errorHandler(error))
            })
    }

    errorHandler(error: any): any {
        throw new Error('Method not implemented.')
    }
}
