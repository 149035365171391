export class Notificacion {
    constructor(
        public id_usuario: String,
        public modulo: String,
        public mensaje: String,
        public titulo: String,
        public fecha?: any
    ) {}
}
export class Difusion {
    constructor(
        public mensaje: String,
        public titulo: String,
        public id_proyecto: String,
        public emergencia: boolean,
        public fecha?: any
    ) {}
}
export class Correo {
    constructor(
        public asunto: String,
        public para: String,
        public html: String,
        public nombre: String,
        public texto: String
    ) {}
}
