import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { GlobalServicesService } from '../../services/global-services.service'
import { ToastrService } from 'ngx-toastr'
import { VisitasService } from 'src/app/services/visitas/visitas.service'
import * as moment from 'moment'
import { Subject } from 'rxjs'
import { SocketServiceService } from 'src/app/services/socket-service.service'
import { AreasComunesService } from '../areas-comunes/areas-comunes.service'
import { DataTableDirective } from 'angular-datatables'
import { NgxSpinnerService } from 'ngx-spinner'
declare var $: any

@Component({
    selector: 'app-visitantes',
    templateUrl: './visitantes.component.html',
    styles: []
})
export class VisitantesComponent implements OnInit {
    public IsIntegracion: any = 0
    public VisitasArray: any = []
    public VisitasArrayFilter: any = []
    public searchTerm: any = []
    public data = []
    title = 'angulardatatables'
    dtOptions: any = {}
    dtTrigger: Subject<any> = new Subject<any>()
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective
    isDtInitialized: boolean = false
    @ViewChild('nombre', { static: false }) searchElement: ElementRef

    constructor(
        public areascomunesService: AreasComunesService,
        public globalServicesService: GlobalServicesService,
        public toastr: ToastrService,
        public _visitaService: VisitasService,
        public _socketService: SocketServiceService,
        private spinner: NgxSpinnerService
    ) {}

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe()
    }

    ngOnInit() {
        this.IsIntegracion = sessionStorage.getItem('Integracion')
        this.Visitantes()
        let self = this
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthMenu: [5, 10, 25, 50, 100],
            processing: true,
           // order: [[0, 'desc']],
            dom: 'Bfrtip',
            aoColumns: [
                { mData: 'id_visita' },
                { mData: 'numero_bien' },
                { mData: 'nombre' },
                { mData: 'rut' },
                { mData: 'fecha' },
                { mData: 'fecha_salida' },
                { mData: 'patente' },
                { mData: 'nombre_estacionamiento' }
            ],

            buttons: [
                {
                    extend: 'excelHtml5',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel',
                    action: function (e, dt, node, config) {
                        self.spinner.show()
                        self._visitaService
                            .getVisitantes({
                                id_proyecto: sessionStorage.getItem('IdSelectProject')
                            })
                            .subscribe((resp: any) => {
                                dt.rows.add(resp).draw()
                                $.fn.dataTable.ext.buttons.excelHtml5.action.call(this, e, dt, node, config)
                                self.spinner.hide()
                            })
                    }
                }
            ],
            language: {
                processing: 'Procesando...',
                search: 'Buscar:',
                lengthMenu: 'Mostrar _MENU_ ',
                info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                infoEmpty: 'Mostrando ningún elemento.',
                infoFiltered: '(filtrado _MAX_ elementos total)',
                infoPostFix: '',
                loadingRecords: 'Cargando registros...',
                zeroRecords: 'No se encontraron registros',
                emptyTable: 'No hay datos disponibles en la tabla',
                paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último'
                }
            }
        }
    }

    Visitantes() {
        this.spinner.show()
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            fecha: moment().format('YYYY-MM-DD')
        }
        this._visitaService.getVisitantes(data).subscribe((result) => {
            this.data = result
            if (this.isDtInitialized) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy()
                    this.dtTrigger.next()
                })
            } else {
                this.isDtInitialized = true
                this.dtTrigger.next()
            }
            this.spinner.hide()
        })
    }
}
