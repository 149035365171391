import { Injectable } from '@angular/core'
import { Evento } from 'src/app/models/evento.model'
import { HttpClient } from '@angular/common/http'
import { Inivitado } from 'src/app/models/invitado.model'
import { map } from 'rxjs/operators'
import * as moment from 'moment'
import { GlobalServicesService } from 'src/app/services/global-services.service'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class GestionarInvitadosService {
    public oculto: string = 'oculto'
    public invitados: Array<Inivitado>
    public searchTerm: any = []
    public invitados_filtered: Array<Inivitado>
    public evento_finalizado: Boolean = false
    public actual_date: any = moment().format('YYYY-MM-DD')
    public id_login_usuario: any = this.globalServicesService.getLocalData().id_login_usuario

    constructor(public http: HttpClient, public globalServicesService: GlobalServicesService) {}

    hideModal() {
        this.oculto = 'oculto'
    }

    showModal(event: Evento) {
        if (this.actual_date > event.fecha_inicio) {
            this.evento_finalizado = true
        } else {
            this.evento_finalizado = false
        }
        let data = {
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            id_evento: event.id_evento
        }
        let url = environment.URL_SERVICIOS + 'GetEventById'
        return this.http.post(url, data).subscribe((resp: any) => {
            let invitados = resp
            this.invitados = invitados.map((x: any) => {
                if (!x.llegada) {
                    x.llegada = 0
                }
                return x
            })
            this.invitados_filtered = this.invitados
            this.oculto = ''
        })
    }

    guestIsHere(arrived: Boolean, id_evento_visita: Number) {
        let data = {
            id_usuario: this.id_login_usuario,
            id_proyecto: sessionStorage.getItem('IdSelectProject'),
            llegada: arrived,
            id_evento: id_evento_visita
        }
        let url = environment.URL_SERVICIOS + 'GuestIsHere'
        return this.http.post(url, data).pipe(
            map((resp: any) => {
                return resp
            })
        )
    }
}
